




















































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { PaginationPanel } from '@/components';
import { formRules } from '@/common/util/validator';
import { Order, User, Cart, RefundOrder } from '@/http';
interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        PaginationPanel,
    },
})
export default class OrderPanel extends Vue {
    @Prop({
        default: '1',
    })
    public type: string;
    // @Prop({
    //     default: '',
    // })
    public current: string;
    public formRules = formRules;
    public payMethodDialogVisible: boolean = false;

    dialogVisible = false;
    public $refs: {
        pagination: HTMLFormElement;
        payMethodForm: HTMLFormElement;
    };
    public payMethodForm: any = {
        payMethod: '',
    };
    closePayMethod() {
        this.$nextTick(() => {
            this.$refs.payMethodForm.resetFields();
        });
        this.payMethodDialogVisible = false;
    }

    handleClose() {
        this.dialogVisible = false;
        this.detailData = [];
    }

    savePayMethod() {
        this.$refs.payMethodForm.validate((valid) => {
            if (valid) {
                if (this.payMethodForm.payMethod == '2') {
                    this.$router.push({
                        path: '/orderDetail/detail',
                        query: {
                            id: this.locId,
                        },
                    });
                } else if (this.payMethodForm.payMethod == '3') {
                    this.$router.push({
                        path: '/settleCenter',
                        query: {
                            id: this.locId,
                            type: '3',
                        },
                    });
                } else if (this.payMethodForm.payMethod == '4') {
                    this.$router.push({
                        path: '/settleCenter',
                        query: {
                            id: this.locId,
                            type: '4',
                        },
                    });
                }
            }
        });
    }

    showRefund(item) {
        this.$router.push({
            path: '/refund/detail',
            query: {
                id: item.refundOrderId,
            },
        });
    }
    textTip = '物流信息查询中..';
    public showLogistics(item) {
        this.dialogVisible = true;
        this.textTip = '物流信息查询中..';
        Order.GetOrderLogList({
            itemCode: item.logisticsNum,
        })
            .then((res) => {
                this.detailData = res.data;
                this.time();
                if (this.detailData.length <= 0) {
                    this.textTip = '暂无物流消息';
                }
            })
            .catch(() => {
                this.textTip = '暂无物流消息';
            });
    }

    public days: any = null;
    public hours: any = null;
    public minutes: any = null;
    public seconds: any = null;
    public time() {
        // 计算耗时
        var beginTime = this.detailData[0].operatorTime,
            lastTime = this.detailData[this.detailData.length - 1].operatorTime,
            timeB = Date.parse(String(new Date(beginTime))),
            timeL = Date.parse(String(new Date(lastTime))),
            usedTime = timeL - timeB;
        this.days = Math.floor(usedTime / (24 * 3600 * 1000));
        this.hours = Math.floor((usedTime % (24 * 3600 * 1000)) / (3600 * 1000));
        this.minutes = Math.floor(((usedTime % (24 * 3600 * 1000)) % (3600 * 1000)) / (60 * 1000));
        this.seconds = Math.floor((((usedTime % (24 * 3600 * 1000)) % (3600 * 1000)) % (60 * 1000)) / 1000);
    }

    //计算剩余时间
    public restTime: string = '';
    public leftTime: number = 0;
    public h: number = 0;
    public m: any = 0;
    public s: any = 0;
    public oldTime: string = '';
    public uid: string = '';
    countTime() {
        //获取当前时间
        var date = new Date();
        var now = date.getTime();
        //设置截止时间
        console.log('this.oldTime' + this.oldTime);
        var endDate = new Date(Date.parse(this.oldTime) + 1000 * 60 * 60 * 48);
        var end = endDate.getTime();
        //时间差
        this.leftTime = end - now;
        console.log('leftTime-------->' + this.leftTime);
        //定义变量 d,h,m,s保存倒计时的时间
        // if (leftTime >= 0) {
        //     this.h = Math.floor(leftTime / 1000 / 60 / 60);
        //     this.m = Math.floor((leftTime / 1000 / 60) % 60);
        //     this.s = Math.floor((leftTime / 1000) % 60);
        // }
        // this.restTime = this.h + '小时' + this.m + '分' + this.s + '秒';
    }

    public detailData = [];

    reason = '';

    reasonDialog = false;

    reasonData = [];

    handleReasonDialogClose() {
        this.reasonDialog = false;
    }

    public goodsList: any[] = [];

    public locId = '';
    settle(data) {
        this.locId = data.id;
        this.payMethodDialogVisible = true;
        // this.$router.push({
        //     path: '/settleCenter',
        //     query: {
        //         id: data.id,
        //     },
        // });
    }

    public checkAll: boolean = false;
    public pageSize = 0;

    public isIndeterminate: boolean = true;

    public checkedCities: any = [];

    public total: number = 30;
    public rePagin: boolean = false;

    public orderList: any = [];

    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };
    public async onPaginationChange(pagin: Pagination) {
        this.searchPagin = pagin;
        this.loadData();
    }
    lastPage() {
        if (this.searchPagin.currentPage > 1) {
            this.searchPagin.currentPage -= 1;
            this.$refs.pagination.handleCurrentChange(this.searchPagin.currentPage);
        }
    }
    nextPage() {
        if (this.searchPagin.currentPage < this.pageSize) {
            this.searchPagin.currentPage++;
            this.$refs.pagination.handleCurrentChange(this.searchPagin.currentPage);
        }
    }

    confirmReceipt(id) {
        this.$confirm('确认收货?')
            .then(() => {
                Order.SaveOrderStatus({
                    cancelReason: '',
                    id: id,
                    orderStatus: 4,
                }).then(() => {
                    this.loadData();
                });
            })
            .catch(() => {});
    }

    deleteOrder(id) {
        Order.DeleteOrder(id).then(() => {
            this.$message.success('删除成功');
            this.loadData();
        });
    }

    pinglun(id) {
        this.$router.push({
            path: '/orderDetail/evaluate',
            query: {
                id: id,
            },
        });
    }

    toOrderDetail(item) {
        this.$router.push({
            path: '/orderDetail/detail',
            query: {
                id: item.id,
            },
        });
    }

    lianxi(item) {
        if (sessionStorage.getItem('id')) {
            this.$root.$emit('lianxi', item.orderChildList[0].shopId);
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }

    cancelId = '';
    cancelOrder(id) {
        this.cancelId = id;
        this.reasonDialog = true;
        this.reasonData = [];
        this.reason = '';
        User.GetDataDictionary({
            parentId: '3d2e4da3-c4a9-0d2a-b968-78946f73fcfc',
            skip: 0,
            count: -1,
        }).then((res) => {
            this.reasonData = res.data;
        });
    }
    refundOrder(item) {
        this.$router.push({
            path: '/refund/detail',
            query: {
                id: item.id,
            },
        });
    }

    doCancel() {
        if (!this.reason) {
            this.$alert('请选择原因');
            return;
        }
        Order.SaveOrderStatus({
            cancelReason: this.reason,
            id: this.cancelId,
            orderStatus: 5,
        }).then(() => {
            this.$message.success('取消成功');
            this.reasonDialog = false;
            this.loadData();
        });
    }

    public turnPage(type) {
        console.log('type', type);
        console.log('this.$refs.pagination', this.$refs.pagination);
        if (type === 1) {
            this.$refs.pagination.handleCurrentChange(this.searchPagin.currentPage - 1);
        } else {
            this.$refs.pagination.handleCurrentChange(this.searchPagin.currentPage + 1);
        }
    }

    public created() {
        // this.init();
    }
    public mounted() {
        if (this.type == '1' && window.location.href.indexOf('activeName') == -1) {
            this.loadData();
        }
    }

    setCurrentPage(currentPage) {
        this.current = currentPage;
        console.log(this.current);
    }

    public orderStatusList = '';

    isEvaluate = null;

    public init() {
        if (this.current == '1') {
            this.orderStatusList = '';
            this.isEvaluate = '';
            this.loadData();
        } else if (this.current == '2') {
            this.orderStatusList = '1';
            this.isEvaluate = '';
            this.loadData();
        } else if (this.current == '3') {
            this.orderStatusList = '2';
            this.isEvaluate = '';
            this.loadData();
        } else if (this.current == '4') {
            this.orderStatusList = '3';
            this.isEvaluate = '';
            this.loadData();
        } else if (this.current == '5') {
            this.orderStatusList = '4';
            this.isEvaluate = false;
            this.loadData();
        }
    }
    public countdownList = [];
    public restStatus: string = '';
    loadData() {
        this.countdownList = [];
        Order.Order({
            orderStatusList: this.orderStatusList,
            isEvaluate: this.isEvaluate,
            ...this.searchPagin,
        }).then((res) => {
            this.orderList = res.data;
            this.total = res.total;
            this.$emit('haveTotal', this.total);
            this.pageSize = this.total > 0 ? Math.floor(this.total < 10 ? 1 : this.total % 10 ? this.total / 10 + 1 : this.total / 10) : 0;
            this.orderList.forEach((e) => {
                this.oldTime = e.orderTime;
                this.uid = e.id;
                this.restStatus = e.orderStatus;
                this.countTime();
                let countdown = {
                    oldTime: this.oldTime,
                    uid: this.uid,
                    leftTime: this.leftTime,
                    restStatus: this.restStatus,
                };
                this.countdownList.push(countdown);
            });
            console.log('this.countdownList------>' + JSON.stringify(this.countdownList));
            this.countdownList.forEach((e) => {
                console.log('e--------->' + JSON.stringify(e));
                console.log('e.leftTime' + e.leftTime);
                console.log('e.restStatus' + e.restStatus);
                if (e.leftTime < 0 && e.restStatus == '1') {
                    console.log('00000000000000000');
                    Order.SaveOrderStatus({
                        cancelReason: '',
                        id: e.uid,
                        orderStatus: 5,
                    }).then(() => {
                        this.$message.success('取消成功');
                        this.loadData();
                    });
                }
            });
        });
    }

    clear() {
        this.orderList = [];
        this.total = 0;
    }

    toDetail(row) {
        this.$router.push({
            path: '/goodsDetail',
            query: {
                id: row.goodsId,
            },
        });
    }

    public handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.orderList.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.orderList.length;
    }

    public getOrderDetails() {
        this.$router.push({
            path: '/orderDetail/detail',
        });
    }
}
