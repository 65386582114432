






































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { CommonPanel } from '@/components';
import { fmoney } from '@/common/util/money';
import { NavBar } from '../home/components';
import { Goods, Collection, Cart, Shop, Focus, AccessRecord } from '@/http';
import RightAnchor from '@/components/RightAnchor.vue';
declare var returnCitySN: any;
@Component({
    components: {
        CommonPanel,
        NavBar,
        RightAnchor,
    },
})
export default class goodsDetail extends Vue {
    public isShow: number = 0;
    public bigSrc: string = '';
    public specsId: string = '';
    public specsName: string = '';
    public isShoucang: boolean = false;
    public num: number = 1;
    public rate: number = 5;
    public data: any = {};
    public showImg: any[] = [];
    public shoucangCD: boolean = true;
    public goodsRecommends: any[] = [];
    public fullShopDetail: any = {};
    public isUserFocus: boolean = false;
    public weight: number = null;
    public enterpriseFixSalePrice: number = null;
    public enterpriseSectionSalePriceOne: number = null;
    public enterpriseSectionSalePriceTwo: number = null;
    public personalFixSalePrice: number = null;
    public personalSectionSalePriceOne: number = null;
    public personalSectionSalePriceTwo: number = null;
    public goodsPropertyList = [];
    public userType: string = '';
    mounted() {
        this.init();
    }
    lianxi() {
        if (sessionStorage.getItem('id')) {
            this.$root.$emit('lianxi', this.data.shopId);
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }

    shopQualificationList = [];
    async init() {
        let res = await Goods.GetGoodsDetail(this.$route.query.id);
        this.data = res;
        this.showImg = res.coverImageAttachmentList.concat(res.displayImageAttachmentList);
        let resShop = await Shop.GetShopQualificationList({ id: this.data.shopId });
        this.shopQualificationList = resShop.data;
        AccessRecord.AddAccessRecord({
            webHost: returnCitySN['cip'],
            weChatOpenId: null,
            dataId: res.id,
            accessPage: 'Goods',
            creatorUserName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : '',
        });
        AccessRecord.AddAccessRecord({
            webHost: returnCitySN['cip'],
            weChatOpenId: null,
            dataId: res.shopId,
            accessPage: 'Shop',
            creatorUserName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : '',
        });
        if (res.goodsSpecificationList.length > 0) {
            if (res.goodsSpecificationList[0].enterpriseSalePriceType == 2) {
                this.enterpriseSectionSalePriceOne = res.goodsSpecificationList[0].enterpriseSectionSalePriceOne;
                this.enterpriseSectionSalePriceTwo = res.goodsSpecificationList[0].enterpriseSectionSalePriceTwo;
            } else {
                this.enterpriseFixSalePrice = res.goodsSpecificationList[0].enterpriseFixSalePrice;
            }
            if (res.goodsSpecificationList[0].personalSalePriceType == 2) {
                this.personalSectionSalePriceOne = res.goodsSpecificationList[0].personalSectionSalePriceOne;
                this.personalSectionSalePriceTwo = res.goodsSpecificationList[0].personalSectionSalePriceTwo;
            } else {
                this.personalFixSalePrice = res.goodsSpecificationList[0].personalFixSalePrice;
            }
            this.weight = res.goodsSpecificationList[0].weight;
        } else {
            this.weight = res.weight;
        }
        this.bigSrc = this.data.coverImageAttachmentList.length > 0 ? this.data.coverImageAttachmentList[0].path : '';
        res.goodsSpecificationList.forEach((e) => {
            if (e.isDefaultSpec) {
                this.specsId = e.id;
                this.specsName = e.name;
                this.enterpriseSectionSalePriceOne = e.enterpriseSectionSalePriceOne;
                this.enterpriseSectionSalePriceTwo = e.enterpriseSectionSalePriceTwo;
                this.enterpriseFixSalePrice = e.enterpriseFixSalePrice;
                this.personalSectionSalePriceOne = e.personalSectionSalePriceOne;
                this.personalSectionSalePriceTwo = e.personalSectionSalePriceTwo;
                this.personalFixSalePrice = e.personalFixSalePrice;
                this.goodsPropertyList = [];
                this.data.goodsSpecificationList.forEach((item) => {
                    if (item.id == this.specsId) {
                        this.goodsPropertyList = item.goodsPropertyList;
                    }
                });
            }
        });
        let recommend = await Goods.GetRecommend({
            notGoodsId: res.id,
            goodsTypeOneId: res.goodsTypeOneId,
            isGoodsAround: true,
            skip: 0,
            count: 10,
        });
        this.goodsRecommends = recommend.data;
        let fullShopDetail = await Shop.GetFullShopDetail(res.shopId);
        this.fullShopDetail = fullShopDetail;
        if (sessionStorage.getItem('id')) {
            let isUserFocus = await Focus.isUserFocus({ id: res.shopId });
            let result = await Collection.isUserCollection({ id: this.$route.query.id });
            this.isShoucang = result.extData;
            if (isUserFocus.extData) {
                this.isUserFocus = true;
            } else {
                this.isUserFocus = false;
            }
            this.userType = sessionStorage.getItem('userType');
        } else {
            this.userType = '';
        }
    }
    changeSpecsId(v) {
        this.data.goodsSpecificationList.forEach((e) => {
            if (e.id == v) {
                this.specsName = e.name;
                if (e.enterpriseSalePriceType == 2) {
                    this.enterpriseSectionSalePriceOne = e.enterpriseSectionSalePriceOne;
                    this.enterpriseSectionSalePriceTwo = e.enterpriseSectionSalePriceTwo;
                    this.enterpriseFixSalePrice = 0;
                } else {
                    this.enterpriseFixSalePrice = e.enterpriseFixSalePrice;
                }
                if (e.personalSalePriceType == 2) {
                    this.personalSectionSalePriceOne = e.personalSectionSalePriceOne;
                    this.personalSectionSalePriceTwo = e.personalSectionSalePriceTwo;
                    this.personalFixSalePrice = 0;
                } else {
                    this.personalFixSalePrice = e.personalFixSalePrice;
                }
                this.goodsPropertyList = e.goodsPropertyList;
                this.weight = e.weight;
            }
        });
    }
    mouseenter(item, i) {
        this.bigSrc = item.path;
        this.isShow = i;
    }
    handleChange(value) {
        console.log(value);
    }
    shoucang() {
        // this.isShoucang = !this.isShoucang;
        if (sessionStorage.getItem('id')) {
            if (this.shoucangCD) {
                this.shoucangCD = false;
                // if (this.isShoucang) {
                //     Collection.deleteCollection({ goodsId: this.data.id }).then(() => {
                //         this.shoucangCD = true;
                //         this.init();
                //     });
                // } else {
                //     Collection.addCollection({
                //         goodsId: this.data.id,
                //     }).then((res) => {
                //         this.shoucangCD = true;
                //         this.init();
                //     });
                // }
                if (!this.isShoucang) {
                    Collection.addCollection({
                        goodsId: this.data.id,
                    }).then((res) => {
                        this.shoucangCD = true;
                        this.init();
                    });
                }
            }
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    addGouwuche() {
        if (sessionStorage.getItem('id')) {
            let cartList = [
                {
                    goodsId: this.data.id,
                    goodsSpecificationId: this.specsId,
                    number: this.num,
                },
            ];
            Cart.addBatchAddCart({
                cartList: cartList,
            }).then((res) => {
                this.$message.success('已加入购物车');
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    goumai(item) {
        let personalFixSalePrice = null;
        let personalSectionSalePriceOne = null;
        let personalSectionSalePriceTwo = null;
        let enterpriseFixSalePrice = null;
        let enterpriseSectionSalePriceOne = null;
        let enterpriseSectionSalePriceTwo = null;
        if (
            this.enterpriseFixSalePrice != null ||
            this.enterpriseSectionSalePriceOne != null ||
            this.enterpriseSectionSalePriceTwo != null ||
            this.personalFixSalePrice != null ||
            this.personalSectionSalePriceOne != null ||
            this.personalSectionSalePriceTwo != null
        ) {
            personalFixSalePrice = this.personalFixSalePrice;
            personalSectionSalePriceOne = this.personalSectionSalePriceOne;
            personalSectionSalePriceTwo = this.personalSectionSalePriceTwo;
            enterpriseFixSalePrice = this.enterpriseFixSalePrice;
            enterpriseSectionSalePriceOne = this.enterpriseSectionSalePriceOne;
            enterpriseSectionSalePriceTwo = this.enterpriseSectionSalePriceTwo;
        } else {
            personalFixSalePrice = item.personalFixSalePrice;
            personalSectionSalePriceOne = item.personalSectionSalePriceOne;
            personalSectionSalePriceTwo = item.personalSectionSalePriceTwo;
            enterpriseFixSalePrice = item.enterpriseFixSalePrice;
            enterpriseSectionSalePriceOne = item.enterpriseSectionSalePriceOne;
            enterpriseSectionSalePriceTwo = item.enterpriseSectionSalePriceTwo;
        }
        let orderList = [
            {
                platform: '1',
                receiveAddressId: '',
                receiver: '',
                phoneNumber: '',
                province: '',
                city: '',
                county: '',
                detail: '',
                address: '',
                remark: '',
                shopId: item.shopId,
                shopName: item.shopName,
                payMethod: '',
                orderChildList: [
                    {
                        cartId: '',
                        brand: item.brand,
                        merchantType: item.merchantType,
                        goodsId: item.id,
                        goodsNumber: item.goodsNumber,
                        name: item.name,
                        deliveryAddress: item.deliveryAddress,
                        weight: this.weight,
                        personalFixSalePrice: personalFixSalePrice,
                        personalSectionSalePriceOne: personalSectionSalePriceOne,
                        personalSectionSalePriceTwo: personalSectionSalePriceTwo,
                        enterpriseFixSalePrice: enterpriseFixSalePrice,
                        enterpriseSectionSalePriceOne: enterpriseSectionSalePriceOne,
                        enterpriseSectionSalePriceTwo: enterpriseSectionSalePriceTwo,
                        specId: this.specsId,
                        specName: this.specsName,
                        goodsQuantity: this.num,
                        coverImageAttachment: item.coverImageAttachmentList[0],
                        mainBusiness: item.mainBusiness,
                    },
                ],
            },
        ];
        if (sessionStorage.getItem('id')) {
            sessionStorage.setItem('goodsList', JSON.stringify(orderList));
            this.$router.push({
                path: '/submitOrder',
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    jumpShop() {
        this.$router.push({
            path: '/shop',
            query: {
                shopId: this.fullShopDetail.id,
            },
        });
    }
    followShop() {
        if (sessionStorage.getItem('id')) {
            Focus.addFocus({
                shopId: this.data.shopId,
            }).then((res) => {
                this.init();
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    deletfollow() {
        Focus.DeleteByShopId({
            shopId: this.data.shopId,
        }).then(() => {
            this.init();
        });
    }
    jumpDetail(id) {
        this.$router.push({
            path: '/goodsDetail',
            query: {
                id: id,
            },
        });
    }
    @Watch('$route')
    changeRoute(to, from) {
        this.init();
    }
}
