




























import { Vue, Component } from "vue-property-decorator";
import store from "@/vuex/index";
@Component({
  components: {}
})
export default class companyInfo extends Vue {

  created() {
  }

}
