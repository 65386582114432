








import { Vue, Component } from 'vue-property-decorator';
import OrderPanelCart from './orderPanelCart.vue';
@Component({
    components: {
        OrderPanelCart,
    },
})
export default class shoppingCart extends Vue {

    public created() {}
    public mounted() {}
}
