/*
 * @Description: 
 * @Autor: jyf
 * @Date: 2021-04-27 14:40:52
 * @LastEditors: jyf
 * @LastEditTime: 2021-05-05 16:13:53
 */
import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace BannerAdvert {
  /**
   * 获取banner列表
   */
  export var GetBannerAdvertList = (query: any): Promise<any> => {
    return Get(`/api/BannerAdvert?${qs.stringify(query)}`, {});
  };

}
