import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import qs from 'qs';
export namespace PlatformAccord {
    /**
     * 获取门店招商
     */
    export var businessInvitation = (): Promise<any> => {
        return Get(`/api/PlatformAccord/businessInvitation`, '');
    };
}
