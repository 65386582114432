/*
 * @Autor: dingxiaolin
 * @Date: 2020-12-25 00:39:22
 * @LastEditors: jyf
 * @LastEditTime: 2021-04-26 23:15:33
 */
/**
 * 个位数，加0前缀
 * @param {*} number
 * @returns
 */
function addZeroPrefix(number) {
    return number < 10 ? `0${number}` : number
}
// function addZeroPrefix (n) { //另类写法
//     const str = n.toString()
//     return str[1] ? str : `0${str}`
// }

/**
 * 返回年月日 分割符/
 * @param {Date} date 
 * @param {String} splitor 
 */
function getDate(date, splitor = '-') {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${year}${splitor}${addZeroPrefix(month)}${splitor}${addZeroPrefix(day)}`
}
/**
 * 返回时分秒/时分
 * @export
 * @param {*} date
 * @param {boolean} [withSecond=false]
 * @returns
 */
function getTime(date, withSecond = false) {
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    return withSecond ? `${addZeroPrefix(hour)}:${addZeroPrefix(minute)}:${addZeroPrefix(second)}` : `${hour}:${addZeroPrefix(minute)}`
}
/**
 * 年/月/日 时:分:秒
 * @param {DateTime} date 
 */
function getFullDate(date) {
    return `${getDate(date)} ${getTime(date)}`
}
/**
 * 判断是否是今天
 * @param {Boolean} date 
 */
function isToday(date) {
    return date.toDateString() === new Date().toDateString()
}
/**
 * 总秒数计算为时分秒
 * @param {*} second 秒
 */
function formatDuration(second) {
    let interval = second
    let continued = ''
    if (interval >= 3600) {
        const hour = Math.floor(interval / 3600)
        continued += hour + '小时'
        interval -= hour * 3600
    }
    if (interval >= 60 && interval < 3600) {
        const min = Math.floor(interval / 60)
        continued += min + '分'
        interval -= min * 60
    }
    if (interval < 60) {
        continued += Math.floor(interval) + '秒'
    }
    return continued
}

function getHoursDiff(dt1, dt2) {
    var res = dt2 - dt1;
    if (isNaN(res)) throw Error("invalid dates arguments");
    return res / (1000 * 60 * 60);
}

function getRemainTime(time) {
    const sdtime1 = new Date(time.replace(/-/g, '/'));
    const data =
        (Date.parse(new Date(sdtime1.setHours(sdtime1.getHours() + 48))) -
            Date.parse(new Date())) /
        1000;
    const day = parseInt(data / (24 * 60 * 60)); //计算整数天数
    const afterDay = data - day * 24 * 60 * 60; //取得算出天数后剩余的秒数
    const hour = parseInt(data / (60 * 60));
    var dt1 = new Date(sdtime1.setHours(sdtime1.getHours() + 48));
    var dt2 = new Date();

    var re = getHoursDiff(dt1, dt2);
    var h = parseInt(re);
    var m = parseInt((re - h) * 60);
    return `${hour}小时${Math.abs(m)}分自动关闭`;
}





module.exports = {
    getDate,
    getTime,
    getFullDate,
    isToday,
    formatDuration,
    getRemainTime
}
