











































































import { Vue, Component } from 'vue-property-decorator';
import OrderPanelCart from './orderPanelCart.vue';
import { AddressSelect } from '@/components';
import { PaginationPanel } from '@/components';
import { ReceiverInfo } from '@/http';
import { formRules } from '@/common/util/validator';
export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        OrderPanelCart,
        AddressSelect,
        PaginationPanel,
    },
})
export default class receivingAddress extends Vue {
    public formRules = formRules;
    public $refs: {
        address: HTMLFormElement;
        form: HTMLFormElement;
    };

    public title = '新增收货地址';

    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };

    edit(row) {
        this.$nextTick(() => {
            this.title = '编辑收货地址';
            this.dialogVisible = true;
            this.form = {
                receiver: '',
                phoneNumber: '',
                detail: '',
                isDefault: false,
            };
            this.editId = row.id;
            this.SelectAddress.province.code = '';
            this.SelectAddress.city.code = '';
            this.SelectAddress.area.code = '';
            this.provinceCode = '';
            this.cityCode = '';
            this.areaCode = '';
            this.address = '';
            this.form.receiver = row.receiver;
            this.form.phoneNumber = row.phoneNumber;
            this.form.detail = row.detail;
            this.form.isDefault = row.isDefault;
            this.SelectAddress.province.code = row.province;
            this.SelectAddress.city.code = row.city;
            this.SelectAddress.area.code = row.county;
            this.provinceCode = row.province;
            this.cityCode = row.city;
            this.areaCode = row.county;
            this.address = row.address;
            console.log('this.editId ' + this.editId);
            this.$refs.address.setValue(this.SelectAddress);
        });
    }

    public rePagin: boolean = false;
    public total: number = 0;

    public async onPaginationChange(pagin: Pagination) {
        this.searchPagin = pagin;
        this.loadData();
    }

    dialogVisible = false;
    address = '';

    form: any = {
        receiver: '',
        phoneNumber: '',
        detail: '',
        isDefault: false,
    };

    async editSave() {
        let valid1 = this.$refs.address.validateForm();
        this.$refs.form.validate(async (valid) => {
            if (valid) {
                if (valid1) {
                    await ReceiverInfo.editReceiverInfo({
                        address: this.address,
                        province: this.provinceCode,
                        city: this.cityCode,
                        county: this.areaCode,
                        detail: this.form.detail,
                        isDefault: this.form.isDefault,
                        phoneNumber: this.form.phoneNumber,
                        receiver: this.form.receiver,
                        id: this.editId,
                    });
                    this.$message.success('修改成功');
                    this.dialogVisible = false;
                    this.editId = '';
                    this.loadData();
                }
            }
        });
    }

    async deleteRow(row) {
        this.$confirm('确定要删除吗?')
            .then(async () => {
                await ReceiverInfo.receiverInfoDelete(row.id);
                this.$message.success('删除成功');
                this.loadData();
            })
            .catch(() => {});
    }

    async setDefault(row) {
        this.form.receiver = row.receiver;
        this.form.phoneNumber = row.phoneNumber;
        this.form.detail = row.detail;
        this.SelectAddress.province.code = row.province;
        this.SelectAddress.city.code = row.city;
        this.SelectAddress.area.code = row.county;
        this.provinceCode = row.province;
        this.cityCode = row.city;
        this.areaCode = row.county;
        this.address = row.address;
        this.editId = row.id;
        await ReceiverInfo.editReceiverInfo({
            address: this.address,
            province: this.provinceCode,
            city: this.cityCode,
            county: this.areaCode,
            detail: this.form.detail,
            isDefault: true,
            phoneNumber: this.form.phoneNumber,
            receiver: this.form.receiver,
            id: this.editId,
        });
        this.$message.success('修改成功');
        this.editId = '';
        this.loadData();
    }

    editId = '';

    async save() {
        let valid1 = this.$refs.address.validateForm();
        this.$refs.form.validate(async (valid) => {
            if (valid) {
                if (valid1) {
                    await ReceiverInfo.addReceiverInfo({
                        address: this.province + this.city + this.area,
                        province: this.provinceCode,
                        city: this.cityCode,
                        county: this.areaCode,
                        detail: this.form.detail,
                        isDefault: this.form.isDefault,
                        phoneNumber: this.form.phoneNumber,
                        receiver: this.form.receiver,
                    });
                    this.$message.success('添加成功');
                    this.handleClose();
                    this.loadData();
                }
            }
        });
    }

    handleClose() {
        // this.$refs.address.clear();
        this.dialogVisible = false;
        this.form = {
            receiver: '',
            phoneNumber: '',
            detail: '',
            isDefault: false,
        };
        this.SelectAddress.province.code = '';
        this.SelectAddress.city.code = '';
        this.SelectAddress.area.code = '';
        this.provinceCode = '';
        this.cityCode = '';
        this.areaCode = '';
        this.address = '';
        this.$refs.address.reloadSelect();
    }

    addNewAdd() {
        this.$nextTick(() => {
            if (this.$refs.form) {
                this.$refs.form.resetFields();
            }
        });

        this.title = '新增收货地址';
        if (this.$refs.address) {
            this.$refs.address.clear();
        }

        this.dialogVisible = true;
        this.form = {
            receiver: '',
            phoneNumber: '',
            detail: '',
            isDefault: false,
        };
    }

    public tableData = [];

    public created() {}
    public mounted() {
        this.loadData();
    }
    async loadData() {
        let temp = await ReceiverInfo.getReceiverInfo({
            skip: this.searchPagin.skip,
            count: this.searchPagin.count,
        });
        this.tableData = temp.data;
        this.total = temp.total;
    }

    //---地址选择start---
    public selectReset: boolean = true;
    public async updateReset() {
        this.selectReset = true;
    }

    public provinceCode = '';
    public province = '';
    public cityCode = '';
    public city = '';
    public areaCode = '';
    public area = '';
    public async getProvince(item, name) {
        if (item == '') {
            this.provinceCode = '';
            this.cityCode = '';
            this.areaCode = '';
            this.province = '';
            this.city = '';
            this.area = '';
        }
        this.provinceCode = item;
        this.province = name;
    }

    public async getCityCode(item, name) {
        this.cityCode = item;
        this.city = name;
    }

    public async getAreaCode(item, name) {
        this.areaCode = item;
        this.area = name;
        this.address = this.province + this.city + this.area;
    }

    public SelectAddress = {
        province: {
            value: '',
            text: '',
            code: '',
        },
        city: {
            value: '',
            text: '',
            code: '',
        },
        area: {
            value: '',
            text: '',
            code: '',
        },
    };
    //---地址选择--end---
}
