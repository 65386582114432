
































import { Vue, Component, Watch } from 'vue-property-decorator';
import CommonPanel from './components/CommonPanel.vue';
import footerBar from '@/components/footerBar.vue';
import { PlatformAccord } from '@/http';
@Component({
    components: {
        CommonPanel,
        footerBar,
    },
})
export default class attractShow extends Vue {
    mounted() {
        this.init();
    }
    data_ = null;
    async init() {
        this.data_ = await PlatformAccord.businessInvitation();
        console.log('this.data_------------->' + JSON.stringify(this.data_))
    }

    jumpOut(webLink) {
        console.log('webLink-------->' + webLink)
        if (webLink && webLink != '') {
            if (webLink.indexOf('http') > -1) {
                window.open(webLink);
            } else {
                window.open('https://' + webLink);
            }
        } else {
        }
    }
}
