





















import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({})
export default class CommonPanel extends Vue {
    @Prop()
    public title: string;
    @Prop()
    public height: boolean;
    @Prop()
    public name: string;
}
