














import { Vue, Component } from 'vue-property-decorator';
import BlankContailerPanel from '../../components/BlankContailerPanel.vue';
import EvaluatPanel from './evaluatPanel.vue';
import { NavBar } from '../home/components';
import orderInfo from './components/orderInfo.vue';

@Component({
    components: {
        BlankContailerPanel,
        EvaluatPanel,
        NavBar,
        orderInfo,
    },
})
export default class myEvaluate extends Vue {
    public $refs: {
        evaluat: HTMLFormElement;
        orderInfo: HTMLFormElement;
    };
    public mid: any = '';
    public created() {}
    public mounted() {
        this.mid = this.$route.query.id;
        console.log('this.mid' + this.mid);
        this.$nextTick(() => {
            this.$refs.evaluat.setData(this.mid);
            this.$refs.orderInfo.setData(this.mid);
        });
    }
}
