import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace Focus {
    /**
     * 添加关注记录
     */
    export var addFocus = (body: any): Promise<any> => {
        return Post(`/api/Focus`, body);
    };
    /**
     * 用户是否关注此店铺
     */
    export var isUserFocus = (query: any): Promise<any> => {
        return Get(`/api/Focus/IsUserFocus?${qs.stringify(query)}`, '');
    };
    /**
     * 删除关注记录
     */
    export var deleteFocus = (id: any): Promise<any> => {
        return Put(`/api/Focus/Delete/${id}`, {});
    };
    /**
     * 获取关注的店铺
     */
    export var getFocusList = (query: any): Promise<any> => {
        return Get(`/api/Focus?${qs.stringify(query)}`, '');
    };
    /**
     * 删除关注的店铺
     */
    export var DeleteByShopId = (body: any): Promise<any> => {
        return Put(`/api/Focus/DeleteByShopId`, body);
    };
}
