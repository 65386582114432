




















import { Component, Vue, Watch } from 'vue-property-decorator';
import store from '@/vuex/index';
import { SideBar, footerBar, AppMain, NavBar, HeaderBar, companyInfo, search } from './components';
import { AccessRecord } from '@/http';
declare var returnCitySN: any;
@Component({
    components: {
        SideBar,
        footerBar,
        AppMain,
        NavBar,
        HeaderBar,
        companyInfo,
        search,
    },
})
export default class home extends Vue {
    public sysName: string = '';
    public collapsed: boolean = false;
    public sysUserName: string = '';
    public sysUserAvatar: string = '';
    public form = {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: null,
        resource: '',
        desc: '',
    };

    public $refs: {
        menuCollapsed: HTMLFormElement;
        elconRef: HTMLFormElement;
    };

    created() {
        this.sysUserAvatar = '/static/logo.ico';
        if (sessionStorage.getItem('id')) {
            console.log('id' + sessionStorage.getItem('id'));
            AccessRecord.AddAccessRecord({
                webHost: returnCitySN['cip'],
                weChatOpenId: null,
                dataId: sessionStorage.getItem('id'),
                accessPage: 'Home',
                creatorUserName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : '',
            });
        }
    }

    onSubmit() {}

    //折叠导航栏
    public collapse = function () {
        this.collapsed = !this.collapsed;
    };
    public showMenu(i, status) {
        let element: any = this.$refs.menuCollapsed.getElementsByClassName('submenu-hook-' + i)[0];
        element.style.display = status ? 'block' : 'none';
    }
}
