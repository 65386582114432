













































































































































import { Vue, Component } from 'vue-property-decorator';
import { formRules } from '@/common/util/validator';
import { PaginationPanel } from '@/components';
import { Collection, Cart } from '@/http';
export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}

@Component({
    components: {
        PaginationPanel,
    },
})
export default class myCollect extends Vue {
    public formRules = formRules;
    public isIndeterminate: boolean = false;
    public checkAll: boolean = false;
    public checkedCollection: any[] = [];
    public collectionOptions: string[] = [];
    public isShoucang: number = null;
    public rePagin: boolean = false;
    public total: number = 0;

    public collectionList = [];
    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };
    public async onPaginationChange(pagin: Pagination) {
        this.searchPagin = pagin;
        this.loadData();
    }

    cancelCollection(item) {
        Collection.deleteCollection({ goodsId: item.collectionGoods.id }).then(() => {
            this.$message.success('已取消收藏');
            this.loadData();
        });
    }
    handleCheckAllChange(val) {
        this.checkedCollection = val ? this.collectionOptions : [];
        this.isIndeterminate = false;
    }
    handlecheckedCollectionChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.collectionList.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.collectionList.length;
    }
    deleteAll() {
        if (this.checkedCollection.length == 0) return;
        let tempArr = this.checkedCollection.join(',');
        console.log('tempArr' + tempArr);
        Collection.DeleteAll(tempArr).then(() => {
            this.loadData();
        });
    }

    public userType = null;
    mounted() {
        this.userType = sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : '';
        this.loadData();
        console.log('this.userType' + this.userType);
    }

    public addGouwucheCD = true;
    // addGouwuche(item) {
    //     if (sessionStorage.getItem('id')) {
    //         if (this.addGouwucheCD) {
    //             let cartList = [
    //                 {
    //                     goodsId: item.collectionGoods.id,
    //                     goodsSpecificationId: item.collectionGoods.goodsSpec,
    //                     number: 1,
    //                 },
    //             ];
    //             this.addGouwucheCD = false;
    //             console.log(cartList);
    //             Cart.addBatchAddCart({
    //                 cartList: cartList,
    //             }).then((res) => {
    //                 this.addGouwucheCD = true;
    //                 this.$message.success('已加入购物车');
    //             });
    //         }
    //     } else {
    //         this.$router.push({
    //             path: '/login',
    //         });
    //     }
    // }

    jumpDetail(item) {
        if (item.collectionGoods.isEnabled && item.collectionGoods.status == 3) {
            this.$router.push({
                path: '/goodsDetail',
                query: {
                    id: item.collectionGoods.id,
                },
            });
        } else {
            this.$alert('商品已下架');
        }
    }

    jumpShop(item) {
        this.$router.push({
            path: '/shop',
            query: {
                shopId: item.collectionGoods.shopId,
            },
        });
    }

    lianxi(item) {
        if (sessionStorage.getItem('id')) {
            this.$root.$emit('lianxi', item.collectionGoods.shopId);
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }

    loadData() {
        this.checkAll = false;
        this.checkedCollection = [];
        this.collectionOptions = [];
        this.isIndeterminate = false;
        Collection.getCollection({
            skip: this.searchPagin.skip,
            count: this.searchPagin.count,
        }).then((res) => {
            this.collectionList = res.data;
            this.collectionList.forEach((item) => {
                this.collectionOptions.push(item.id);
            });
            this.total = res.total;
        });
    }
}
