













import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({})
export default class TopPanel extends Vue {
    @Prop()
    public title: string;
    @Prop()
    public content: string;
    @Prop({ default: 'grey' })
    public color1: string;
    @Prop({ default: 'grey' })
    public color2: string;
    @Prop()
    public yulin: string;
    @Prop()
    public hexiao: string;
}
