




import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { CommonPanel } from '@/components';
import { formRules } from '@/common/util/validator';
import { upload } from '@/common/util/ossUploadService';
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import Editor from '@tinymce/tinymce-vue';
import 'tinymce/plugins/code';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/paste';

@Component({
    components: {
        CommonPanel,
        Editor,
    },
})
export default class DescribePanel extends Vue {
    @Prop({
        default: false,
    })
    public isDisabled: any;
    @Prop()
    public apply: any;
    @Prop()
    public data: any;

    public tinymceHtml: string = '';

    @Watch('tinymceHtml')
    dataChange() {
        this.$emit('dataChange', this.tinymceHtml);
    }
    @Watch('data')
    changeData(v) {
        this.tinymceHtml = v;
    }

    setData(data) {
        this.tinymceHtml = data;
    }

    public mounted() {
        if (this.data) {
            this.tinymceHtml = this.data;
        }
        tinymce.init({});
    }
    public init = {
        content_css: '/static/tinymce/skins/content/default/content.css',
        language_url: '/static/tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/static/tinymce/skins/ui/oxide',
        height: 400,
        menubar: false, //顶部菜单栏显示
        plugins: 'lists image imagetools importcss code table colorpicker textcolor wordcount contextmenu paste',
        toolbar:
            'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo  | removeformat | table | image',
        branding: false,
        automatic_uploads: true,
        paste_data_images: true,
        paste_retain_style_properties: 'color',
        paste_word_valid_elements:
            'table[width|border|border-collapse],tr,td[colspan|rowspan|width],th[colspan|rowspan|width],thead,tfoot,tbody,h1,h2,h3,h4,h5,h6,span,strong,p,div',
        images_upload_handler: (blobInfo, success, failure) => {
            let files = {};
            files = new window.File([blobInfo.blob()], blobInfo.blob().name, { type: blobInfo.blob().type });
            upload(files, blobInfo.blob().name ? blobInfo.blob().name : '').then((res) => {
                if (res.res.statusCode == '200') {
                    success(res.res.requestUrls[0].split('?')[0]);
                    failure('上传失败');
                } else {
                    failure('上传失败');
                    this.$message.warning('上传图片失败!');
                }
            });
        },
    };
}
