


















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { uploadStream } from '@/common/util/ossUploadService';
import Cropper from 'cropperjs';
@Component({})
export default class CropperDialogue extends Vue {
    @Prop({ default: false })
    showdialog;
    @Prop({ default: '' })
    url;
    @Prop({ default: '' })
    name;
    /**
     * logo选择插件
     */
    public cropper: any;
    /**头像图片名称 */
    public avatarName = '';
    @Watch('showdialog')
    change(v) {
        if (v) {
            this.$nextTick(() => {
                /* 头像选择 */
                let img = document.getElementById('tailoringImg') as HTMLCanvasElement;
                this.cropper = new Cropper(img, {
                    aspectRatio: 1 / 1,
                    dragMode: 'move',
                    preview: '.previewImg', // 预览视图
                    guides: false, // 裁剪框的虚线(九宫格)
                    autoCropArea: 0.7, // 0-1之间的数值，定义自动剪裁区域的大小，默认0.8
                    movable: true, // 是否允许移动剪裁框
                    zoomable: true, // 是否允许缩放图片大小
                    rotatable: true, // 是否允许旋转图片
                    cropBoxMovable: false, //是否允许拖动裁剪框
                    cropBoxResizable: false, //是否允许拖动 改变裁剪框大小
                    ready: function (e) {
                        $('.cropper-face').html(
                            '<div class="face-guide" data-cropper-action="all"></div>'
                        );
                    },
                    crop: function (e) {
                        // 输出结果数据裁剪图像。
                    },
                });
                if (this.$store.state.blobUrl) {
                    this.cropper.replace(this.$store.state.blobUrl);
                }
            });
        }
    }
    close() {
        this.$emit('update:showdialog', false);
    }
    mounted() {}
    getObjectURL(file) {
        var url = null;
        if ((window as any).createObjectURL != undefined) {
            // basic
            url = (window as any).createObjectURL(file);
        } else if (window.URL != undefined) {
            // mozilla(firefox)
            url = window.URL.createObjectURL(file);
        } else if (window.webkitURL != undefined) {
            // webkit or chrome
            url = window.webkitURL.createObjectURL(file);
        }
        return url;
    }
    /* 头像上传 */
    public selectImg(event) {
        let file = event.target.files[0];
        if (file) {
            var fileMaxSize = 1024 * 5; //5M
            var fileSize = file.size;
            var size = fileSize / 1024;
            //   console.log(size);
            // this.imgSize = this.readFileSize(file.size);
            this.avatarName = file.name;
            if (size > fileMaxSize) {
                this.$message.warning(`上传图片大小不能超过 5MB`);
            } else {
                this.$store.state.blobUrl = this.getObjectURL(file);
                if (this.cropper) {
                    this.cropper.replace(this.$store.state.blobUrl);
                }
            }
        } else {
            this.$message.warning(`请选择图片！`);
            return null;
        }
    }
    /* 确认保存头像 */
    public confirm() {
        if (!this.avatarName) {
            this.$message.warning(`请先上传图片`);
            return;
        }
        uploadStream(this.cropper.getCroppedCanvas().toDataURL('image/png'), (res) => {
            if (res.res.statusCode == '200') {
                this.$emit('update:showdialog', false);
                this.$emit('update:url', res.url);
                this.$emit('update:name', this.avatarName);
            } else {
                this.$message.error('图片上传失败!');
            }
        });
    }
}
