
































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import Bus from '@/common/util/bus';
import { GoodsType } from '@/http';

@Component({
    components: {},
})
export default class NavBar extends Vue {
    @Prop({
        default: '#fff',
    })
    public backgroundColor: string;
    @Prop({
        default: null,
    })
    public classify: any;
    @Prop()
    public name: string;
    @Prop()
    public goodsName: string;
    public sysName: string = '';
    public collapsed: boolean = false;
    public sysUserName: string = '';
    public anchorId: string = '';
    public showButton: boolean = false;

    created() {}
    @Watch('$route')
    routeChange() {}
    //折叠导航栏
    public collapse = function () {
        this.collapsed = !this.collapsed;
        Bus.$emit('collapsed', this.collapsed);
    };
    tiaozhuan(item) {
        if (item.meta.title == '首页') {
            this.$router.push({
                path: `/`,
            });
        } else if (item.meta.title == '个人中心') {
            this.$router.push({
                path: `/personal/order`,
            });
        } else if (item.meta.title == '聚天自营') {
            this.$router.push({
                path: `/own`,
            });
        } else if (item.meta.title == '平台活动') {
            this.$router.push({
                path: `/activity/activity`,
            });
        }
    }
    jump1(item) {
        if (item == '首页') {
            this.$router.push({
                path: `/`,
            });
        } else if (item == '个人中心') {
            this.$router.push({
                path: `/personal/order`,
            });
        } else if (item == '我评价的') {
            this.$router.push({
                path: `/invitation/comment`,
            });
        } else if (item == '我发布的') {
            this.$router.push({
                path: `/invitation/publish`,
            });
        } else if (item == '聚天贴吧') {
            this.$router.push({
                path: `/interaction`,
            });
        } else if (item == '聚天特惠') {
            this.$router.push({
                path: `/preferential`,
            });
        } else if (item == '特价甩货') {
            this.$router.push({
                path: `/specialOff`,
            });
        } else if (item == '限时拼购') {
            this.$router.push({
                path: `/shopTogether`,
            });
        } else if (item == '新品试用') {
            this.$router.push({
                path: `/newProduc`,
            });
        }
    }
    async jump(v) {
        if (v == '首页') {
            this.$router.push({
                path: `/`,
            });
        } else if (v == '1') {
            this.$router.push({
                path: `/goodsList`,
                query: {
                    id: this.classify.goodsTypeOneId,
                    level: 'One',
                    parentGoodsTypeId: '',
                },
            });
        } else if (v == '2') {
            /**获取完整商品分类 */
            let list = await GoodsType.GetFullGoodsTypeList();
            let parentGoodsTypeId = '';
            list.data.forEach((e) => {
                if (e.id == this.classify.goodsTypeOneId) {
                    e.twoLevelGoodsTypeList.forEach((ele) => {
                        if (ele.id == this.classify.goodsTypeTwoId) {
                            parentGoodsTypeId = ele.parentGoodsTypeId;
                        }
                    });
                }
            });
            this.$router.push({
                path: `/goodsList`,
                query: {
                    id: this.classify.goodsTypeTwoId,
                    level: '2',
                    parentGoodsTypeId: parentGoodsTypeId,
                },
            });
        } else if (v == '3') {
            /**获取完整商品分类 */
            let list = await GoodsType.GetFullGoodsTypeList();
            let parentGoodsTypeId = '';
            list.data.forEach((e) => {
                if (e.id == this.classify.goodsTypeOneId) {
                    e.twoLevelGoodsTypeList.forEach((ele) => {
                        if (ele.id == this.classify.goodsTypeTwoId) {
                            ele.threeLevelGoodsTypeList.forEach((element) => {
                                if (element.id == this.classify.goodsTypeThreeId) {
                                    parentGoodsTypeId = element.parentGoodsTypeId;
                                }
                            });
                        }
                    });
                }
            });
            this.$router.push({
                path: `/goodsList`,
                query: {
                    id: this.classify.goodsTypeThreeId,
                    level: '3',
                    parentGoodsTypeId: parentGoodsTypeId,
                },
            });
        }
    }
}
