













































import { Vue, Component } from 'vue-property-decorator';
import { formRules } from '@/common/util/validator';
import { PaginationPanel } from '@/components';
import { Focus } from '@/http';
export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}

@Component({
    components: {
        PaginationPanel,
    },
})
export default class followShops extends Vue {
    public formRules = formRules;
    public isIndeterminate: boolean = false;
    public checkAll: boolean = false;
    public checkedCities: any[] = [];
    public isGuanzhu: number = null;
    public rePagin: boolean = false;
    public total: number = 0;
    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };
    public async onPaginationChange(pagin: Pagination) {
        this.searchPagin = pagin;
        this.loadData();
    }
    lianxi() {
        console.log(111111);
    }

    mounted() {
        this.loadData();
    }

    async cancalFollow(row) {
        await Focus.DeleteByShopId({
            shopId: row.id,
        });
        this.$message.success('操作成功');
        this.loadData();
    }

    toShop(row) {
        if (row.isEnabled) {
            this.$router.push({
                path: '/shop',
                query: {
                    shopId: row.id,
                },
            });
        } else {
            this.$alert('店铺已禁用');
        }
    }

    public focusList = [];
    public loadData() {
        Focus.getFocusList({
            skip: this.searchPagin.skip,
            count: this.searchPagin.count,
        }).then((res) => {
            this.focusList = res.data;
            this.total = res.total;
        });
    }
}
