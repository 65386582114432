

















































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { formRules } from '@/common/util/validator';
import { User, Login } from '@/http';
import headerPanel from './component/headerPanel.vue';
import footerBar from './component/footerBar.vue';
import { JSEncrypt } from 'jsencrypt';

@Component({
    components: {
        headerPanel,
        footerBar,
    },
})
export default class forgetPassword extends Vue {
    public data: any = {};
    public disYzm: boolean = true;
    public yzmLogin: boolean = true;
    public canShow: boolean = false;
    public active: number = 0;
    public $refs: {
        forgetForm: HTMLFormElement;
    };
    public forgetForm = {
        phoneNumber: '',
        SMSCode: '',
        newPassword: '',
        confirmPassword: '',
    };
    public btnMessage: string = '获取验证码';
    public countDowning: boolean = false;
    // 倒计时
    interval: any;
    public formRules = formRules;
    public passWordIsTrue: boolean = false;
    created() {
        this.canShow = false;
    }
    mounted() {
        if (this.forgetForm.phoneNumber == '' || this.forgetForm.SMSCode == '') {
            this.yzmLogin = true;
        } else {
            this.yzmLogin = false;
        }
        if (this.forgetForm.phoneNumber != '') {
            this.disYzm = false;
        } else {
            this.disYzm = true;
        }
    }
    resetForm(formName) {
        if (this.$refs[formName] !== undefined) {
            this.$refs[formName].resetFields();
        }
        switch (formName) {
            case 'forgetForm':
                break;
            default:
                break;
        }
    }
    // 获取验证码
    getCode() {
        if (!this.disYzm) {
            User.GetSMSCode({
                phoneNum: this.forgetForm.phoneNumber,
                codeType: '9',
            }).then((res) => {
                this.$message.success('验证码已发送');
                this.countCode();
            });
        }
    }
    changePassword(value, num) {
        if (num == 0) {
            if (this.forgetForm.confirmPassword != '') {
                if (value != this.forgetForm.confirmPassword) {
                    this.passWordIsTrue = false;
                    // this.$message.warning('两次密码输入不一致');
                } else {
                    this.passWordIsTrue = true;
                }
            }
        } else {
            if (this.forgetForm.newPassword != '') {
                if (value != this.forgetForm.newPassword) {
                    this.passWordIsTrue = false;
                    // this.$message.warning('两次密码输入不一致');
                } else {
                    this.passWordIsTrue = true;
                }
            }
        }
    }
    // 倒计时
    countCode() {
        this.disYzm = true;
        this.countDowning = true;
        let i = 60;
        this.interval = setInterval(() => {
            this.btnMessage = `${--i}秒后重试`;
            if (i === 0) {
                clearInterval(this.interval);
                this.btnMessage = '获取验证码';
                this.disYzm = false;
                this.countDowning = false;
            }
        }, 1000);
    }
    // 下一步
    handleForget(v) {
        if (v == 0) {
            this.$refs.forgetForm.validate((valid) => {
                if (valid) {
                    User.CheckSMSCode({
                        phoneNumber: this.forgetForm.phoneNumber,
                        SMSCode: this.forgetForm.SMSCode,
                    }).then((res) => {
                        this.active++;
                    });
                }
            });
        } else {
            this.$refs.forgetForm.validate((valid) => {
                if (valid) {
                    if (this.passWordIsTrue) {
                        const jsencrypt = new JSEncrypt({});
                        jsencrypt.setPublicKey(
                            'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDMkmzEoj7LFqLmfKqix11b2A5SMXsAE9ag/9Uq+YeiJfFq5TtP7VpGdJUx06SgQqghgWL5hXLRVTMUn8LVW2SmHV0WjvFAqhtTK9CyTYFQ9wFXy7tpxHcl6jZnw8rokzY2y4yN5WbzEJ4+1c9j0Yp+oeTk0NauyOC03lEOEY9BGwIDAQAB'
                        );
                        let password = jsencrypt.encrypt(this.forgetForm.newPassword);
                        User.ResetPwd({
                            phoneNumber: this.forgetForm.phoneNumber,
                            SMSCode: this.forgetForm.SMSCode,
                            password: password,
                        }).then((res) => {
                            this.active++;
                            this.$message.success('设置成功');
                            let obj = {
                                codeType: '8',
                                webLoginMode: 2,
                                phoneNumber: this.forgetForm.phoneNumber,
                                password: password,
                            };
                            Login.login(obj)
                                .then((res) => {
                                    this.successLogin(res);
                                })
                                .catch((e) => {
                                    this.canShow = true;
                                });
                        });
                    } else {
                        this.$message.warning('两次密码输入不一致');
                    }
                }
            });
        }
    }
    successLogin(res) {
        sessionStorage.setItem('id', res.id);
        sessionStorage.setItem('jwtToken', res.jwtToken);
        sessionStorage.setItem('headPath', res.headPath);
        sessionStorage.setItem('userName', res.userName);
        sessionStorage.setItem('phoneNumber', res.phoneNumber);
        sessionStorage.setItem('isOpenTencentCloud', res.isOpenTencentCloud);
        sessionStorage.setItem('mainBusiness', res.mainBusiness);
        sessionStorage.setItem('userType', res.userType);
        sessionStorage.setItem('enterpriseCode', res.enterpriseCode);
        sessionStorage.setItem('enterpriseJob', res.enterpriseJob);
        sessionStorage.setItem('enterpriseName', res.enterpriseName);
        sessionStorage.setItem('isEnabled', res.isEnabled);
        sessionStorage.setItem('isFirstLogin', res.isFirstLogin);
        sessionStorage.setItem('userSig', res.userSig);
        sessionStorage.setItem('realName', res.realName);
        sessionStorage.setItem('isOpenTencentCloud', res.isOpenTencentCloud);
        sessionStorage.setItem('userSig', res.userSig);
        this.data = { ...res };
        this.$store.state.isId = res.id;
    }
    guang() {
        // window.location.href = 'http://jutiangas.com/#/';
        this.$router.push({
            path: '/',
        });
    }
    @Watch('forgetForm.phoneNumber')
    public forgetFormPwdChange(phoneNumber) {
        if (phoneNumber == '' || this.forgetForm.SMSCode == '') {
            this.yzmLogin = true;
        } else {
            this.yzmLogin = false;
        }
        if (!this.countDowning && phoneNumber.length > 10) {
            this.disYzm = false;
        } else {
            this.disYzm = true;
        }
    }
    @Watch('forgetForm.SMSCode')
    public forgetFormCodeChange(SMSCode) {
        if (this.forgetForm.phoneNumber == '' || SMSCode == '') {
            this.yzmLogin = true;
        } else {
            this.yzmLogin = false;
        }
    }
    login() {
        this.$router.push({
            path: '/login',
        });
    }
}
