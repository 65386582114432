












































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PaginationPanel } from '@/components';
import { fmoney } from '@/common/util/money';
import { NavBar } from '../home/components';
import RightAnchor from '@/components/RightAnchor.vue';
import { GoodsType, Goods, Collection, Cart, GoodsProperty } from '@/http';

export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        NavBar,
        PaginationPanel,
        RightAnchor,
    },
})
export default class goodsList extends Vue {
    public activeName = '';
    public tabIndex = -1;
    mouseover(name, index) {
        console.log('name:' + name);
        this.tabIndex = index;
        this.activeName = name;
    }
    handleClick(tab, event) {
        this.tabIndex = tab.index;
        console.log('activeName' + this.activeName);
        console.log(tab.index, event);
    }
    activeIndex = '';
    handleSelect(key, keyPath) {
        console.log(key, keyPath);
    }
    hideTabs() {
        this.tabIndex = -1;
        this.activeName = null;
    }
    public goodsPropertyList: any = [];
    public itemCheckList: any[] = [];
    public total: number = 0;
    public rePagin: boolean = false;
    public radio1: string = '1';
    public checkList: string[] = [];
    public goodLists: any[] = [];
    public goodsTypeLists: any[] = [];
    public shopBrands: any[] = [];
    public fenleiOneList: any[] = [];
    public fenleiTwoList: any[] = [];
    public fenleiThreeList: any[] = [];
    public fenleiOnevalue: string = '';
    public fenleiTwovalue: string = '';
    public fenleiThreevalue: string = '';
    public isShowSelectOne: boolean = true;
    public isShowSelectTwo: boolean = true;
    public isShowSelectThree: boolean = true;
    public id: string = '';
    public level: string = '';
    public parentGoodsTypeId: string = '';
    public focusOne: string = '';
    public focusTwo: string = '';
    public focusThree: string = '';
    public focusOnes: string = '';
    public focusTwos: string = '';
    public focusShopBrand: string = '';
    public addGouwucheCD: boolean = true;
    public moreGoodsTypeLists: number = 0;
    public moreShopBrands: number = 0;
    public pageNum: number = 0;
    public merchantType: string = '';
    public orderby: string = '';
    public asc: boolean = true;
    public xiaoliang: boolean = true;
    public salesVolumeUpOrdown: boolean = true;
    public priceUpOrdown: boolean = true;
    public searchInput: string = '';
    public currentPage: number = 0;
    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };
    public $refs: {
        fenlei: HTMLFormElement;
        brand: HTMLFormElement;
        pagination: HTMLFormElement;
        nav: HTMLFormElement;
        tab: HTMLFormElement;
    };
    public async onPaginationChange(pagin: Pagination) {
        this.$nextTick(() => {
            window.scroll(0, 280);
        });
        this.searchPagin = pagin;
        this.loadData();
    }

    // @Watch('$store.state.searchInput')
    // changeSearchInput(v) {
    //     console.log('hhhh');
    //     this.searchInput = v;
    //     this.init();
    // }
    @Watch('$route')
    changeRoute(to, from) {
        this.focusOne = '';
        this.focusTwo = '';
        this.focusThree = '';
        this.init();
    }

    public goodsPropertyIds: any = '';
    toSearch() {
        this.activeName = null;
        this.goodsPropertyIds = this.itemCheckList.join(',');
        this.loadData();
    }

    public userType = null;
    mounted() {
        this.$root.$on('shouyeSearch', (searchValue) => {
            this.init();
        });
        this.init();
    }
    async init() {
        this.userType = sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : '';
        this.goodsPropertyList.length = 0;
        GoodsProperty.GoodsPropertyAll().then((res) => {
            if (res && res.length > 0) {
                this.goodsPropertyList = [];
                res.forEach((item) => {
                    if (item.childrenList.length > 0) {
                        this.goodsPropertyList.push(item);
                    }
                });
            }
        });
        this.moreGoodsTypeLists = 0;
        this.moreShopBrands = 0;
        this.isShowSelectOne = true;
        this.isShowSelectTwo = true;
        this.isShowSelectThree = true;
        this.searchPagin = {
            skip: 0,
            count: 10,
            currentPage: 1,
        };
        let result = await GoodsType.GetShopBrand({
            initials: '',
            skip: 0,
            count: -1,
        });
        this.shopBrands = result.data;
        // this.shopBrands = [
        //     {
        //         shopId: 'b1f86779-04ad-4fd5-a942-387e1c51d0df',
        //         name: '新疆农业大学企业品牌',
        //     },
        // ];
        this.goodsTypeLists = [];
        let fullGoodsTypeList = await GoodsType.GetFullGoodsTypeList();
        this.fenleiOneList = [];
        this.fenleiTwoList = [];
        this.fenleiThreeList = [];
        this.fenleiOnevalue = '';
        this.fenleiTwovalue = '';
        this.fenleiThreevalue = '';
        if (this.$route.query.id) {
            this.id = this.$route.query.id.toString();
        } else {
            this.id = '';
        }
        if (this.$route.query.level) {
            this.level = this.$route.query.level.toString();
        } else {
            this.level = '';
        }
        if (this.$route.query.parentGoodsTypeId) {
            this.parentGoodsTypeId = this.$route.query.parentGoodsTypeId.toString();
        } else {
            this.parentGoodsTypeId = '';
        }
        if (this.$route.query.id) {
            if (this.$route.query.level == '2') {
                this.focusOne = '';
                this.focusTwo = this.$route.query.id.toString();
                this.focusThree = '';
                let res1 = await GoodsType.GetGoodsTypeList({
                    goodsTypeLevel: 'One',
                    parentGoodsTypeId: '',
                    isRecommendHome: '',
                    skip: 0,
                    count: -1,
                });
                res1.data.forEach((e) => {
                    this.fenleiOneList.push({
                        value: e.id,
                        label: e.name,
                    });
                });
                let res = await GoodsType.GetGoodsTypeList({
                    goodsTypeLevel: this.$route.query.level,
                    parentGoodsTypeId: this.parentGoodsTypeId,
                    isRecommendHome: '',
                    skip: 0,
                    count: -1,
                });
                res.data.forEach((e) => {
                    this.fenleiTwoList.push({
                        value: e.id,
                        label: e.name,
                    });
                });
                fullGoodsTypeList.data.forEach((e) => {
                    if (e.id == this.$route.query.parentGoodsTypeId) {
                        e.twoLevelGoodsTypeList.forEach((ele) => {
                            if (ele.id == this.$route.query.id) {
                                this.goodsTypeLists = ele.threeLevelGoodsTypeList;
                            }
                        });
                    }
                });
                setTimeout(() => {
                    this.fenleiOnevalue = this.$route.query.parentGoodsTypeId.toString();
                    this.fenleiTwovalue = this.$route.query.id.toString();
                    this.focusOnes = this.fenleiOnevalue;
                    this.focusTwos = this.fenleiTwovalue;
                }, 0);
            } else if (this.$route.query.level == '3') {
                this.focusOne = '';
                this.focusTwo = '';
                this.focusThree = this.$route.query.id.toString();
                let twoParentGoodsTypeId = '';
                fullGoodsTypeList.data.forEach((e) => {
                    e.twoLevelGoodsTypeList.forEach((ele) => {
                        if (ele.id == this.parentGoodsTypeId) {
                            twoParentGoodsTypeId = ele.parentGoodsTypeId;
                        }
                    });
                });
                let res2 = await GoodsType.GetGoodsTypeList({
                    goodsTypeLevel: '2',
                    parentGoodsTypeId: twoParentGoodsTypeId,
                    isRecommendHome: '',
                    skip: 0,
                    count: -1,
                });
                res2.data.forEach((e) => {
                    this.fenleiTwoList.push({
                        value: e.id,
                        label: e.name,
                    });
                });

                let res1 = await GoodsType.GetGoodsTypeList({
                    goodsTypeLevel: 'One',
                    parentGoodsTypeId: '',
                    isRecommendHome: '',
                    skip: 0,
                    count: -1,
                });
                res1.data.forEach((e) => {
                    this.fenleiOneList.push({
                        value: e.id,
                        label: e.name,
                    });
                });
                let res = await GoodsType.GetGoodsTypeList({
                    goodsTypeLevel: this.$route.query.level,
                    parentGoodsTypeId: this.parentGoodsTypeId,
                    isRecommendHome: '',
                    skip: 0,
                    count: -1,
                });
                res.data.forEach((e) => {
                    this.fenleiThreeList.push({
                        value: e.id,
                        label: e.name,
                    });
                });
                setTimeout(() => {
                    this.fenleiTwovalue = this.parentGoodsTypeId.toString();
                    this.fenleiOnevalue = twoParentGoodsTypeId.toString();
                    this.fenleiThreevalue = this.$route.query.id.toString();
                    this.focusOnes = this.fenleiOnevalue;
                    this.focusTwos = this.fenleiTwovalue;
                }, 0);
                this.goodsTypeLists = [];
            } else {
                this.focusOne = this.$route.query.id.toString();
                this.focusTwo = '';
                this.focusThree = '';
                let res = await GoodsType.GetGoodsTypeList({
                    goodsTypeLevel: this.$route.query.level,
                    parentGoodsTypeId: this.parentGoodsTypeId,
                    isRecommendHome: '',
                    skip: 0,
                    count: -1,
                });
                res.data.forEach((e) => {
                    this.fenleiOneList.push({
                        value: e.id,
                        label: e.name,
                    });
                });
                setTimeout(() => {
                    this.fenleiOnevalue = this.$route.query.id.toString();
                }, 0);
                fullGoodsTypeList.data.forEach((e) => {
                    if (e.id == this.$route.query.id) {
                        this.goodsTypeLists = e.twoLevelGoodsTypeList;
                    }
                });
            }
        } else {
            let res = await GoodsType.GetGoodsTypeList({
                goodsTypeLevel: '3',
                parentGoodsTypeId: '',
                isRecommendHome: '',
                skip: 0,
                count: -1,
            });
            this.goodsTypeLists = res.data;
        }
        // if (this.$route.query.search) {
        //     this.searchInput = this.$route.query.search.toString();
        // }
        this.loadData();
    }
    loadData() {
        Goods.GetGoods({
            goodsPropertyIds: this.goodsPropertyIds,
            userId: sessionStorage.getItem('id'),
            name: sessionStorage.getItem('searchInput') ? sessionStorage.getItem('searchInput') : '',
            goodsTypeOneId: this.focusOne,
            goodsTypeTwoId: this.focusTwo,
            goodsTypeThreeId: this.focusThree,
            shopId: this.focusShopBrand,
            merchantType: this.merchantType,
            enterpriseSalePriceStart: '',
            enterpriseSalePriceEnd: '',
            personalSalePriceStart: '',
            personalSalePriceEnd: '',
            isTop: '',
            orderby: this.orderby,
            ASC: this.asc,
            skip: this.searchPagin.skip,
            count: this.searchPagin.count,
        }).then((res) => {
            this.total = res.total;
            this.pageNum = Math.ceil(this.total / 10);
            this.goodLists = res.data;
            this.goodLists.forEach((e, i) => {
                this.goodLists[i].defaultNum = 1;
            });
        });
    }
    async changeOne(v) {
        this.fenleiTwoList = [];
        this.fenleiThreeList = [];
        this.fenleiTwovalue = '';
        this.fenleiThreevalue = '';
        let fullGoodsTypeList = await GoodsType.GetFullGoodsTypeList();
        fullGoodsTypeList.data.forEach((e) => {
            if (e.id == v) {
                this.goodsTypeLists = e.twoLevelGoodsTypeList;
            }
        });
        this.$router.push({
            path: '/goodsList',
            query: {
                id: v,
                level: 'One',
                parentGoodsTypeId: '',
            },
        });
        // this.chooseOne({ id: v });
    }
    async changeTwo(v) {
        this.fenleiThreeList = [];
        this.fenleiThreevalue = '';
        let fullGoodsTypeList = await GoodsType.GetFullGoodsTypeList();
        let parentGoodsTypeId = '';
        fullGoodsTypeList.data.forEach((e) => {
            e.twoLevelGoodsTypeList.forEach((ele) => {
                if (ele.id == v) {
                    this.goodsTypeLists = ele.threeLevelGoodsTypeList;
                    parentGoodsTypeId = ele.parentGoodsTypeId;
                }
            });
        });
        console.log(v);
        console.log(parentGoodsTypeId);
        this.$router.push({
            path: '/goodsList',
            query: {
                id: v,
                level: '2',
                parentGoodsTypeId: parentGoodsTypeId,
            },
        });
        // this.chooseTwo({ id: v });
    }
    async changeThree(v) {
        this.goodsTypeLists = [];
        let fullGoodsTypeList = await GoodsType.GetFullGoodsTypeList();
        let parentGoodsTypeId = '';
        fullGoodsTypeList.data.forEach((e) => {
            e.twoLevelGoodsTypeList.forEach((ele) => {
                ele.threeLevelGoodsTypeList.forEach((element) => {
                    if (element.id == v) {
                        parentGoodsTypeId = element.parentGoodsTypeId;
                    }
                });
            });
        });
        this.$router.push({
            path: '/goodsList',
            query: {
                id: v,
                level: '3',
                parentGoodsTypeId: parentGoodsTypeId,
            },
        });
        // this.chooseThree({ id: v });
    }
    public fenlei1Id = '';
    chooseOne(item) {
        this.focusTwo = '';
        this.focusThree = '';
        if (this.fenlei1Id == item.id) {
            this.focusOne = '';
            this.fenlei1Id = '';
        } else {
            this.focusOne = item.id;
            this.fenlei1Id = item.id;
        }
        this.searchPagin = {
            skip: 0,
            count: 10,
            currentPage: 1,
        };
        this.loadData();
    }
    public fenlei2Id = '';
    chooseTwo(it) {
        // this.focusOne = '';
        this.focusThree = '';
        if (this.fenlei2Id == it.id) {
            this.focusTwo = '';
            this.fenlei2Id = '';
        } else {
            this.fenlei2Id = it.id;
            this.focusTwo = it.id;
        }
        this.searchPagin = {
            skip: 0,
            count: 10,
            currentPage: 1,
        };
        this.loadData();
    }

    public fenlei3Id = '';
    chooseThree(ite) {
        // this.focusOne = '';
        // this.focusTwo = '';
        if (this.fenlei3Id == ite.id) {
            this.focusThree = '';
            this.fenlei3Id = '';
        } else {
            (this.fenlei3Id = ite.id), (this.focusThree = ite.id);
        }
        this.searchPagin = {
            skip: 0,
            count: 10,
            currentPage: 1,
        };
        this.loadData();
    }

    public curShopId = '';
    chooseFocusShopBrand(item) {
        if (this.curShopId == item.shopId) {
            this.focusShopBrand = '';
            this.curShopId = '';
        } else {
            this.focusShopBrand = item.shopId;
            this.curShopId = item.shopId;
        }
        this.searchPagin = {
            skip: 0,
            count: 10,
            currentPage: 1,
        };
        this.loadData();
    }
    changeMerchantType() {
        if (this.checkList.length == 1) {
            this.merchantType = this.checkList[0];
        } else {
            this.merchantType = '';
        }
        this.loadData();
    }
    async closeSelect(v) {
        if (v == 1) {
            this.isShowSelectOne = false;
            this.isShowSelectTwo = false;
            this.isShowSelectThree = false;
            let res = await GoodsType.GetGoodsTypeList({
                goodsTypeLevel: '3',
                parentGoodsTypeId: '',
                isRecommendHome: '',
                skip: 0,
                count: -1,
            });
            this.goodsTypeLists = res.data;
            this.$router.push({
                path: '/goodsList',
            });
        } else if (v == 2) {
            this.isShowSelectTwo = false;
            this.isShowSelectThree = false;
            this.changeOne(this.focusOnes);
        } else if (v == 3) {
            this.isShowSelectThree = false;
            this.changeTwo(this.focusTwos);
        }
    }
    openOrClose(v) {
        this.moreGoodsTypeLists = v;
        setTimeout(() => {
            let fenlei = document.getElementsByClassName('fenleiTitle')[0] as any;
            fenlei.style.height = this.$refs.fenlei.offsetHeight + 'px';
            fenlei.style.lineHeight = this.$refs.fenlei.offsetHeight + 'px';
        });
    }
    ShopBrandsopenOrClose(v) {
        this.moreShopBrands = v;
        setTimeout(() => {
            let brand = document.getElementsByClassName('pinpaiTitle')[0] as any;
            brand.style.height = this.$refs.brand.offsetHeight + 'px';
            brand.style.lineHeight = this.$refs.brand.offsetHeight + 'px';
        });
    }
    // changeRadio(v) {
    //     console.log(v);
    //     if (v == 2) {
    //         this.orderby = '3';
    //     } else if (v == 3) {
    //         this.orderby = '1';
    //     }
    //     this.loadData();
    // }
    // clickXiaoliang(xiaoliang) {
    //     console.log(xiaoliang);
    //     this.xiaoliang = !xiaoliang;
    // }
    changeOrderby(v) {
        if (v == 0) {
            this.orderby = '';
        } else if (v == 1) {
            if (sessionStorage.getItem('userType')) {
                if (sessionStorage.getItem('userType') == '2') {
                    this.orderby = '1';
                } else {
                    this.orderby = '2';
                }
            } else {
                this.orderby = '2';
            }
            this.priceUpOrdown = !this.priceUpOrdown;
            this.asc = !this.priceUpOrdown;
        } else if (v == 2) {
            this.orderby = '3';
            this.salesVolumeUpOrdown = !this.salesVolumeUpOrdown;
            this.asc = !this.salesVolumeUpOrdown;
        }
        this.loadData();
    }
    handleChange(value) {
        console.log(value);
    }
    shoucang(item, i) {
        if (sessionStorage.getItem('id')) {
            if (!item.isUserCollection) {
                Collection.addCollection({
                    goodsId: item.id,
                }).then((res) => {
                    this.loadData();
                });
            }
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    addGouwuche(item) {
        if (sessionStorage.getItem('id')) {
            if (this.addGouwucheCD) {
                let cartList = [
                    {
                        goodsId: item.id,
                        goodsSpecificationId: item.isOpenSpecification ? item.specId : '',
                        number: item.defaultNum,
                    },
                ];
                this.addGouwucheCD = false;
                console.log(cartList);
                Cart.addBatchAddCart({
                    cartList: cartList,
                }).then((res) => {
                    this.addGouwucheCD = true;
                    //z-index需要超过顶部导航栏的比重
                    this.$message({ message: '已加入购物车', type: 'success', customClass: 'zZindex' });
                    // this.$message.success('已加入购物车');
                });
            }
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    goumai(item) {
        let orderList = [
            {
                platform: '1',
                receiveAddressId: '',
                receiver: '',
                phoneNumber: '',
                province: '',
                city: '',
                county: '',
                detail: '',
                address: '',
                remark: '',
                shopId: item.shopId,
                shopName: item.shopName,
                payMethod: '',
                orderChildList: [
                    {
                        cartId: '',
                        brand: item.brand,
                        merchantType: item.merchantType,
                        goodsId: item.id,
                        goodsNumber: item.goodsNumber,
                        name: item.name,
                        deliveryAddress: item.deliveryAddress,
                        weight: item.weight,
                        personalFixSalePrice: item.personalFixSalePrice,
                        personalSectionSalePriceOne: item.personalSectionSalePriceOne,
                        personalSectionSalePriceTwo: item.personalSectionSalePriceTwo,
                        enterpriseFixSalePrice: item.enterpriseFixSalePrice,
                        enterpriseSectionSalePriceOne: item.enterpriseSectionSalePriceOne,
                        enterpriseSectionSalePriceTwo: item.enterpriseSectionSalePriceTwo,
                        specId: item.specId,
                        specName: item.specName,
                        goodsQuantity: item.defaultNum,
                        coverImageAttachment: item.coverImageAttachmentList[0],
                        mainBusiness: item.mainBusiness,
                    },
                ],
            },
        ];
        if (sessionStorage.getItem('id')) {
            sessionStorage.setItem('goodsList', JSON.stringify(orderList));
            this.$router.push({
                path: '/submitOrder',
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    toShouye() {
        this.$router.push({
            path: '/',
        });
    }
    jumpDetail(id) {
        this.$router.push({
            path: '/goodsDetail',
            query: {
                id: id,
            },
        });
    }
    lastPage() {
        if (this.searchPagin.currentPage > 1) {
            this.searchPagin.currentPage -= 1;
            this.$refs.pagination.handleCurrentChange(this.searchPagin.currentPage);
        }
    }
    nextPage() {
        if (this.searchPagin.currentPage < this.pageNum) {
            this.searchPagin.currentPage++;
            this.$refs.pagination.handleCurrentChange(this.searchPagin.currentPage);
        }
    }
}
