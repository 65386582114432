/*
 * @Description: 
 * @Autor: jyf
 * @Date: 2021-04-27 14:40:52
 * @LastEditors: jyf
 * @LastEditTime: 2021-04-28 13:44:53
 */
import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace Activity {
  /**
   * 获取活动列表
   */
  export var GetActivityList = (query: type.GetActivityListVM): Promise<any> => {
    return Get(`/api/Activity?${qs.stringify(query)}`, {});
  };
  /**
   * 活动详情
   */
  export var GetActivity = (id: string): Promise<type.ActivityItemVM> => {
    return Get(`/api/Activity/${id}`, {});
  };

}
