

























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class PaginationPanel extends Vue {
    @Prop({
        default: 0,
    })
    public total: number;
    @Prop({
        default: false,
    })
    public reset: boolean;
    public paginationOptions = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };
    public lastPageNum = 0;
    /* 重置当前页 */
    @Watch('reset')
    public reloadChange(val: boolean) {
        if (val) {
            this.paginationOptions.currentPage = 1;
        }
    }
    @Watch('total')
    public totalChange(newVal, oldVal) {
        this.total = newVal;
        this.lastPageNum = Math.ceil(newVal / this.paginationOptions.count);
    }

    mounted() {
        document.getElementsByClassName('el-pagination__jump')[0].childNodes[0].nodeValue = '跳转至';
    }

    public firstPageBtnDisabled = true;
    public lastPageBtnDisabled = false;

    public handleCurrentChange(val) {
        this.firstPageBtnDisabled = val === 1 ? true : false;
        this.lastPageBtnDisabled = val === Math.ceil(this.total / this.paginationOptions.count) ? true : false;
        this.paginationOptions.currentPage = val;
        this.paginationOptions.skip = (this.paginationOptions.currentPage - 1) * this.paginationOptions.count;
        this.$emit('onPaginationChange', this.paginationOptions);
    }
    public handleSizeChange(val) {
        this.paginationOptions.count = val;
        this.paginationOptions.currentPage = 1;
        this.handleCurrentChange(this.paginationOptions.currentPage);
        if (Math.ceil(this.total / val) < this.paginationOptions.currentPage) {
            this.paginationOptions.skip = 0;
        } else {
            this.paginationOptions.skip = (this.paginationOptions.currentPage - 1) * this.paginationOptions.count;
            this.$emit('onPaginationChange', this.paginationOptions);
        }
    }

    toFirstPage() {
        this.handleCurrentChange(1);
    }
    toLastPage() {
        this.paginationOptions.currentPage = Math.ceil(this.total / this.paginationOptions.count);
        this.handleCurrentChange(this.paginationOptions.currentPage);
    }
}
