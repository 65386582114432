import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace Login {
    /**
     * 登录
     */
    export var login = (body: any): Promise<any> => {
        return Post(`/api/Login/Login`, body);
    };
}
