import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace SystemNotify {
    /**
     * 获取消息列表
     */
    export var GetList = (query: type.GetListReq): Promise<type.SystemNotifyListRsp> => {
        return Get(`/api/SystemNotify/GetList?${qs.stringify(query)}`, {});
    };
    /**
     * 更新消息为已读
     */
    export var UpdateReadStatus = (id: string): Promise<any> => {
        return Put(`/api/SystemNotify/UpdateReadStatus/${id}`, {});
    };
    /**
     * 更新消息为已读
     */
    export var UpdateAllRead = (): Promise<any> => {
        return Put(`/api/SystemNotify/UpdateAllRead`, {});
    };
    /**
     * 删除消息
     */
    export var Delete = (id: string): Promise<any> => {
        return Put(`/api/SystemNotify/Delete/${id}`, {});
    };
    /**
     * 获取消息数字
     */
    export var GetNumber = (): Promise<type.NotifyNumberRsp> => {
        return Get(`/api/SystemNotify/GetNumber`, {});
    };
    /**
     * 催办
     */
    export var Urge = (body: type.UrgeReq): Promise<any> => {
        return Post(`/api/SystemNotify/Urge`, body);
    };
}
