import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace Public {
    /**
     * 登录
     */
    export var Login = (body: type.LoginReq): Promise<type.LoginRsp> => {
        return Post(`/api/Public/Login`, body);
    };
    /**
     * 获取登录验证码
     */
    export var GetVerificationCode = (body: type.GetVerificationCodeReq): Promise<any> => {
        return Post(`/api/Public/GetVerificationCode`, body);
    };
    /**
     * 注销登录
     */
    export var Logout = (body: type.LogoutReq): Promise<any> => {
        return Post(`/api/Public/Logout`, body);
    };
    /**
     * 修改权限树
     */
    export var EditPermissionTree = (body: Array<type.Permission>): Promise<any> => {
        return Post(`/api/Public/EditPermissionTree`, body);
    };
    /**
     * 获取权限树
     */
    export var GetPermissionTree = (): Promise<Array<type.Permission>> => {
        return Get(`/api/Public/GetPermissionTree`, {});
    };
    /**
     * 树结构排序
     */
    export var TreeSort = (body: type.TreeSortReq): Promise<any> => {
        return Post(`/api/Public/TreeSort`, body);
    };
}
