
















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { PaginationPanel } from '@/components';
import { RefundOrder, Order } from '@/http';
interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        PaginationPanel,
    },
})
export default class AfterSalePanel extends Vue {
    @Prop({
        default: '1',
    })
    public type: string;
    @Prop({
        default: '',
    })
    public current: string;
    public checkAll: boolean = false;
    public isIndeterminate: boolean = true;
    public checkedCities: any = [];
    public total: number = 30;
    public rePagin: boolean = false;
    public orderList: any = [];
    public pageSize = 0;
    public orderStatusList: string = '';
    public isEvaluate: any = null;
    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };
    public $refs: {
        pagination: HTMLFormElement;
    };
    async onPaginationChange(pagin: Pagination) {
        this.searchPagin = pagin;
        this.init();
    }
    turnPage(type) {
        if (type === 1) {
            this.$refs.pagination.handleCurrentChange(this.searchPagin.currentPage - 1);
        } else {
            this.$refs.pagination.handleCurrentChange(this.searchPagin.currentPage + 1);
        }
    }
    mounted() {
        this.init();
    }

    lianxi(item) {
        if (sessionStorage.getItem('id')) {
            this.$root.$emit('lianxi', item.orderChildList[0].shopId);
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    lastPage() {
        if (this.searchPagin.currentPage > 1) {
            this.searchPagin.currentPage -= 1;
            this.$refs.pagination.handleCurrentChange(this.searchPagin.currentPage);
        }
    }
    nextPage() {
        if (this.searchPagin.currentPage < this.pageSize) {
            this.searchPagin.currentPage++;
            this.$refs.pagination.handleCurrentChange(this.searchPagin.currentPage);
        }
    }
    setCurrentPage(currentPage) {
        this.current = currentPage;
    }
    async init() {
        let res = await RefundOrder.getReceiverInfo({
            refundType: '',
            skip: this.searchPagin.skip,
            count: this.searchPagin.count,
        });
        this.orderList = res.data;
        this.total = res.total;
        this.$emit('haveTotal', this.total);
        this.pageSize = this.total > 0 ? Math.floor(this.total < 10 ? 1 : this.total % 10 ? this.total / 10 + 1 : this.total / 10) : 0;
    }
    jumpDetail(id) {
        this.$router.push({
            path: '/refund/detail',
            query: {
                id: id,
            },
        });
    }
    toDetail(row) {
        this.$router.push({
            path: '/goodsDetail',
            query: {
                id: row.goodsId,
            },
        });
    }
}
