/*
 * @Autor: dingxiaolin
 * @Date: 2021-01-05 14:03:08
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-02-24 09:01:40
 */
import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import login from './modules/login';
import rongCloud from './modules/rongCloud';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        blobUrl: '',
        ossBucket: '', //ali-oss
        RONGIM_AppKey:
            location.hostname.includes('test') || location.hostname.includes('192') || location.hostname.includes('localhost')
                ? '82hegw5uhqtcx'
                : 'e0x9wycfxzk6q',
        asyncRouterMap: [],
        isFirst: true,
        searchInput: '',
        isId: '',
        userName: sessionStorage.getItem('userType')=='2'? sessionStorage.getItem('enterpriseName'): sessionStorage.getItem('userName')
    },
    mutations: {},
    actions: {},
    modules: {
        app,
        login,
        rongCloud
    }
});

export default store;
