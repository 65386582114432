/*
 * @Description: 
 * @Autor: jyf
 * @Date: 2021-04-27 14:40:52
 * @LastEditors: jyf
 * @LastEditTime: 2021-04-28 13:44:53
 */
import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace PreferentialActivity {
    /**
     * 获取新品试用商品列表
     */
    export var GetPaNewGoodsList = (query: any): Promise<any> => {
        return Get(`/api/PreferentialActivity/GetPaNewGoodsList?${qs.stringify(query)}`, {});
    };
    /**
     * 获取特价甩货商品列表
     */
    export var GetPaSpecialPriceList = (query: any): Promise<any> => {
        return Get(`/api/PreferentialActivity/GetPaSpecialPriceList?${qs.stringify(query)}`, {});
    };
    /**
     * 获取限时拼团商品列表
     */
    export var GetPaShopTogetherList = (query: any): Promise<any> => {
        return Get(`/api/PreferentialActivity/GetPaShopTogetherList?${qs.stringify(query)}`, {});
    };
    /**
     * 获取活动商品分类列表
     */
    export var GetActivityGoodsTypeList = (query: any): Promise<any> => {
        return Get(`/api/PreferentialActivity/GetActivityGoodsTypeList?${qs.stringify(query)}`, {});
    };
    /**
     * 获取新品试用商品详情
     */
    export var GetPANewGoodsDetail = (query: any): Promise<any> => {
        return Get(`/api/PreferentialActivity/GetPA_NewGoodsDetail?${qs.stringify(query)}`, {});
    };
    /**
     * 获取新品试用商品详情
     */
    export var GetPAShopTogetherDetail = (query: any): Promise<any> => {
        return Get(`/api/PreferentialActivity/GetPA_ShopTogetherDetail?${qs.stringify(query)}`, {});
    };
    /**
     * 获取新品试用商品详情
     */
    export var GetPASpecialPriceDetail = (query: any): Promise<any> => {
        return Get(`/api/PreferentialActivity/GetPA_SpecialPriceDetail?${qs.stringify(query)}`, {});
    };
    /**
     * 获取新品试用记录
     */
    export var GetNewGoodsApplyRecordList = (query: any): Promise<any> => {
        return Get(`/api/PreferentialActivity/GetNewGoodsApplyRecordList?${qs.stringify(query)}`, {});
    };
    /**
     * 获取限时拼购记录
     */
    export var GetShopTogetherApplyRecordList = (query: any): Promise<any> => {
        return Get(`/api/PreferentialActivity/GetShopTogetherApplyRecordList?${qs.stringify(query)}`, {});
    };
    /**
     * 获取新品试用活动规则
     */
    export var GetNewGoodsActivityResult = (body: any): Promise<any> => {
        return Post(`/api/PreferentialActivity/GetNewGoodsActivityResult`, body);
    };
    /**
     * 获取限时拼购活动规则
     */
    export var GetShopTogetherActivityResult = (body: any): Promise<any> => {
        return Post(`/api/PreferentialActivity/GetShopTogetherActivityResult`, body);
    };
    /**
     * 获取特价甩货活动规则
     */
    export var GetSpecialPriceActivityResult = (body: any): Promise<any> => {
        return Post(`/api/PreferentialActivity/GetSpecialPriceActivityResult`, body);
    };
    /**
     * 添加新品试用申请记录
     */
    export var AddNewGoodsApplyRecord = (body: any): Promise<any> => {
        return Post(`/api/PreferentialActivity/AddNewGoodsApplyRecord`, body);
    };
    /**
     * 添加限时拼购申请记录
     */
    export var AddShopTogetherApplyRecord = (body: any): Promise<any> => {
        return Post(`/api/PreferentialActivity/AddShopTogetherApplyRecord`, body);
    };
    /**
     * 修改新品试用收货人信息
     */
    export var SaveNewGoodsApplyRecordReceiver = (body: any): Promise<any> => {
        return Put(`/api/PreferentialActivity/SaveNewGoodsApplyRecordReceiver?id=${body.id}`, body);
    };
    /**
     * 取消新品试用申请记录
     */
    export var CancelNewGoodsApplyRecord = (body: any): Promise<any> => {
        return Put(`/api/PreferentialActivity/CancelNewGoodsApplyRecord?id=${body.id}`, {});
    };
    /**
     * 修改新品试用收货人信息
     */
    export var SaveShopTogetherApplyRecordReceiver = (body: any): Promise<any> => {
        return Put(`/api/PreferentialActivity/SaveShopTogetherApplyRecordReceiver?id=${body.id}`, body);
    };
    /**
     * 取消新品试用申请记录
     */
    export var CancelShopTogetherApplyRecord = (body: any): Promise<any> => {
        return Put(`/api/PreferentialActivity/CancelShopTogetherApplyRecord?id=${body.id}`, body);
    };
}