








































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import store from '@/vuex/index';
@Component({
    components: {},
})
export default class headerPanel extends Vue {
    public input: string = '';
    public imgSrc: string = require('@/assets/img/logo.png');
    public enterpriseName: string = '';
    public userName: string = '';
    public realName: string = '';
    public enterpriseJob: string = '';
    public phoneNumber: string = '';
    public userType: string = '';
    public isLogin: boolean = false;
    @Prop()
    public data: any;
    @Watch('data')
    changeData(v) {
        this.init();
    }
    mounted() {
        this.init();
    }
    init() {
        this.imgSrc = sessionStorage.getItem('headPath')
            ? sessionStorage.getItem('headPath') != 'null'
                ? sessionStorage.getItem('headPath')
                : require('@/assets/img/logo.png')
            : require('@/assets/img/logo.png');
        this.phoneNumber = sessionStorage.getItem('phoneNumber') ? sessionStorage.getItem('phoneNumber') : '';
        this.enterpriseName = sessionStorage.getItem('enterpriseName') ? sessionStorage.getItem('enterpriseName') : '';
        this.userName = sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : '';
        this.realName = sessionStorage.getItem('realName') ? sessionStorage.getItem('realName') : '';
        this.enterpriseJob = sessionStorage.getItem('enterpriseJob') ? sessionStorage.getItem('enterpriseJob') : '';
        this.userType = sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : '';
        if (sessionStorage.getItem('id')) {
            this.isLogin = true;
        } else {
            this.isLogin = false;
        }
    }
    search() {}
    register() {
        this.$router.push({
            path: '/register',
        });
    }
    login() {
        this.$router.push({
            path: '/login',
        });
    }
}
