/*
 * @Autor: dingxiaolin
 * @Date: 2020-03-27 21:47:21
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-02-24 09:29:41
 */
import Vue from 'vue';
import '@/style/index'; // global css
import 'amfe-flexible';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import store from './vuex';
import vueSwiper from 'vue-awesome-swiper';
import VueClipboard from 'vue-clipboard2';
import preview from 'vue-photo-preview';
import dayjs from 'dayjs';
import Bus from './common/util/bus';
import VueLazyLoad from 'vue-lazyload'
import axios from "axios";
import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d);
Vue.prototype.window = window;
Vue.use(vueSwiper)
    .use(VueClipboard)
    .use(preview)
    .use(ElementUI, {
        size: 'small'
    });
Vue.use(VueLazyLoad, {
    error: require('@/assets/img/Error.png'),
    loading: require('@/assets/img/loading2.gif'),
    attempt: 1,
})
Vue.config.productionTip = false;
Vue.prototype.TITLE = process.env.TITLE;
Vue.prototype.$store = store;
Vue.prototype.$axios = axios;
Vue.prototype.$bus = Bus;
Vue.prototype.$dayjs = dayjs;
import directive from './directive';
Vue.use(directive);
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
export default new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    // mounted() {
    //     document.dispatchEvent(new Event('render-event'))
    // }

});
