























import { Vue, Component, Watch } from 'vue-property-decorator';
import { CommonPanel } from '@/components';
import { fmoney } from '@/common/util/money';
import { NavBar } from '../home/components';
import { JuTianUnion } from '@/http';
@Component({
    components: {
        CommonPanel,
        NavBar,
    },
})
export default class union extends Vue {
    public letters: any[] = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        '#',
    ];
    public szm: string = '';
    public brandLists: any[] = [];

    public JuTianUnionList: any[] = [];
    mounted() {
        this.szm = 'A';
        this.getJuTianUnion('');
    }

    public async getJuTianUnion(str) {
        let list = await JuTianUnion.GetJuTianUnion({
            initials: str,
            isTop: null,
            skip: 0,
            count: -1,
        });
        this.JuTianUnionList = list.data;
    }

    async cancel() {
        this.szm = '';
        let list = await JuTianUnion.GetJuTianUnion({
            initials: '',
            isTop: null,
            skip: 0,
            count: -1,
        });
        this.JuTianUnionList = list.data;
    }

    radioChange(e) {
        console.log('e', e);
        this.getJuTianUnion(this.letters[e]);
    }
}
