
































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import OrderPanelCart from './orderPanelCart.vue';
import { AddressSelect } from '@/components';
import { formRules } from '@/common/util/validator';
import { User } from '@/http';
import ImageUpload from './components/ImageUpload.vue';
import { JSEncrypt } from 'jsencrypt';
@Component({
    components: {
        OrderPanelCart,
        AddressSelect,
        ImageUpload,
    },
})
export default class securityCenter extends Vue {
    public $refs: {
        address: HTMLFormElement;
        form: HTMLFormElement;
        passWordForm: HTMLFormElement;
        phoneForm: HTMLFormElement;
    };
    public formRules = formRules;
    public dialogVisible = false;
    public passWordDialog = false;
    public phoneDialog = false;
    public form = {
        brand: '',
        enterpriseName: '',
        enterpriseCode: '',
        realName: '',
        enterpriseJob: '',
        mainBusiness: '',
        enterpriseBrandLogo: {
            name: '',
            path: '',
            tag: '',
            id: '',
        },
    };
    public passWordForm = {
        phoneNumber: '',
        verificationCode: '',
        password: '',
        relpassword: '',
    };
    public phoneForm = {
        oldPhoneNumber: '',
        phoneNumber: '',
        SMSCode: '',
    };
    public phoneNumber: string = '';
    public oldPhoneNumber: string = '';
    public passWordIsTrue: boolean = false;
    public detailForm = {};
    public disYzm = false;
    public disYzm1 = false;
    public isEditBase = false;
    public isEditQiye = false;
    public mainBusinessList: any[] = [];
    public userHead: any = {
        name: '',
        path: '',
        tag: '',
        id: '',
    };
    public userName: string = '';
    public locationAddress: string = '';
    public locationCountry: string = '';
    public brand: string = '';
    public enterpriseName: string = '';
    public enterpriseCode: string = '';
    public realName: string = '';
    public enterpriseJob: string = '';
    public mainBusiness: string = '';
    public userType: string = '';
    public enterpriseBrandLogo: any = {
        name: '',
        path: '',
        tag: '',
        id: '',
    };
    editBase() {
        this.isEditBase = true;
    }
    editQiye() {
        this.form.brand = this.brand;
        this.form.enterpriseName = this.enterpriseName;
        this.form.enterpriseCode = this.enterpriseCode;
        this.form.realName = this.realName;
        this.form.enterpriseJob = this.enterpriseJob;
        this.form.mainBusiness = this.mainBusiness;
        this.form.enterpriseBrandLogo.path = this.enterpriseBrandLogo.path;
        this.isEditQiye = true;
    }
    cancelQiye() {
        this.$refs.form.validate((valid) => {
            if (valid) {
                if (this.enterpriseBrandLogo.path == '') {
                    this.$message.warning('请添加企业图片');
                    return;
                }
                User.EditUserEnterpriseInfo({
                    Id: sessionStorage.getItem('id'),
                    brand: this.form.brand,
                    enterpriseName: this.form.enterpriseName,
                    enterpriseCode: this.form.enterpriseCode,
                    realName: this.form.realName,
                    enterpriseJob: this.form.enterpriseJob,
                    mainBusiness: this.form.mainBusiness,
                    enterpriseBrandLogo: this.enterpriseBrandLogo,
                }).then((res) => {
                    this.$message.success('修改成功');
                    this.isEditQiye = false;
                    this.init();
                });
            }
        });
        // this.isEditQiye = false;
    }
    saveQiye() {
        this.isEditQiye = false;
    }
    findCode() {}
    public async handleCustomerDropDown(qs, cb) {
        this.form.enterpriseCode = '';
        if (qs == '') {
            return;
        }
        let res = await User.GetEnterpriseCodeFromQcc({
            codeType: '8',
            enterpriseName: qs,
            pageIndex: 0,
            count: 10,
        });
        if (res.data) {
            cb(
                res.data.map((v) => ({
                    value: v.enterpriseName,
                    enterpriseCode: v.enterpriseCode,
                }))
            );
        } else {
            cb([]);
        }
    }
    public handleSelect(item) {
        if (item.enterpriseCode == '') {
            this.$message.warning('未查询到该企业信息');
        } else {
            this.form.enterpriseCode = item.enterpriseCode;
        }
    }
    handleClose() {
        this.dialogVisible = false;
    }
    cancelBase() {
        this.isEditBase = false;
        this.init();
    }
    saveBase() {
        let valid = this.$refs.address.validateForm();
        if (valid) {
            User.EditUserBaseInfo({
                userName: this.userName,
                locationAddress:
                    this.SelectAddress.province.text != ''
                        ? this.SelectAddress.province.text + this.SelectAddress.city.text + this.SelectAddress.area.text
                        : this.locationAddress,
                locationCountry: this.SelectAddress.area.code,
                userHead: this.userHead,
            }).then((res) => {
                this.isEditBase = false;
                this.init();
            });
        }
    }
    changePassword(value, num) {
        if (num == 0) {
            if (this.passWordForm.relpassword != '') {
                if (value != this.passWordForm.relpassword) {
                    this.passWordIsTrue = false;
                    // this.$message.warning('两次密码不一致');
                } else {
                    this.passWordIsTrue = true;
                }
            }
        } else {
            if (this.passWordForm.password != '') {
                if (value != this.passWordForm.password) {
                    this.passWordIsTrue = false;
                    // this.$message.warning('两次密码不一致');
                } else {
                    this.passWordIsTrue = true;
                }
            }
        }
    }
    savePassword() {
        this.$refs.passWordForm.validate((valid) => {
            if (this.passWordForm.password.length < 6 || this.passWordForm.password.length > 20) {
                this.$message.warning('密码为6-20位');
                return;
            }
            if (valid) {
                if (this.passWordIsTrue) {
                    const jsencrypt = new JSEncrypt({});
                    jsencrypt.setPublicKey(
                        'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDMkmzEoj7LFqLmfKqix11b2A5SMXsAE9ag/9Uq+YeiJfFq5TtP7VpGdJUx06SgQqghgWL5hXLRVTMUn8LVW2SmHV0WjvFAqhtTK9CyTYFQ9wFXy7tpxHcl6jZnw8rokzY2y4yN5WbzEJ4+1c9j0Yp+oeTk0NauyOC03lEOEY9BGwIDAQAB'
                    );
                    let password = jsencrypt.encrypt(this.passWordForm.password);
                    User.EditUserPwd({
                        id: sessionStorage.getItem('id'),
                        SMSCode: this.passWordForm.verificationCode,
                        password: password,
                    }).then((res) => {
                        this.$message.success('设置成功');
                        this.passWordDialog = false;
                        this.passWordForm.verificationCode = '';
                        this.passWordForm.password = '';
                        this.passWordForm.relpassword = '';
                        this.init();
                    });
                } else {
                    this.$message.warning('两次密码不一致');
                }
            }
        });
    }
    //监听原手机号与新手机号是否一致
    @Watch('phoneForm', { immediate: false, deep: true })
    public samePhone() {
        if(this.phoneForm.phoneNumber && this.phoneForm.oldPhoneNumber == this.phoneForm.phoneNumber){
            this.$alert('原手机号、新手机号不可重复');
            this.phoneForm.phoneNumber = '';
        }
    }
    savePhone() {
        User.EditUserPhone({
            id: sessionStorage.getItem('id'),
            oldPhoneNumber: this.phoneForm.oldPhoneNumber,
            SMSCode: this.phoneForm.SMSCode,
            phoneNumber: this.phoneForm.phoneNumber,
        }).then((res) => {
            this.$message.success('手机号修改成功');
            this.phoneForm = {
                oldPhoneNumber: '',
                phoneNumber: '',
                SMSCode: '',
            };
            this.phoneDialog = false;
            this.init();
        });
    }
    phoneClose() {
        this.phoneDialog = false;
        this.$refs.phoneForm.resetFields();
        this.phoneForm = {
            oldPhoneNumber: '',
            phoneNumber: '',
            SMSCode: '',
        };
    }
    phoneChange() {
        this.phoneDialog = true;
        this.phoneForm.oldPhoneNumber = this.oldPhoneNumber;
    }
    passWordClose() {
        this.passWordDialog = false;
        this.$refs.passWordForm.resetFields();
        this.passWordForm = {
            phoneNumber: '',
            verificationCode: '',
            password: '',
            relpassword: '',
        };
    }
    mimaChange() {
        this.passWordDialog= true;
        this.passWordForm.phoneNumber = this.oldPhoneNumber;
    }
    setImage(name, path) {
        this.userHead = {
            name: '',
            path: '',
            tag: '',
            id: '',
        };
        this.userHead.name = name;
        this.userHead.path = path;
    }
    setEnterImage(name, path) {
        this.enterpriseBrandLogo = {
            name: '',
            path: '',
            tag: '',
            id: '',
        };
        this.enterpriseBrandLogo.name = name;
        this.enterpriseBrandLogo.path = path;
    }
    // 获取验证码
    getCode(v) {
        if (v == 0) {
            if (!this.disYzm) {
                User.GetSMSCode({
                    phoneNum: this.passWordForm.phoneNumber,
                    codeType: '9',
                }).then((res) => {
                    this.$message.success('验证码已发送');
                    this.countCode(0);
                });
            }
        } else {
            if (!this.disYzm1) {
                User.GetSMSCode({
                    phoneNum: this.phoneForm.phoneNumber,
                    codeType: '10',
                }).then((res) => {
                    this.$message.success('验证码已发送');
                    this.countCode(1);
                });
            }
        }
    }
    public countDowning = false;
    public countDowning1 = false;
    // 倒计时
    public interval: any;
    public interval1: any;
    public btnMessage: string = '发送验证码';
    public btnMessage1: string = '发送验证码';
    // 倒计时
    countCode(v) {
        let i = 60;
        if (v == 0) {
            this.disYzm = true;
            this.countDowning = true;
            this.interval = setInterval(() => {
                this.btnMessage = `${--i}秒后重试`;
                if (i === 0) {
                    clearInterval(this.interval);
                    this.btnMessage = '获取验证码';
                    this.disYzm = false;
                    this.countDowning = false;
                }
            }, 1000);
        } else {
            this.disYzm1 = true;
            this.countDowning1 = true;
            this.interval1 = setInterval(() => {
                this.btnMessage1 = `${--i}秒后重试`;
                if (i === 0) {
                    clearInterval(this.interval1);
                    this.btnMessage1 = '获取验证码';
                    this.disYzm1 = false;
                    this.countDowning1 = false;
                }
            }, 1000);
        }
    }
    public mounted() {
        this.init();
    }
    init() {
        if (sessionStorage.getItem('id')) {
            User.GetRefreshUserInfo(sessionStorage.getItem('id')).then((res) => {
                this.successInfo(res);
                this.userHead.path = res.headPath
                    ? res.headPath
                    : 'https://sinostoragedev.oss-cn-hangzhou.aliyuncs.com/fms/87852C02-44F5-43AC-A4CB-19678DF0E82C.png';
                this.userName = res.userName;
                this.locationAddress = res.locationAddress;
                this.locationCountry = res.locationCountry;
                this.SelectAddress.province.code = res.locationCountry ? res.locationCountry.substr(0, 2) : '';
                this.SelectAddress.city.code = res.locationCountry ? res.locationCountry.substr(0, 4) : '';
                this.SelectAddress.area.code = res.locationCountry ? res.locationCountry : '';
                this.SelectAddress.province.text = res.provinceName;
                this.SelectAddress.city.text = res.cityName;
                this.SelectAddress.area.text = res.countyName;
                this.brand = res.brandName;
                this.enterpriseName = res.enterpriseName;
                this.enterpriseCode = res.enterpriseCode;
                this.realName = res.realName;
                this.enterpriseJob = res.enterpriseJob;
                this.mainBusiness = res.mainBusiness;
                this.enterpriseBrandLogo.path = res.enterpriseBrandLogoUrl;
                this.passWordForm.phoneNumber = res.phoneNumber;
                this.oldPhoneNumber = res.phoneNumber;
                this.phoneNumber = res.phoneNumber.substr(0, 3) + '****' + res.phoneNumber.substr(7, 11);
            });
        }
    }
    successInfo(res) {
        sessionStorage.setItem('id', res.id);
        sessionStorage.setItem('headPath', res.headPath);
        sessionStorage.setItem('userName', res.userName);
        sessionStorage.setItem('phoneNumber', res.phoneNumber);
        sessionStorage.setItem('isOpenTencentCloud', res.isOpenTencentCloud);
        sessionStorage.setItem('mainBusiness', res.mainBusiness);
        sessionStorage.setItem('userType', res.userType);
        sessionStorage.setItem('enterpriseCode', res.enterpriseCode);
        sessionStorage.setItem('enterpriseJob', res.enterpriseJob);
        sessionStorage.setItem('enterpriseName', res.enterpriseName);
        sessionStorage.setItem('isEnabled', res.isEnabled);
        sessionStorage.setItem('isFirstLogin', res.isFirstLogin);
        sessionStorage.setItem('userSig', res.userSig);
        this.userType = res.userType;
        this.setDataDictionary();
    }
    async setDataDictionary() {
        let res = await User.GetDataDictionary({
            parentId: '95e698f8-8d1e-4c82-7eea-0cc35528e281',
            skip: 0,
            count: -1,
        });
        this.mainBusinessList = res.data;
    }
    //---地址选择start---
    public selectReset: boolean = true;
    public async updateReset() {
        this.selectReset = true;
    }
    public async getProvince(item, name) {
        this.SelectAddress.province.code = item;
        this.SelectAddress.province.text = name;
        this.SelectAddress.city.code = '';
        this.SelectAddress.city.text = '';
        this.SelectAddress.area.code = '';
        this.SelectAddress.area.text = '';
    }
    public async getCityCode(item, name) {
        this.SelectAddress.city.code = item;
        this.SelectAddress.city.text = name;
        this.SelectAddress.area.code = '';
        this.SelectAddress.area.text = '';
    }
    public async getAreaCode(item, name) {
        this.SelectAddress.area.code = item;
        this.SelectAddress.area.text = name;
    }
    public SelectAddress = {
        province: {
            code: '',
            text: '',
        },
        city: {
            code: '',
            text: '',
        },
        area: {
            code: '',
            text: '',
        },
    };
    //---地址选择--end---
}
