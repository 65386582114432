


















import { Vue, Component } from 'vue-property-decorator';
import { PostBar } from '@/http';
export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}

@Component({
    components: {},
})
export default class comment extends Vue {
    public evaluateList: any[] = [];

    async init() {
        let res = await PostBar.GetMyPostEvaluateList({
            skip: 0,
            count: -1,
        });
        this.evaluateList = res.data;
    }
    mounted() {
        if (sessionStorage.getItem('id')) {
            this.init();
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    toDetail(id) {
        this.$router.push({
            path: '/interactionDetail',
            query: {
                id: id,
                name: 'pinglun',
            },
        });
    }
}
