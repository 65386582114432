





































































import { Vue, Component } from 'vue-property-decorator';
import { formRules } from '@/common/util/validator';
import { PaginationPanel } from '@/components';
import { Evaluate } from '@/http';
export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}

@Component({
    components: {
        PaginationPanel,
    },
})
export default class evaluateDetail extends Vue {
    public formRules = formRules;

    public evaluateList = [];

    created() {
        this.loadData();
    }

    loadData() {
        Evaluate.Evaluate({
            goodId: '',
            shopId: '',
            isAllGoodsEvaluate: true,
            skip: this.searchPagin.skip,
            count: this.searchPagin.count,
        }).then((res) => {
            this.evaluateList = res.data;
            this.total = res.total;
        });
    }

    value = 4;
    colors = ['#E02020', '#E02020', '#E02020'];

    public isIndeterminate: boolean = false;
    public checkAll: boolean = false;
    public checkedCities: any[] = [];
    public cities: any[] = ['1', '2', '3', '4'];
    public cityOptions: string[] = ['1', '2', '3', '4'];
    public isShoucang: number = null;
    public rePagin: boolean = false;
    public total: number = 0;
    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };
    public async onPaginationChange(pagin: Pagination) {
        this.searchPagin = pagin;
        this.loadData();
    }
    handleCheckAllChange(val) {
        this.checkedCities = val ? this.cityOptions : [];
        this.isIndeterminate = false;
    }
    handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.cities.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    }
    lianxi(item) {
        console.log(JSON.stringify(item));
        if (sessionStorage.getItem('id')) {
            this.$root.$emit('lianxi', item.shopId);
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }

    toDetail(row) {
        this.$router.push({
            path: '/goodsDetail',
            query: {
                id: row.goodsId,
            },
        });
    }
}
