













































import { Vue, Component } from 'vue-property-decorator';
import BlankContailerPanel from '../../components/BlankContailerPanel.vue';
import AfterSalePanel from './afterSalePanel.vue';
@Component({
    components: {
        BlankContailerPanel,
        AfterSalePanel,
    },
})
export default class AfterSale extends Vue {
    public activeName: string = '6';
    public tabList: any = [{ label: '退款/售后', name: '6' }];
    /**当前显示tabs */
    public currentPage: string | number = '1';
    enterpriseName = '';
    userName = '';
    userType = '';
    headPath = '';
    phoneNumber = '';
    enterpriseJob = '';
    realName = '';
    public mounted() {
        this.enterpriseName = sessionStorage.getItem('enterpriseName') ? sessionStorage.getItem('enterpriseName') : '';
        this.userName = sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : '';
        this.userType = sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : '';
        this.headPath = sessionStorage.getItem('headPath') ? sessionStorage.getItem('headPath') : '';
        this.phoneNumber = sessionStorage.getItem('phoneNumber') ? sessionStorage.getItem('phoneNumber') : '';
        this.enterpriseJob = sessionStorage.getItem('enterpriseJob') ? sessionStorage.getItem('enterpriseJob') : '';
        this.realName = sessionStorage.getItem('realName') ? sessionStorage.getItem('realName') : '';
    }

    isDisable = false;
    haveTotal(total) {
        if (total > 10) {
            this.isDisable = true;
        } else {
            console.log('total!' + total);
            this.isDisable = false;
        }
    }

    public async handleClick(tab, event) {
        this.currentPage = Number(tab.index) + 1;
        this.$refs['order_' + tab.index][0].rePagin = true;
        await this.$refs['order_' + tab.index][0].init();
        this.$refs['order_' + tab.index][0].rePagin = false;
    }
    public async onpaginChange(type) {
        await this.$refs['order_' + (Number(this.currentPage) - 1)][0].turnPage(type);
    }
    lastPage() {
        this.$nextTick(() => {
            this.$refs['order_' + (Number(this.currentPage) - 1)][0].lastPage();
        });
    }
    nextPage() {
        this.$nextTick(() => {
            this.$refs['order_' + (Number(this.currentPage) - 1)];
            this.$refs['order_' + (Number(this.currentPage) - 1)][0].nextPage();
        });
    }
    jump() {
        this.$router.push({
            path: '/register',
        });
    }
}
