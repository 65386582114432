











































import { Vue, Component, Prop } from 'vue-property-decorator';
import { PostBar, ShopBrand, GeneralAdvert } from '@/http';
@Component({})
export default class RightTieba extends Vue {
    canShow = false;
    toPinPai() {
        this.$router.push({
            path: `/brand`,
        });
    }
    toShopDetail(id) {
        this.$router.push({
            path: '/shop',
            query: {
                shopId: id,
            },
        });
    }
    toReTieDetail(id) {
        console.log('idx' + id);
        this.$router.push({
            path: '/interactionDetail',
            query: {
                id: id,
                name: 'hudong',
            },
        });
    }
    jumpOut(item) {
        if (item.webHref != '') {
            if (item.webHref.indexOf('http') > -1) {
                window.open(item.webHref);
            } else {
                window.open('https://' + item.webHref);
            }
        } else {
        }
    }

    mounted() {
        window.addEventListener('scroll', this.handleScrollx, true);
        this.GetHotPostBar();
        this.getShopBrandList();
        this.getGeneralAdvert();
    }
    handleScrollx() {
        if (window.pageYOffset > 400) {
            this.canShow = true;
        } else {
            this.canShow = false;
        }
    }
    public imgsType10 = [];
    public hotPostBar = [];
    public GetHotPostBar() {
        this.hotPostBar = [];
        PostBar.GetHotPostListByViews().then((res) => {
            this.hotPostBar = res.data;
        });
    }
    shopBrandList = [];
    imgsType9 = [];
    public getGeneralAdvert() {
        GeneralAdvert.GetGeneralAdvertApi({
            generalAdvertTypes: '10',
        }).then((res) => {
            this.imgsType10 = [];
            if (res.data) {
                res.data.forEach((item) => {
                    if (item.generalAdvertType == 10 && item.webAttachment && item.webAttachment.path) {
                        this.imgsType10.push(item);
                    }
                });
            }
        });
    }
    /**获取品牌 */
    public async getShopBrandList() {
        let list = await ShopBrand.GetShopBrandList2({ initials: '', isTop: true, skip: 0, count: 10 });
        this.shopBrandList = list.data;
        GeneralAdvert.GetGeneralAdvertApi({
            generalAdvertTypes: '9',
        }).then((res) => {
            this.imgsType9 = [];
            if (res.data) {
                let temp = this.shopBrandList.concat(res.data);
                this.imgsType9 = temp.slice(0, 10);
            }
        });
    }
}
