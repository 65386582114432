

























































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { formRules } from '@/common/util/validator';
import { User, Login } from '@/http';
import headerPanel from './component/headerPanel.vue';
import footerBar from './component/footerBar.vue';
import store from '@/vuex';
import { JSEncrypt } from 'jsencrypt';
@Component({
    components: {
        headerPanel,
        footerBar,
    },
})
export default class login extends Vue {
    public disYzm: boolean = true;
    public toastText: string = '';
    public onLine = true;
    public transitionName: string = '';
    public passLogin: boolean = true;
    public yzmLogin: boolean = true;
    public userLogin: boolean = true;
    public canShow: boolean = false;
    public dialogVisible: boolean = false;
    industryList = [];
    public $refs: {
        loginForm: HTMLFormElement;
        loginForm1: HTMLFormElement;
    };

    public loginForm = {
        phoneNumber: '',
        password: '',
    };
    public loginForm1 = {
        phoneNumber: '',
        SMSCode: '',
    };
    public btnMessage: string = '获取验证码';
    public countDowning: boolean = false;
    // 倒计时
    interval: any;
    public formRules = formRules;
    created() {
        this.canShow = false;
    }
    mounted() {
        if (this.loginForm.phoneNumber == '' || this.loginForm.password == '') {
            this.userLogin = true;
        } else {
            this.userLogin = false;
        }
        if (this.loginForm1.phoneNumber == '' || this.loginForm1.SMSCode == '') {
            this.yzmLogin = true;
        } else {
            this.yzmLogin = false;
        }
        if (this.loginForm1.phoneNumber != '') {
            this.disYzm = false;
        } else {
            this.disYzm = true;
        }
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
    }
    public changeLogin(i) {
        if (i == 0) {
            this.passLogin = true;
        } else {
            this.passLogin = false;
        }
        this.canShow = false;
    }
    updateOnlineStatus(e) {
        const { type } = e;
        this.onLine = type === 'online';
    }
    beforeDestroy() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    }
    resetForm(formName) {
        if (this.$refs[formName] !== undefined) {
            this.$refs[formName].resetFields();
        }
        switch (formName) {
            case 'loginForm':
                break;
            case 'loginForm1':
                break;
            default:
                break;
        }
    }
    // 获取验证码
    getCode() {
        if (!this.disYzm) {
            User.GetSMSCode({
                phoneNum: this.loginForm1.phoneNumber,
                codeType: '3',
            }).then((res) => {
                this.$message.success('验证码已发送');
                this.countCode();
                // if (!res.extData) {
                //     this.dialogVisible = true;
                // } else {
                //     this.$message.success('验证码已发送');
                //     this.countCode();
                // }
            });
        }
    }
    // 倒计时
    countCode() {
        this.disYzm = true;
        this.countDowning = true;
        let i = 60;
        this.interval = setInterval(() => {
            this.btnMessage = `${--i}秒后重试`;
            if (i === 0) {
                clearInterval(this.interval);
                this.btnMessage = '获取验证码';
                this.disYzm = false;
                this.countDowning = false;
            }
        }, 1000);
    }
    // 登录
    handleLogin() {
        if (!this.onLine) {
            this.canShow = true;
            this.toastText = '网络连接失败，请稍后再试！';
            return;
        }
        if (this.passLogin) {
            const jsencrypt = new JSEncrypt({});
            jsencrypt.setPublicKey(
                'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDMkmzEoj7LFqLmfKqix11b2A5SMXsAE9ag/9Uq+YeiJfFq5TtP7VpGdJUx06SgQqghgWL5hXLRVTMUn8LVW2SmHV0WjvFAqhtTK9CyTYFQ9wFXy7tpxHcl6jZnw8rokzY2y4yN5WbzEJ4+1c9j0Yp+oeTk0NauyOC03lEOEY9BGwIDAQAB'
            );
            let password = jsencrypt.encrypt(this.loginForm.password);
            let obj = {
                codeType: '8',
                webLoginMode: 2,
                phoneNumber: this.loginForm.phoneNumber,
                password: password,
            };
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    Login.login(obj)
                        .then((res) => {
                            this.successLogin(res);
                        })
                        .catch((e) => {
                            // this.dialogVisible = true;
                            this.toastText = e;
                            this.canShow = true;
                        });
                } else {
                    this.canShow = true;
                    this.toastText = '请输入正确的用户名或密码';
                }
            });
        } else {
            let obj = {
                codeType: '8',
                webLoginMode: 1,
                phoneNumber: this.loginForm1.phoneNumber,
                SMSCode: this.loginForm1.SMSCode,
            };
            this.$refs.loginForm1.validate((valid) => {
                if (valid) {
                    Login.login(obj)
                        .then((res) => {
                            this.successLogin(res);
                        })
                        .catch((e) => {
                            // this.dialogVisible = true;
                            this.toastText = e;
                            this.canShow = true;
                        });
                } else {
                    this.canShow = true;
                    this.toastText = '请输入正确的手机号或者验证码';
                }
            });
        }
    }
    successLogin(res) {
        sessionStorage.setItem('id', res.id);
        sessionStorage.setItem('jwtToken', res.jwtToken);
        sessionStorage.setItem('headPath', res.headPath);
        sessionStorage.setItem('userName', res.userName);
        sessionStorage.setItem('phoneNumber', res.phoneNumber);
        sessionStorage.setItem('isOpenTencentCloud', res.isOpenTencentCloud);
        sessionStorage.setItem('mainBusiness', res.mainBusiness);
        sessionStorage.setItem('userType', res.userType);
        sessionStorage.setItem('enterpriseCode', res.enterpriseCode);
        sessionStorage.setItem('enterpriseJob', res.enterpriseJob);
        sessionStorage.setItem('enterpriseName', res.enterpriseName);
        sessionStorage.setItem('isEnabled', res.isEnabled);
        sessionStorage.setItem('isFirstLogin', res.isFirstLogin);
        sessionStorage.setItem('userSig', res.userSig);
        sessionStorage.setItem('realName', res.realName);
        sessionStorage.setItem('isOpenTencentCloud', res.isOpenTencentCloud);
        sessionStorage.setItem('userSig', res.userSig);
        this.$store.state.isId = res.id;
        // this.$root.$emit('isUserLogin');
        // window.location.href = 'http://jutiangas.com/#/';
        this.$router.push({
            path: '/',
        });
    }
    // 忘记密码
    forget() {
        this.$router.push({
            path: '/forgetPassword',
        });
    }
    // 为了登录按钮是否可按的效果
    @Watch('loginForm.phoneNumber')
    public loginFormUserChange(phoneNumber) {
        if (phoneNumber == '' || this.loginForm.password == '') {
            this.userLogin = true;
        } else {
            this.userLogin = false;
        }
    }
    @Watch('loginForm.password')
    public loginFormPwdChange(password) {
        if (this.loginForm.phoneNumber == '' || password == '') {
            this.userLogin = true;
        } else {
            this.userLogin = false;
        }
    }
    @Watch('loginForm1.phoneNumber')
    public loginForm1PwdChange(phoneNumber) {
        if (phoneNumber == '' || this.loginForm1.SMSCode == '') {
            this.yzmLogin = true;
        } else {
            this.yzmLogin = false;
        }
        if (!this.countDowning && phoneNumber.length > 10) {
            this.disYzm = false;
        } else {
            this.disYzm = true;
        }
    }
    @Watch('loginForm1.SMSCode')
    public loginForm1CodeChange(SMSCode) {
        if (this.loginForm1.phoneNumber == '' || SMSCode == '') {
            this.yzmLogin = true;
        } else {
            this.yzmLogin = false;
        }
    }
    // 立即注册
    register() {
        this.$router.push({
            path: '/register',
        });
    }
}
