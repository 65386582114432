

































import { Vue, Component, Watch } from 'vue-property-decorator';
import { CommonPanel } from '@/components';
import { fmoney } from '@/common/util/money';
import { NavBar } from '../home/components';
import { ShopBrand } from '@/http';
@Component({
    components: {
        CommonPanel,
        NavBar,
    },
})
export default class brand extends Vue {
    public letters: any[] = [];
    public szm: string = '';
    public brandLists: any[] = [];
    /**品牌 */
    public shopBrandList: any[] = [];
    mounted() {
        this.szm = 'A';
        this.letters = [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
            '#',
        ];
        this.brandLists = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
        this.getShopBrandList('');
    }

    /**获取品牌 */
    public async getShopBrandList(str) {
        let list = await ShopBrand.GetShopBrandList({ initials: str, skip: 0, count: -1 });
        this.shopBrandList = list.data;
        console.log('this.shopBrandList', this.shopBrandList);
    }

    async cancel() {
        this.szm = '';
        let list = await ShopBrand.GetShopBrandList({ initials: '', skip: 0, count: -1 });
        this.shopBrandList = list.data;
    }

    radioChange(e) {
        console.log('e', e);
        this.getShopBrandList(this.letters[e]);
    }

    jump(item) {
        this.$router.push({
            path: './shop',
            query: {
                shopId: item.shopId,
            },
        });
    }
}
