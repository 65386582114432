















































import { Vue, Component, Watch } from 'vue-property-decorator';
import headerPanel from './component/headerPanel.vue';
import footerBar from './component/footerBar.vue';
import { Order } from '@/http';
import QRCode from 'qrcodejs2';

@Component({
    components: {
        headerPanel,
        footerBar,
    },
})
export default class settleCenter extends Vue {
    public time: string = '';
    public h: number = 0;
    public m: any = 0;
    public s: any = 0;
    public status: any = 1;
    public orderTime: string = '';
    public totalAmount: number = 0;
    public timer: any;
    public setTime: any;
    public type: any = '';
    mounted() {
        this.countTime();
        this.init();
    }
    async init() {
        this.type = this.$route.query.type;
        if (this.$route.query.type == '3') {
            let res = await Order.UnifiedOrder({
                id: this.$route.query.id,
                payMethod: 3,
                tradeType: '1',
                openId: '',
            });
            let result = await Order.OrderDetail(this.$route.query.id);
            this.orderTime = result.orderTime;
            this.totalAmount = result.totalAmount;
            new QRCode(document.getElementById('qrcode'), res.payUrl);
            this.setTime = setTimeout(() => {
                this.getOrderStatus();
            }, 1000 * 10);
        } else if (this.$route.query.type == '4') {
            Order.UnifiedOrder({
                id: this.$route.query.id,
                payMethod: 4,
                tradeType: '1',
                openId: '',
            }).then((res) => {
                window.open(res.payUrl);
                this.setTime = setTimeout(() => {
                    this.getOrderStatus();
                }, 1000 * 10);
            });
        }
    }
    countTime() {
        //获取当前时间
        var date = new Date();
        var now = date.getTime();
        //设置截止时间
        var endDate = new Date(Date.parse(this.orderTime) + 1000 * 60 * 60 * 48);
        var end = endDate.getTime();
        //时间差
        var leftTime = end - now;
        //定义变量 d,h,m,s保存倒计时的时间
        if (leftTime >= 0) {
            this.h = Math.floor(leftTime / 1000 / 60 / 60);
            this.m = Math.floor((leftTime / 1000 / 60) % 60);
            this.s = Math.floor((leftTime / 1000) % 60);
        }
        this.time = this.h + '小时' + this.m + '分' + this.s + '秒';
        //递归每秒调用countTime方法，显示动态时间效果
        setTimeout(this.countTime, 1000);
    }
    getOrderStatus() {
        this.timer = setInterval(() => {
            Order.RefreshOrderStatus(this.$route.query.id).then((res) => {
                this.status = res.extData;
                if (res.extData > 1) {
                    clearInterval(this.timer);
                    this.$router.push({
                        path: '/orderDetail/detail',
                        query: {
                            id: this.$route.query.id,
                        },
                    });
                }
            });
        }, 1000 * 2);
    }
    jumpDetail(id) {
        this.$router.push({
            path: '/orderDetail/detail',
            query: {
                id: id,
            },
        });
    }
    destroyed() {
        clearInterval(this.timer);
        clearTimeout(this.setTime);
    }
}
