


































import { Vue, Component, Watch } from 'vue-property-decorator';
import { NavBar } from '@/views/home/components';
import { Shop, Focus, AccessRecord } from '@/http';
declare var returnCitySN: any;
@Component({
    components: {
        NavBar,
    },
})
export default class shopNews extends Vue {
    shopNewData: any = {};
    shopData: any = {};

    isUserFocus = false;

    mounted() {
        this.GetShopNewsList();
        this.GetFullShopDetail();
        if (sessionStorage.getItem('id')) {
            this.getIfFollow();
        } else {
            this.isUserFocus = false;
        }
    }

    public async getIfFollow() {
        let isUserFocus = await Focus.isUserFocus({ id: this.$route.query.shopId });
        if (isUserFocus.extData) {
            this.isUserFocus = true;
        } else {
            this.isUserFocus = false;
        }
    }
    //获取企业动态
    public async GetShopNewsList() {
        let list = await Shop.GetShopNewsList({ id: this.$route.query.shopId });
        this.shopNewData = list.data[Number(this.$route.query.name)];
        console.log('this.shopNewData============' + JSON.stringify(this.shopNewData));
        AccessRecord.AddAccessRecord({
            webHost: returnCitySN['cip'],
            weChatOpenId: null,
            dataId: this.shopNewData.id,
            accessPage: 'ShopNews',
            creatorUserName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : '',
        });
    }

    //获取企业详情
    public async GetFullShopDetail() {
        let list = await Shop.GetFullShopDetail(this.$route.query.shopId);
        this.shopData = list;
    }

    setFocus() {
        Focus.addFocus({
            shopId: this.$route.query.shopId,
        }).then(() => {
            this.getIfFollow();
        });
    }

    async deletfollow() {
        await Focus.DeleteByShopId({
            shopId: this.$route.query.shopId,
        });
        this.getIfFollow();
    }
}
