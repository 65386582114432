import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace GoodsProperty {
    /**
     * 获取商品列表
     */
    export var GoodsProperty = (query: any): Promise<any> => {
        return Get(`/api/GoodsProperty?${qs.stringify(query)}`, '');
    };
    /**
     * 获取商品详情
     */
    export var GoodsPropertyAll = (): Promise<any> => {
        return Get(`/api/GoodsProperty/all`, {});
    };
}
