










































































import { Vue, Component } from 'vue-property-decorator';
import { NavBar } from '../home/components';
import OrderInformation from './orderInformation.vue';
@Component({
    components: {
        NavBar,
        OrderInformation,
    },
})
export default class Main extends Vue {
    public created() {}
    public mounted() {}
}
