
































































import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({})
export default class RightAnchor extends Vue {
    public isMaoShow = false;
    public isLogin: boolean = false;
    mounted() {
        this.isLogin = sessionStorage.getItem('id') ? true : false;
        window.addEventListener('scroll', this.handleScrollx, true);
    }
    login() {
        this.$router.push({
            path: '/login',
        });
    }
    register() {
        this.$router.push({
            path: '/register',
        });
    }
    toMe() {
        this.$router.push({
            path: '/personal/order',
        });
    }
    jumpCart() {
        if (sessionStorage.getItem('id')) {
            this.$router.push({
                path: '/personal/shoppingCart',
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    handleScrollx() {
        if (window.pageYOffset > 430) {
            this.isMaoShow = true;
        } else {
            this.isMaoShow = false;
        }
    }
    toTop() {
        var element = document.getElementById('app');
        element.scrollIntoView({ behavior: 'smooth' }); // 参数 false 代表 Bottom
    }
}
