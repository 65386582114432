






































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { upload } from '@/common/util/ossUploadService';

@Component({
    components: {},
})
export default class ImageUpload extends Vue {
    @Prop()
    public data;
    @Prop()
    public title: string;
    @Prop()
    public name: string;

    mounted() {
        if (this.data != '') {
            this.isShow = true;
        } else {
            this.isShow = false;
        }
        this.src = this.data;
    }
    @Watch('data')
    changeData(v) {
        console.log(v);
        if (v != '') {
            this.isShow = true;
        } else {
            this.isShow = false;
        }
        this.src = v;
    }

    public $refs: {
        imageUploadInput: HTMLFormElement;
    };

    public isShow = false;
    public file;
    public src;
    public imgName = '';
    public async changeFile(e) {
        console.log(e.target.files[0]);
        if (e.target.files[0]) {
            this.src = null;
            this.isShow = false;
            this.imgName = e.target.files[0].name;
            let imgType = this.imgName.split('.').pop();
            let allowType = ['jpg', 'jpeg', 'png', 'gif', 'tiff'];
            this.file = e.target.files[0];
            this.src = URL.createObjectURL(e.target.files[0]);
            if (allowType.indexOf(imgType.toLowerCase()) === -1) {
                this.$alert('不支持该文件类型').then((e) => {
                    this.src = null;
                    this.isShow = false;
                    this.$refs.imageUploadInput.value = null;
                });
                return;
            }
            if (e.target.files[0].size > 5 * 1024 * 1024) {
                this.$alert('文件大小不能超过5M').then((e) => {
                    this.src = null;
                    this.isShow = false;
                    this.$refs.imageUploadInput.value = null;
                });
                return;
            }
            this.isShow = true;
            this.upload();
        }
        e.target.value = '';
    }

    public async upload() {
        const res = await upload(this.file, this.imgName);
        if (res.res.statusCode == '200') {
            let url = res.res.requestUrls[0].split('?')[0];
            this.$emit('setImage', this.imgName, url);
        } else {
            this.$message.warning('图片上传失败!');
        }
    }
}
