








































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PaginationPanel, RightTieba } from '@/components';
import { NavBar } from '@/components';
import { PostBar, AccessRecord } from '@/http';
declare var returnCitySN: any;
export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        NavBar,
        PaginationPanel,
        RightTieba,
    },
})
export default class interactionDetail extends Vue {
    public detailInfo: any = {};
    public commentList: any[] = [];
    public hotPostList: any[] = [];
    public descIsTure: boolean = true;
    public rePagin: boolean = false;
    public total: number = 0;
    public name: string | string[] = '';
    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };
    @Watch('desc')
    changeDesc(v) {
        if (v == '') {
            this.descIsTure = true;
        } else {
            this.descIsTure = false;
        }
    }
    release() {
        this.$router.push({
            path: '/release',
        });
    }
    submit() {
        if (sessionStorage.getItem('id')) {
            PostBar.AddPostEvaluate({
                postId: this.$route.query.id,
                postTitle: this.detailInfo.titile,
                postCreatorUserName: this.detailInfo.creatorUserName,
                content: this.desc,
            }).then(() => {
                this.desc = '';
                this.init();
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    public desc: string = '';
    chooseIsLike(item) {
        if (sessionStorage.getItem('id')) {
            if (item.isLike) {
                PostBar.CancleLike({
                    postId: item.id,
                }).then((res) => {
                    this.init();
                });
            } else {
                PostBar.LikePost({
                    postId: item.id,
                }).then((res) => {
                    this.init();
                });
            }
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    handleOpen(key, keyPath) {
        console.log(key, keyPath);
    }
    handleClose(key, keyPath) {
        console.log(key, keyPath);
    }
    mounted() {
        this.init();
    }
    async init() {
        this.name = this.$route.query.name;
        let res = await PostBar.GetPostDetail({
            id: this.$route.query.id,
            userId: sessionStorage.getItem('id'),
        });
        this.detailInfo = res;
        let result = await PostBar.GetPostEvaluateList({
            postId: this.$route.query.id,
            userId: sessionStorage.getItem('id'),
            skip: this.searchPagin.skip,
            count: this.searchPagin.count,
        });
        this.commentList = result.data;
        this.total = result.total;
        let resu = await PostBar.GetHotPostList();
        this.hotPostList = resu.data;
        AccessRecord.AddAccessRecord({
            webHost: returnCitySN['cip'],
            weChatOpenId: null,
            dataId: res.id,
            accessPage: 'PostBar',
            creatorUserName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : '',
        });
    }
    public async onPaginationChange(pagin: Pagination) {
        this.searchPagin = pagin;
        this.init();
    }
    jumpPostBar(id) {
        this.$router.push({
            path: '/interactionDetail',
            query: {
                id: id,
            },
        });
    }
    @Watch('$route')
    changeRoute(to, from) {
        this.init();
    }
}
