/*
 * @Description:
 * @Autor: jyf
 * @Date: 2021-04-27 15:42:27
 * @LastEditors: jyf
 * @LastEditTime: 2021-04-28 14:41:17
 */
import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import qs from 'qs';
export namespace PostBar {
    /**
     * 获取帖子类别列表
     */
    export var GetPostTypeList = (query: any): Promise<any> => {
        return Get(`/api/PostBar/GetPostTypeList?${qs.stringify(query)}`, '');
    };
    /**
     * 获取帖子列表
     */
    export var GetPostList = (query: any): Promise<any> => {
        return Get(`/api/PostBar/GetPostList?${qs.stringify(query)}`, '');
    };
    /**
     * 获取我的帖子列表
     */
    export var GetMyPostList = (query: any): Promise<any> => {
        return Get(`/api/PostBar/GetMyPostList?${qs.stringify(query)}`, '');
    };
    /**
     * 获取热门帖子列表
     */
    export var GetHotPostList = (): Promise<any> => {
        return Get(`/api/PostBar/GetHotPostList`, '');
    };
    /**
   * 获取热门帖子列表
   */
    export var GetHotPostListByViews = (): Promise<any> => {
        return Get(`/api/PostBar/GetHotPostListByViews`, '');
    };


    /**
     * 获取帖子评价列表
     */
    export var GetPostEvaluateList = (query: any): Promise<any> => {
        return Get(`/api/PostBar/GetPostEvaluateList?${qs.stringify(query)}`, '');
    };
    /**
     * 获取我的帖子评价列表
     */
    export var GetMyPostEvaluateList = (query: any): Promise<any> => {
        return Get(`/api/PostBar/GetMyPostEvaluateList?${qs.stringify(query)}`, '');
    };
    /**
     * 获取帖子详情
     */
    export var GetPostDetail = (query: any): Promise<any> => {
        return Get(`/api/PostBar/GetPostDetail?${qs.stringify(query)}`, '');
    };
    /**
     * 重新发布的时候获取帖子详情
     */
    export var GetPostDetailForEdit = (query: any): Promise<any> => {
        return Get(`/api/PostBar/GetPostDetailForEdit?${qs.stringify(query)}`, '');
    };
    /**
     * 添加帖子
     */
    export var AddPost = (body: any): Promise<any> => {
        return Post(`/api/PostBar/AddPost`, body);
    };
    /**
     * 修改帖子
     */
    export var SavePost = (body: any): Promise<any> => {
        return Post(`/api/PostBar/SavePost?id=${body.id}`, body);
    };
    /**
     * 点赞帖子
     */
    export var TestContentcheck = (body: any): Promise<any> => {
        return Post(`/api/PostBar/TestContentcheck`, body);
    };
    /**
     * 点赞帖子
     */
    export var LikePost = (body: any): Promise<any> => {
        return Post(`/api/PostBar/LikePost?${qs.stringify(body)}`, {});
    };
    /**
     * 取消点赞
     */
    export var CancleLike = (body: any): Promise<any> => {
        return Post(`/api/PostBar/CancleLike?${qs.stringify(body)}`, {});
    };
    /**
     * 添加帖子评价
     */
    export var AddPostEvaluate = (body: any): Promise<any> => {
        return Post(`/api/PostBar/AddPostEvaluate`, body);
    };
}
