



import { Vue, Component } from 'vue-property-decorator';
@Component({})
export default class OrderDetail extends Vue {
    public created() {}

    public mounted() {}
}
