


import { Vue, Component, Watch } from 'vue-property-decorator';
import CommonPanel from './components/CommonPanel.vue';
@Component({
    components: {},
})
export default class attract extends Vue {
    attractIndex = null;
    mounted() {
        this.attractIndex = sessionStorage.getItem('attractIndex');
        if (this.attractIndex % 2 != 0) {
            sessionStorage.setItem('attractIndex', (++this.attractIndex).toString());
            const routeData = this.$router.resolve({
                path: './attractShow',
            });
            window.open(routeData.href, '_blank');
            this.$router.push('./');
            // this.$router.push('./attractShow');
        } else {
            this.$router.push('./');
        }
    }
}
