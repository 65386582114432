
























































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { formRules } from '@/common/util/validator';
import headerPanel from './component/headerPanel.vue';
import footerBar from './component/footerBar.vue';
import { AddressSelect } from '@/components';
import { Cart, Order, ReceiverInfo } from '@/http';

@Component({
    components: {
        headerPanel,
        footerBar,
        AddressSelect,
    },
})
export default class submitOrder extends Vue {
    public formRules = formRules;
    public address: any[] = [];
    public goodsListAddress: string = '';
    public addreses: any[] = [];
    public isShow: number = null;
    public isShowAllAddress: boolean = true;
    public remarks: string = '';
    public goodsList: any[] = [];
    public userType: string = '';
    public subtotal: number = null;
    public dialogVisible: boolean = false;
    public payMethodDialogVisible: boolean = false;
    public isSubmitOrder: boolean = false;
    public payableAmount: string = '';
    public form: any = {
        receiver: '',
        phoneNumber: '',
        province: '',
        city: '',
        county: '',
        detail: '',
        address: '',
        isDefault: false,
    };
    public payMethodForm: any = {
        payMethod: '',
    };
    public area: string = '';
    public isCart: boolean = false;
    public $refs: {
        form: HTMLFormElement;
        payMethodForm: HTMLFormElement;
    };
    mounted() {
        this.init();
    }
    async init() {
        if (sessionStorage.getItem('id')) {
            this.goodsList = JSON.parse(sessionStorage.getItem('goodsList'));
            this.userType = sessionStorage.getItem('userType');
            this.initAddress();
            if (this.$route.query.type == 'cart') {
                let res = await Cart.getCart({
                    skip: 0,
                    count: -1,
                });
                let i = 0;
                res.data.forEach((ele) => {
                    ele.cartList.forEach((e) => {
                        this.goodsList.forEach((ee) => {
                            ee.orderChildList.forEach((eee) => {
                                if (e.id == eee.cartId) {
                                    i++;
                                }
                            });
                        });
                    });
                });
                if (i > 0) {
                    this.isCart = true;
                } else {
                    this.isCart = false;
                }
            }
        }
    }
    async initAddress() {
        let res = await ReceiverInfo.getReceiverInfo({
            skip: 0,
            count: -1,
        });
        if (this.isShowAllAddress) {
            this.address = res.data.slice(0, 4);
        } else {
            this.address = res.data;
        }
        this.addreses = res.data;
        this.addreses.forEach((e, i) => {
            if (e.isDefault) {
                this.isShow = i;
                this.goodsList.forEach((ele, index) => {
                    this.goodsList[index].address = e.address;
                    this.goodsListAddress = e.address + e.detail;
                    this.goodsList[index].province = e.province;
                    this.goodsList[index].city = e.city;
                    this.goodsList[index].county = e.county;
                    this.goodsList[index].detail = e.detail;
                    this.goodsList[index].phoneNumber = e.phoneNumber;
                    this.goodsList[index].receiver = e.receiver;
                    this.goodsList[index].receiveAddressId = e.id;
                });
            }
        });
        let num = 0;
        let i = 0;
        if (sessionStorage.getItem('userType') == '1') {
            this.goodsList.forEach((e) => {
                e.orderChildList.forEach((ele) => {
                    if (ele.personalFixSalePrice != null) {
                        num += (ele.personalFixSalePrice * 10000 * ele.goodsQuantity) / 10000;
                    } else {
                        i++;
                    }
                });
            });
        } else if (sessionStorage.getItem('userType') == '2' || sessionStorage.getItem('userType') == '3') {
            this.goodsList.forEach((e) => {
                e.orderChildList.forEach((ele) => {
                    if (ele.enterpriseFixSalePrice != null) {
                        num += (ele.enterpriseFixSalePrice * 10000 * ele.goodsQuantity) / 10000;
                    } else {
                        i++;
                    }
                });
            });
        }
        this.payableAmount = num == 0 || i > 0 ? '——' : num.toFixed(2).toString();
    }
    chooseAddress(item, i) {
        this.isShow = i;
        this.goodsList.forEach((ele, index) => {
            this.goodsList[index].address = item.address;
            this.goodsListAddress = item.address + item.detail;
            this.goodsList[index].province = item.province;
            this.goodsList[index].city = item.city;
            this.goodsList[index].county = item.county;
            this.goodsList[index].detail = item.detail;
            this.goodsList[index].phoneNumber = item.phoneNumber;
            this.goodsList[index].receiver = item.receiver;
            this.goodsList[index].receiveAddressId = item.id;
        });
    }
    addAddress() {
        this.dialogVisible = true;
    }
    showAllAddress() {
        if (this.isShowAllAddress) {
            this.address = this.addreses;
        } else {
            this.address = this.addreses.slice(0, 4);
        }
        this.isShowAllAddress = !this.isShowAllAddress;
    }
    manageAddress() {
        this.$router.push({
            path: '/personal/receivingAddress',
        });
    }
    getNum(item) {
        let num = 0;
        item.orderChildList.forEach((e) => {
            num += e.goodsQuantity;
        });
        return num;
    }
    getSubtotal(item) {
        let num = 0;
        let i = 0;
        if (sessionStorage.getItem('userType') == '1') {
            item.orderChildList.forEach((e) => {
                if (e.personalFixSalePrice != null) {
                    num += (e.personalFixSalePrice * 10000 * e.goodsQuantity) / 10000;
                }
                if (e.personalFixSalePrice == null) {
                    i++;
                }
            });
        } else if (sessionStorage.getItem('userType') == '2' || sessionStorage.getItem('userType') == '3') {
            item.orderChildList.forEach((e) => {
                if (e.enterpriseFixSalePrice != null) {
                    num += (e.enterpriseFixSalePrice * 10000 * e.goodsQuantity) / 10000;
                }
                if (e.enterpriseFixSalePrice == null) {
                    i++;
                }
            });
        }
        return num == 0 || i > 0 ? '——' : num.toFixed(2);
    }
    //---地址选择start---
    public selectReset: boolean = true;
    public async updateReset() {
        this.selectReset = true;
    }

    public async getProvince(item, name) {
        if (item == '') {
            this.selectAddress.province.code = '';
            this.selectAddress.city.code = '';
            this.selectAddress.area.code = '';
        }
        this.selectAddress.province.code = item;
        this.selectAddress.province.text = name;
    }

    public async getCityCode(item, name) {
        this.selectAddress.city.code = item;
        this.selectAddress.city.text = name;
    }

    public async getAreaCode(item, name) {
        this.selectAddress.area.code = item;
        this.selectAddress.area.text = name;
        this.area = item;
    }

    public selectAddress = {
        province: {
            code: '',
            text: '',
        },
        city: {
            code: '',
            text: '',
        },
        area: {
            code: '',
            text: '',
        },
    };
    //---地址选择--end---
    handleClose() {
        this.form = {
            receiver: '',
            phoneNumber: '',
            province: '',
            city: '',
            county: '',
            detail: '',
            address: '',
            isDefault: false,
        };
        this.selectAddress = {
            province: {
                code: '',
                text: '',
            },
            city: {
                code: '',
                text: '',
            },
            area: {
                code: '',
                text: '',
            },
        };
        this.$nextTick(() => {
            this.$refs.form.resetFields();
        });
        this.dialogVisible = false;
    }
    closePayMethod() {
        this.$nextTick(() => {
            this.$refs.payMethodForm.resetFields();
        });
        this.payMethodDialogVisible = false;
    }
    submitOrder(v) {
        this.payMethodDialogVisible = true;
        this.isSubmitOrder = v;
    }
    settle(v) {
        this.payMethodDialogVisible = true;
        this.isSubmitOrder = v;
    }
    save() {
        this.form.province = this.selectAddress.province.code;
        this.form.city = this.selectAddress.city.code;
        this.form.county = this.selectAddress.area.code;
        this.form.address = this.selectAddress.province.text + this.selectAddress.city.text + this.selectAddress.area.text;
        this.$refs.form.validate((valid) => {
            if (valid) {
                ReceiverInfo.addReceiverInfo({ ...this.form }).then(() => {
                    this.handleClose();
                    this.init();
                });
            }
        });
    }
    savePayMethod() {
        this.$refs.payMethodForm.validate((valid) => {
            if (valid) {
                this.goodsList.forEach((e) => {
                    e.payMethod = this.payMethodForm.payMethod;
                });
                const loading = this.$loading({
                    lock: true,
                    text: '提交中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                if (this.$route.query.name == 'specialOffer') {
                    let obj = [];
                    this.goodsList.forEach((e) => {
                        obj.push({
                            address: e.address,
                            city: e.city,
                            county: e.county,
                            detail: e.detail,
                            orderChildList: e.orderChildList,
                            payMethod: e.payMethod,
                            phoneNumber: e.phoneNumber,
                            platform: e.platform,
                            province: e.province,
                            receiveAddressId: e.receiveAddressId,
                            receiver: e.receiver,
                            remark: e.remark,
                            shopId: e.shopId,
                            shopName: e.shopName,
                            activityType: '2',
                            activityId: this.$route.query.id,
                        });
                    });
                    Order.addOrder({ orderList: obj })
                        .then((res) => {
                            this.payMethodDialogVisible = false;
                            if (this.isCart) {
                                let ids = [];
                                obj.forEach((e) => {
                                    e.orderChildList.forEach((ele) => {
                                        ids.push(ele.cartId);
                                    });
                                });
                                Cart.deleteCart({ ids: ids }).then((res) => {
                                    loading.close();
                                });
                            } else {
                                loading.close();
                            }
                            if (this.payMethodForm.payMethod == '2' || this.isSubmitOrder) {
                                this.$router.push({
                                    path: '/orderDetail/detail',
                                    query: {
                                        id: res.extData,
                                    },
                                });
                            } else if (this.payMethodForm.payMethod == '1') {
                                this.$router.push({
                                    path: '/settleCenter',
                                    query: {
                                        id: res.extData,
                                        type: '1',
                                    },
                                });
                            } else if (this.payMethodForm.payMethod == '3') {
                                this.$router.push({
                                    path: '/settleCenter',
                                    query: {
                                        id: res.extData,
                                        type: '3',
                                    },
                                });
                            } else if (this.payMethodForm.payMethod == '4') {
                                this.$router.push({
                                    path: '/settleCenter',
                                    query: {
                                        id: res.extData,
                                        type: '4',
                                    },
                                });
                            }
                        })
                        .catch((error) => {
                            loading.close();
                        });
                } else {
                    Order.addOrder({ orderList: this.goodsList })
                        .then((res) => {
                            this.payMethodDialogVisible = false;
                            if (this.isCart) {
                                let ids = [];
                                this.goodsList.forEach((e) => {
                                    e.orderChildList.forEach((ele) => {
                                        ids.push(ele.cartId);
                                    });
                                });
                                Cart.deleteCart({ ids: ids }).then((res) => {
                                    loading.close();
                                });
                            } else {
                                loading.close();
                            }
                            if (this.payMethodForm.payMethod == '2' || this.isSubmitOrder) {
                                this.$router.push({
                                    path: '/orderDetail/detail',
                                    query: {
                                        id: res.extData,
                                    },
                                });
                            } else if (this.payMethodForm.payMethod == '1') {
                                this.$router.push({
                                    path: '/settleCenter',
                                    query: {
                                        id: res.extData,
                                        type: '1',
                                    },
                                });
                            } else if (this.payMethodForm.payMethod == '3') {
                                this.$router.push({
                                    path: '/settleCenter',
                                    query: {
                                        id: res.extData,
                                        type: '3',
                                    },
                                });
                            } else if (this.payMethodForm.payMethod == '4') {
                                this.$router.push({
                                    path: '/settleCenter',
                                    query: {
                                        id: res.extData,
                                        type: '4',
                                    },
                                });
                            }
                        })
                        .catch((error) => {
                            loading.close();
                        });
                }
            }
        });
    }
    jumpCart() {
        this.$router.push({
            path: '/personal/shoppingCart',
        });
    }
}
