import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace ReceiverInfo {
    /**
     * 获取收货地址列表
     */
    export var getReceiverInfo = (query: any): Promise<any> => {
        return Get(`/api/ReceiverInfo?${qs.stringify(query)}`, '');
    };
    /**
     * 添加收货地址
     */
    export var addReceiverInfo = (body: any): Promise<any> => {
        return Post(`/api/ReceiverInfo`, body);
    };

    /**
     * 编辑收货地址
     */
    export var editReceiverInfo = (body: any): Promise<any> => {
        return Put(`/api/ReceiverInfo`, body);
    };

    /**
     * 删除地址
     */
    export var receiverInfoDelete = (id: any): Promise<any> => {
        return Put(`/api/ReceiverInfo/delete/${id}`, { id: id });
    };
}
