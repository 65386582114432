



































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { RefundOrder, Order, ReceiverInfo, PreferentialActivity } from '@/http';
import { PaginationPanel } from '@/components';
interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        PaginationPanel,
    },
})
export default class trialSaleSalePanel extends Vue {
    @Prop({
        default: '1',
    })
    public type: string;
    public current: string;
    public orderList: any = [];
    public tableData: any[] = [];
    public addressDialogVisible: boolean = false;
    public currentRow: any = null;
    public id: string = '';
    public rePagin: boolean = false;
    public total: number = 0;
    public $refs: {
        singleTable: HTMLFormElement;
    };
    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };
    mounted() {
        this.init();
    }
    setCurrentPage(currentPage) {
        this.current = currentPage;
    }
    lianxi(item) {
        if (sessionStorage.getItem('id')) {
            this.$root.$emit('lianxi', item.shopId);
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    async init() {
        PreferentialActivity.GetShopTogetherApplyRecordList({
            shopTogetherStatus: this.current ? this.current : '1',
            skip: this.searchPagin.skip,
            count: this.searchPagin.count,
        }).then((res) => {
            this.orderList = res.data;
            this.total = res.total;
        });
    }
    jumpDetail(id) {
        this.$router.push({
            path: '/refund/detail',
            query: {
                id: id,
            },
        });
    }
    async onPaginationChange(pagin: Pagination) {
        this.searchPagin = pagin;
        this.init();
    }
    async changeAddress(item) {
        this.addressDialogVisible = true;
        this.id = item.id;
        let temp = await ReceiverInfo.getReceiverInfo({
            skip: 0,
            count: -1,
        });
        this.tableData = temp.data;
    }
    cancelApply(row) {
        this.$confirm('确定取消申请吗?')
            .then(async () => {
                await PreferentialActivity.CancelShopTogetherApplyRecord({ id: row.id });
                this.$message.success('取消申请成功');
                this.init();
            })
            .catch(() => {});
    }
    handleCurrentChange(val) {
        this.currentRow = val;
    }
    setCurrent(row) {
        this.$refs.singleTable.setCurrentRow(row);
    }
    submitAddress() {
        if (this.currentRow == null) {
            this.$message.warning('请选择收货地址');
        } else {
            PreferentialActivity.SaveShopTogetherApplyRecordReceiver({
                id: this.id,
                receiveAddressId: this.currentRow.id,
                receiver: this.currentRow.receiver,
                phoneNumber: this.currentRow.phoneNumber,
                province: this.currentRow.province,
                city: this.currentRow.city,
                county: this.currentRow.county,
                detail: this.currentRow.detail,
                address: this.currentRow.address,
            }).then((res) => {
                this.addressDialogVisible = false;
                this.$message.success('地址修改成功');
                this.init();
            });
        }
    }
    closeDialogVisible() {
        this.setCurrent([]);
    }
}
