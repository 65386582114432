

























import { upload } from '@/common/util/ossUploadService';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
@Component({})
export default class AttachmentPanel extends Vue {
    @Prop({ default: () => [] })
    value;
    @Prop({ default: true })
    upload;
    @Prop({ default: '' })
    title;
    mounted() {}
    async filechange(event) {
        let file = event.target.files[0];
        const res = await upload(file, file.name);
        if (res.res.statusCode == '200') {
            let url = res.res.requestUrls[0].split('?')[0];
            this.value.push({ path: url, name: file.name });
        } else {
            this.$message.warning('上传失败!');
        }
        $('#upLoader').val('');
    }
    downFile(url) {
        window.open(url, '_blank');
    }
    delFile(i) {
        this.value.splice(i, 1);
    }
}
