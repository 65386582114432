
















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Order } from '@/http';
@Component({
    components: {},
})
export default class refundorderInfo extends Vue {
    @Prop()
    public line: boolean;
    @Prop()
    public orderData: any;

    lianxi(orderData) {
        if (sessionStorage.getItem('id')) {
            this.$root.$emit('lianxi', orderData.orderChildList[0].shopId);
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
}
