/*
 * @Description: 
 * @Autor: jyf
 * @Date: 2021-04-27 14:40:52
 * @LastEditors: jyf
 * @LastEditTime: 2021-05-05 15:23:17
 */
import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace AccessRecord {
  /**
   * 添加访问记录
   */
  export var AddAccessRecord = (body: any): Promise<any> => {
    return Post(`/api/AccessRecord`, body);
  };

  export var GetUserIp = (): Promise<any> => {
    return Get(`https://pv.sohu.com/cityjson?ie=utf-8`, {});
  };
}
