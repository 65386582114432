































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class PaginationPanel extends Vue {
    @Prop({
        default: 0,
    })
    public total: number;
    @Prop({
        default: false,
    })
    public reset: boolean;
    @Prop({
        default: () => [10, 20, 50],
    })
    public pagesize;
    public curPageSize = 10;
    public paginationOptions = {
        skip: 0,
        count: this.curPageSize,
        currentPage: 1,
    };
    /* 重置当前页 */
    @Watch('reset')
    public reloadChange(val: boolean) {
        if (val) {
            this.paginationOptions.currentPage = 1;
        }
    }
    @Watch('total')
    public totalChange(newVal, oldVal) {
        this.total = newVal;
        this.lastPageNum = Math.ceil(newVal / this.paginationOptions.count);
    }

    mounted() {
        this.lastPageNum = Math.ceil(this.total / this.paginationOptions.count);
    }

    public lastPageNum = 0;
    public firstPageBtnDisabled = true;
    public lastPageBtnDisabled = false;

    public handleCurrentChange(val) {
        this.firstPageBtnDisabled = val === 1 ? true : false;
        this.lastPageBtnDisabled = val === this.lastPageNum ? true : false;
        this.paginationOptions.currentPage = val;
        this.paginationOptions.skip = (this.paginationOptions.currentPage - 1) * this.paginationOptions.count;
        this.$emit('onPaginationChange', this.paginationOptions);
    }
    public handleSizeChange(val) {
        this.paginationOptions.count = val;
        if (Math.ceil(this.total / val) < this.paginationOptions.currentPage) {
            this.paginationOptions.skip = 0;
        } else {
            this.paginationOptions.skip = (this.paginationOptions.currentPage - 1) * this.paginationOptions.count;
            this.$emit('onPaginationChange', this.paginationOptions);
        }
    }

    toFirstPage() {
        this.handleCurrentChange(1);
    }
    toLastPage() {
        this.paginationOptions.currentPage = Math.ceil(this.total / this.paginationOptions.count);
        this.handleCurrentChange(this.paginationOptions.currentPage);
    }
}
