


































import { Vue, Component } from "vue-property-decorator";
const img = require("@/assets/img/404.png");
const img_cloud = require("@/assets/img/404_cloud.png");

@Component
export default class Page404 extends Vue {
  public img_404 = img;
  public img_404_cloud = img_cloud;

  public get message() {
    return "特朗普说这个页面你不能进......";
  }
}
