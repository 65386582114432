



























































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { CommonPanel } from '@/components';
import { fmoney } from '@/common/util/money';
import { NavBar } from '../home/components';
import { MessageBox, Message } from 'element-ui';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import { Goods, Collection, Cart, Shop, Focus, AccessRecord, PreferentialActivity } from '@/http';
declare var returnCitySN: any;
@Component({
    components: {
        CommonPanel,
        NavBar,
        VueSlickCarousel,
    },
})
export default class goodsDetail extends Vue {
    public settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnDotsHover: true,
        pauseOnFocus: true,
        pauseOnHover: true,
    };
    tkey = 0;
    public isShow: number = 0;
    public bigSrc: string = '';
    public specsId: string = '';
    public specsName: string = '';
    public isShoucang: boolean = false;
    public num: number = 1;
    public rate: number = 5;
    public data: any = {};
    public rules: any = {};
    public showImg: any[] = [];
    public shoucangCD: boolean = true;
    public goodsRecommends: any[] = [];
    public fullShopDetail: any = {};
    public isUserFocus: boolean = false;
    public weight: number = null;
    public enterpriseFixSalePrice: number = null;
    public enterpriseSectionSalePriceOne: number = null;
    public enterpriseSectionSalePriceTwo: number = null;
    public personalFixSalePrice: number = null;
    public personalSectionSalePriceOne: number = null;
    public personalSectionSalePriceTwo: number = null;
    public userType: string = '';
    public shopQualificationList: any[] = [];
    public percentage: number = 0;
    public list: any[] = [];
    mounted() {
        this.init();
    }
    lianxi() {
        if (sessionStorage.getItem('id')) {
            this.$root.$emit('lianxi', this.data.shopId);
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    scroll() {
        let speed = 100;
        let wrapper = document.getElementById('wrapper');
        let list_one = document.getElementById('list_one');
        let list_two = document.getElementById('list_two');
        list_two.innerHTML = list_one.innerHTML;
        console.log(list_one.innerHTML);
        function Marquee() {
            if (list_two.offsetHeight - wrapper.scrollTop <= 0) wrapper.scrollTop -= list_one.offsetHeight;
            else {
                wrapper.scrollTop += 1;
            }
        }
        let MyMar = setInterval(Marquee, speed);
        wrapper.onmouseover = function () {
            clearInterval(MyMar);
        };
        wrapper.onmouseout = function () {
            MyMar = setInterval(Marquee, speed);
        };
    }
    async init() {
        // this.scroll();
        this.list = [
            {
                name: '苏小妍',
                date: '2020-09-12',
                text: 'osb违规事件',
            },
            {
                name: '苏小妍',
                date: '2020-09-12',
                text: 'osb违规事件',
            },
            {
                name: '苏小妍',
                date: '2020-09-12',
                text: 'osb违规事件',
            },
            {
                name: '苏小妍',
                date: '2020-09-12',
                text: 'osb违规事件',
            },
            {
                name: '苏小妍',
                date: '2020-09-12',
                text: 'osb违规事件',
            },
        ];
        this.data = [];
        if (this.$route.query.name == 'newProduc') {
            this.data = await PreferentialActivity.GetPANewGoodsDetail({
                goodsId: this.$route.query.id,
                activityId: this.$route.query.preferentialActivityId,
            });
            if (this.data != null) {
                if (this.data.goodsSpecificationList.length > 0) {
                    this.specsId = this.data.goodsSpecificationList[0].id;
                    this.specsName = this.data.goodsSpecificationList[0].name;
                    this.enterpriseSectionSalePriceOne = this.data.goodsSpecificationList[0].enterpriseSectionSalePriceOne;
                    this.enterpriseSectionSalePriceTwo = this.data.goodsSpecificationList[0].enterpriseSectionSalePriceTwo;
                    this.enterpriseFixSalePrice = this.data.goodsSpecificationList[0].enterpriseFixSalePrice;
                    this.personalSectionSalePriceOne = this.data.goodsSpecificationList[0].personalSectionSalePriceOne;
                    this.personalSectionSalePriceTwo = this.data.goodsSpecificationList[0].personalSectionSalePriceTwo;
                    this.personalFixSalePrice = this.data.goodsSpecificationList[0].personalFixSalePrice;
                }
            } else {
                MessageBox.alert(`该活动已经到期`, '提示', {
                    confirmButtonText: '确定',
                    type: 'warning',
                    callback: (action) => {
                        window.history.back();
                        this.$router.push({
                            path: '/preferential',
                        });
                    },
                });
            }
            this.rules = await PreferentialActivity.GetNewGoodsActivityResult({
                activityId: this.$route.query.preferentialActivityId,
                goodsId: this.$route.query.id,
                specId: this.specsId,
                userId: sessionStorage.getItem('id'),
            });
        } else if (this.$route.query.name == 'shopTogether') {
            this.data = await PreferentialActivity.GetPAShopTogetherDetail({
                goodsId: this.$route.query.id,
                activityId: this.$route.query.preferentialActivityId,
            });
            if (this.data != null) {
                if (this.data.goodsSpecificationList.length > 0) {
                    this.specsId = this.data.goodsSpecificationList[0].id;
                    this.specsName = this.data.goodsSpecificationList[0].name;
                    this.enterpriseSectionSalePriceOne = this.data.goodsSpecificationList[0].enterpriseSectionSalePriceOne;
                    this.enterpriseSectionSalePriceTwo = this.data.goodsSpecificationList[0].enterpriseSectionSalePriceTwo;
                    this.enterpriseFixSalePrice = this.data.goodsSpecificationList[0].enterpriseFixSalePrice;
                    this.personalSectionSalePriceOne = this.data.goodsSpecificationList[0].personalSectionSalePriceOne;
                    this.personalSectionSalePriceTwo = this.data.goodsSpecificationList[0].personalSectionSalePriceTwo;
                    this.personalFixSalePrice = this.data.goodsSpecificationList[0].personalFixSalePrice;
                }
            } else {
                MessageBox.alert(`该活动已经到期`, '提示', {
                    confirmButtonText: '确定',
                    type: 'warning',
                    callback: (action) => {
                        window.history.back();
                        this.$router.push({
                            path: '/preferential',
                        });
                    },
                });
            }
            this.rules = await PreferentialActivity.GetShopTogetherActivityResult({
                activityId: this.$route.query.preferentialActivityId,
                goodsId: this.$route.query.id,
                specId: this.specsId,
                userId: sessionStorage.getItem('id') ? sessionStorage.getItem('id') : '',
            });
            this.percentage = Math.round((this.rules.applyCount / this.rules.showGroupCount) * 100);
        } else if (this.$route.query.name == 'specialOffer') {
            this.data = await PreferentialActivity.GetPASpecialPriceDetail({
                goodsId: this.$route.query.id,
                activityId: this.$route.query.preferentialActivityId,
            });
            if (this.data != null) {
                if (this.data.goodsSpecificationList.length > 0) {
                    this.specsId = this.data.goodsSpecificationList[0].id;
                    this.specsName = this.data.goodsSpecificationList[0].name;
                    this.enterpriseSectionSalePriceOne = this.data.goodsSpecificationList[0].enterpriseSectionSalePriceOne;
                    this.enterpriseSectionSalePriceTwo = this.data.goodsSpecificationList[0].enterpriseSectionSalePriceTwo;
                    this.enterpriseFixSalePrice = this.data.goodsSpecificationList[0].enterpriseFixSalePrice;
                    this.personalSectionSalePriceOne = this.data.goodsSpecificationList[0].personalSectionSalePriceOne;
                    this.personalSectionSalePriceTwo = this.data.goodsSpecificationList[0].personalSectionSalePriceTwo;
                    this.personalFixSalePrice = this.data.goodsSpecificationList[0].personalFixSalePrice;
                }
            } else {
                MessageBox.alert(`该活动已经到期`, '提示', {
                    confirmButtonText: '确定',
                    type: 'warning',
                    callback: (action) => {
                        window.history.back();
                        this.$router.push({
                            path: '/preferential',
                        });
                    },
                });
            }
            this.rules = await PreferentialActivity.GetSpecialPriceActivityResult({
                activityId: this.$route.query.preferentialActivityId,
                goodsId: this.$route.query.id,
                specId: this.specsId,
                userId: sessionStorage.getItem('id') ? sessionStorage.getItem('id') : '',
            });
        }
        // let res = await Goods.GetGoodsDetail(this.$route.query.id);
        // this.data = res;
        // this.showImg =
        //     this.data.displayImageAttachmentList && this.data.displayImageAttachmentList
        //         ? this.data.displayImageAttachmentList.concat(this.data.displayImageAttachmentList)
        //         : [];
        this.showImg = this.data.displayImageAttachmentList ? this.data.displayImageAttachmentList : [];
        let resShop = await Shop.GetShopQualificationList({ id: this.data.shopId });
        this.shopQualificationList = resShop.data;
        AccessRecord.AddAccessRecord({
            webHost: returnCitySN['cip'],
            weChatOpenId: null,
            dataId: this.data.id,
            accessPage: 'Goods',
            creatorUserName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : '',
        });
        AccessRecord.AddAccessRecord({
            webHost: returnCitySN['cip'],
            weChatOpenId: null,
            dataId: this.data.shopId,
            accessPage: 'Shop',
            creatorUserName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : '',
        });
        if (this.data.goodsSpecificationList.length > 0) {
            if (this.data.goodsSpecificationList[0].enterpriseSalePriceType == 2) {
                this.enterpriseSectionSalePriceOne = this.data.goodsSpecificationList[0].enterpriseSectionSalePriceOne;
                this.enterpriseSectionSalePriceTwo = this.data.goodsSpecificationList[0].enterpriseSectionSalePriceTwo;
            } else {
                this.enterpriseFixSalePrice = this.data.goodsSpecificationList[0].enterpriseFixSalePrice;
            }
            if (this.data.goodsSpecificationList[0].personalSalePriceType == 2) {
                this.personalSectionSalePriceOne = this.data.goodsSpecificationList[0].personalSectionSalePriceOne;
                this.personalSectionSalePriceTwo = this.data.goodsSpecificationList[0].personalSectionSalePriceTwo;
            } else {
                this.personalFixSalePrice = this.data.goodsSpecificationList[0].personalFixSalePrice;
            }
            this.weight = this.data.goodsSpecificationList[0].weight;
        } else {
            this.weight = this.data.weight;
        }
        this.bigSrc = this.data.displayImageAttachmentList.length > 0 ? this.data.displayImageAttachmentList[0].path : '';
        let recommend = await Goods.GetRecommend({
            notGoodsId: this.data.id,
            goodsTypeOneId: this.data.goodsTypeOneId,
            isGoodsAround: true,
            skip: 0,
            count: 10,
        });
        this.goodsRecommends = recommend.data;
        let fullShopDetail = await Shop.GetFullShopDetail(this.data.shopId);
        this.fullShopDetail = fullShopDetail;
        if (sessionStorage.getItem('id')) {
            let isUserFocus = await Focus.isUserFocus({ id: this.data.shopId });
            let result = await Collection.isUserCollection({ id: this.$route.query.id });
            this.isShoucang = result.extData;
            if (isUserFocus.extData) {
                this.isUserFocus = true;
            } else {
                this.isUserFocus = false;
            }
            this.userType = sessionStorage.getItem('userType');
        } else {
            this.userType = '';
        }
        this.tkey++;
    }
    async changeSpecsId(v) {
        this.data.goodsSpecificationList.forEach((e) => {
            if (e.id == v) {
                this.specsName = e.name;
                if (e.enterpriseSalePriceType == 2) {
                    this.enterpriseSectionSalePriceOne = e.enterpriseSectionSalePriceOne;
                    this.enterpriseSectionSalePriceTwo = e.enterpriseSectionSalePriceTwo;
                    this.enterpriseFixSalePrice = 0;
                } else {
                    this.enterpriseFixSalePrice = e.enterpriseFixSalePrice;
                }
                if (e.personalSalePriceType == 2) {
                    this.personalSectionSalePriceOne = e.personalSectionSalePriceOne;
                    this.personalSectionSalePriceTwo = e.personalSectionSalePriceTwo;
                    this.personalFixSalePrice = 0;
                } else {
                    this.personalFixSalePrice = e.personalFixSalePrice;
                }
                this.weight = e.weight;
            }
        });
        if (this.$route.query.name == 'shopTogether') {
            this.rules = await PreferentialActivity.GetShopTogetherActivityResult({
                activityId: this.$route.query.preferentialActivityId,
                goodsId: this.$route.query.id,
                specId: v,
                userId: sessionStorage.getItem('id') ? sessionStorage.getItem('id') : '',
            });
            this.percentage = Math.round((this.rules.applyCount / this.rules.showGroupCount) * 100);
        } else if (this.$route.query.name == 'newProduc') {
            this.rules = await PreferentialActivity.GetNewGoodsActivityResult({
                activityId: this.$route.query.preferentialActivityId,
                goodsId: this.$route.query.id,
                specId: v,
                userId: sessionStorage.getItem('id') ? sessionStorage.getItem('id') : '',
            });
        } else if (this.$route.query.name == 'specialOffer') {
            this.rules = await PreferentialActivity.GetSpecialPriceActivityResult({
                activityId: this.$route.query.preferentialActivityId,
                goodsId: this.$route.query.id,
                specId: v,
                userId: sessionStorage.getItem('id') ? sessionStorage.getItem('id') : '',
            });
        }
    }
    mouseenter(item, i) {
        this.bigSrc = item.path;
        this.isShow = i;
    }
    handleChange(value) {
        console.log(value);
    }
    shoucang() {
        // this.isShoucang = !this.isShoucang;
        if (sessionStorage.getItem('id')) {
            if (this.shoucangCD) {
                this.shoucangCD = false;
                // if (this.isShoucang) {
                //     Collection.deleteCollection({ goodsId: this.data.id }).then(() => {
                //         this.shoucangCD = true;
                //         this.init();
                //     });
                // } else {
                //     Collection.addCollection({
                //         goodsId: this.data.id,
                //     }).then((res) => {
                //         this.shoucangCD = true;
                //         this.init();
                //     });
                // }
                if (!this.isShoucang) {
                    Collection.addCollection({
                        goodsId: this.data.id,
                    }).then((res) => {
                        this.shoucangCD = true;
                        this.init();
                    });
                }
            }
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    addGouwuche() {
        if (sessionStorage.getItem('id')) {
            let cartList = [
                {
                    goodsId: this.data.id,
                    goodsSpecificationId: this.specsId,
                    number: this.num,
                },
            ];
            Cart.addBatchAddCart({
                cartList: cartList,
            }).then((res) => {
                this.$message.success('已加入购物车');
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    goumai(item) {
        let personalFixSalePrice = null;
        let personalSectionSalePriceOne = null;
        let personalSectionSalePriceTwo = null;
        let enterpriseFixSalePrice = null;
        let enterpriseSectionSalePriceOne = null;
        let enterpriseSectionSalePriceTwo = null;
        if (
            this.enterpriseFixSalePrice != null ||
            this.enterpriseSectionSalePriceOne != null ||
            this.enterpriseSectionSalePriceTwo != null ||
            this.personalFixSalePrice != null ||
            this.personalSectionSalePriceOne != null ||
            this.personalSectionSalePriceTwo != null
        ) {
            personalFixSalePrice = this.personalFixSalePrice;
            personalSectionSalePriceOne = this.personalSectionSalePriceOne;
            personalSectionSalePriceTwo = this.personalSectionSalePriceTwo;
            enterpriseFixSalePrice = this.enterpriseFixSalePrice;
            enterpriseSectionSalePriceOne = this.enterpriseSectionSalePriceOne;
            enterpriseSectionSalePriceTwo = this.enterpriseSectionSalePriceTwo;
        } else {
            personalFixSalePrice = item.personalFixSalePrice;
            personalSectionSalePriceOne = item.personalSectionSalePriceOne;
            personalSectionSalePriceTwo = item.personalSectionSalePriceTwo;
            enterpriseFixSalePrice = item.enterpriseFixSalePrice;
            enterpriseSectionSalePriceOne = item.enterpriseSectionSalePriceOne;
            enterpriseSectionSalePriceTwo = item.enterpriseSectionSalePriceTwo;
        }
        let orderList = [
            {
                platform: '1',
                receiveAddressId: '',
                receiver: '',
                phoneNumber: '',
                province: '',
                city: '',
                county: '',
                detail: '',
                address: '',
                remark: '',
                shopId: item.shopId,
                shopName: item.shopName,
                payMethod: '',
                orderChildList: [
                    {
                        cartId: '',
                        brand: item.brand,
                        merchantType: item.merchantType,
                        goodsId: item.id,
                        goodsNumber: item.goodsNumber,
                        name: item.name,
                        deliveryAddress: item.deliveryAddress,
                        weight: this.weight,
                        personalFixSalePrice: personalFixSalePrice,
                        personalSectionSalePriceOne: personalSectionSalePriceOne,
                        personalSectionSalePriceTwo: personalSectionSalePriceTwo,
                        enterpriseFixSalePrice: enterpriseFixSalePrice,
                        enterpriseSectionSalePriceOne: enterpriseSectionSalePriceOne,
                        enterpriseSectionSalePriceTwo: enterpriseSectionSalePriceTwo,
                        specId: this.specsId,
                        specName: this.specsName,
                        goodsQuantity: this.num,
                        coverImageAttachment: item.coverImageAttachmentList[0],
                        mainBusiness: item.mainBusiness,
                    },
                ],
            },
        ];
        if (sessionStorage.getItem('id')) {
            sessionStorage.setItem('goodsList', JSON.stringify(orderList));
            this.$router.push({
                path: '/submitOrder',
                query: {
                    name: 'specialOffer',
                    id: this.$route.query.preferentialActivityId,
                },
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    // 立即申请
    shenqing(item) {
        let personalFixSalePrice = null;
        let personalSectionSalePriceOne = null;
        let personalSectionSalePriceTwo = null;
        let enterpriseFixSalePrice = null;
        let enterpriseSectionSalePriceOne = null;
        let enterpriseSectionSalePriceTwo = null;
        if (
            this.enterpriseFixSalePrice != null ||
            this.enterpriseSectionSalePriceOne != null ||
            this.enterpriseSectionSalePriceTwo != null ||
            this.personalFixSalePrice != null ||
            this.personalSectionSalePriceOne != null ||
            this.personalSectionSalePriceTwo != null
        ) {
            personalFixSalePrice = this.personalFixSalePrice;
            personalSectionSalePriceOne = this.personalSectionSalePriceOne;
            personalSectionSalePriceTwo = this.personalSectionSalePriceTwo;
            enterpriseFixSalePrice = this.enterpriseFixSalePrice;
            enterpriseSectionSalePriceOne = this.enterpriseSectionSalePriceOne;
            enterpriseSectionSalePriceTwo = this.enterpriseSectionSalePriceTwo;
        } else {
            personalFixSalePrice = item.personalFixSalePrice;
            personalSectionSalePriceOne = item.personalSectionSalePriceOne;
            personalSectionSalePriceTwo = item.personalSectionSalePriceTwo;
            enterpriseFixSalePrice = item.enterpriseFixSalePrice;
            enterpriseSectionSalePriceOne = item.enterpriseSectionSalePriceOne;
            enterpriseSectionSalePriceTwo = item.enterpriseSectionSalePriceTwo;
        }
        let orderList = [
            {
                preferentialActivityId: this.$route.query.preferentialActivityId,
                shopId: item.shopId,
                goodsId: item.id,
                goodsName: item.name,
                goodsQuantity: '1',
                specId: this.specsId,
                spec: this.specsName,
                coverImageAttachment: item.coverImageAttachmentList[0],
                goodsNumber: item.goodsNumber,
                deliveryAddress: item.deliveryAddress,
                personalFixSalePrice: personalFixSalePrice,
                enterpriseFixSalePrice: enterpriseFixSalePrice,
                receiveAddressId: '',
                receiver: '',
                phoneNumber: '',
                province: '',
                city: '',
                county: '',
                detail: '',
                address: '',
            },
        ];
        if (sessionStorage.getItem('id')) {
            sessionStorage.setItem('goodsList', JSON.stringify(orderList));
            this.$router.push({
                path: '/preferentialInfo',
                query: {
                    name: 'newProduc',
                },
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    // 参与拼团
    pintuan(item) {
        console.log(' this.userType ' + this.userType);
        let personalFixSalePrice = null;
        let personalSectionSalePriceOne = null;
        let personalSectionSalePriceTwo = null;
        let enterpriseFixSalePrice = null;
        let enterpriseSectionSalePriceOne = null;
        let enterpriseSectionSalePriceTwo = null;
        if (
            this.enterpriseFixSalePrice != null ||
            this.enterpriseSectionSalePriceOne != null ||
            this.enterpriseSectionSalePriceTwo != null ||
            this.personalFixSalePrice != null ||
            this.personalSectionSalePriceOne != null ||
            this.personalSectionSalePriceTwo != null
        ) {
            personalFixSalePrice = this.personalFixSalePrice;
            personalSectionSalePriceOne = this.personalSectionSalePriceOne;
            personalSectionSalePriceTwo = this.personalSectionSalePriceTwo;
            enterpriseFixSalePrice = this.enterpriseFixSalePrice;
            enterpriseSectionSalePriceOne = this.enterpriseSectionSalePriceOne;
            enterpriseSectionSalePriceTwo = this.enterpriseSectionSalePriceTwo;
        } else {
            personalFixSalePrice = item.personalFixSalePrice;
            personalSectionSalePriceOne = item.personalSectionSalePriceOne;
            personalSectionSalePriceTwo = item.personalSectionSalePriceTwo;
            enterpriseFixSalePrice = item.enterpriseFixSalePrice;
            enterpriseSectionSalePriceOne = item.enterpriseSectionSalePriceOne;
            enterpriseSectionSalePriceTwo = item.enterpriseSectionSalePriceTwo;
        }
        let orderList = [
            {
                preferentialActivityId: this.$route.query.preferentialActivityId,
                shopId: item.shopId,
                shopName: item.shopName,
                goodsId: item.id,
                goodsName: item.name,
                goodsQuantity: this.num,
                specId: this.specsId,
                spec: this.specsName,
                coverImageAttachment: item.coverImageAttachmentList[0],
                goodsNumber: item.goodsNumber,
                deliveryAddress: item.deliveryAddress,
                personalFixSalePrice: personalFixSalePrice,
                enterpriseFixSalePrice: enterpriseFixSalePrice,
                receiveAddressId: '',
                receiver: '',
                phoneNumber: '',
                province: '',
                city: '',
                county: '',
                detail: '',
                address: '',
                totalQuantity: this.num,
                price: this.userType == '2' ? enterpriseFixSalePrice : personalFixSalePrice,
                totalAmount:
                    this.userType == '2' ? (enterpriseFixSalePrice * this.num * 10000) / 10000 : (personalFixSalePrice * this.num * 10000) / 10000,
            },
        ];
        if (sessionStorage.getItem('id')) {
            sessionStorage.setItem('goodsList', JSON.stringify(orderList));
            this.$router.push({
                path: '/preferentialInfo',
                query: {
                    name: 'shopTogether',
                },
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    jumpShop() {
        this.$router.push({
            path: '/shop',
            query: {
                shopId: this.fullShopDetail.id,
            },
        });
    }
    followShop() {
        if (sessionStorage.getItem('id')) {
            Focus.addFocus({
                shopId: this.data.shopId,
            }).then((res) => {
                this.init();
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    deletfollow() {
        Focus.DeleteByShopId({
            shopId: this.data.shopId,
        }).then(() => {
            this.init();
        });
    }
    jumpDetail(id) {
        this.$router.push({
            path: '/goodsDetail',
            query: {
                id: id,
            },
        });
    }
    @Watch('$route')
    changeRoute(to, from) {
        this.init();
    }
}
