/*
 * @Description:
 * @Autor: jyf
 * @Date: 2021-04-27 15:42:27
 * @LastEditors: jyf
 * @LastEditTime: 2021-04-27 17:12:15
 */
import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace GoodsType {
    /**
     * 获取完整商品分类列表
     */
    export var GetFullGoodsTypeList = (): Promise<any> => {
        return Get(`/api/GoodsType/GetFullGoodsTypeList`, '');
    };
    /**
     * 获取 商品分类列表
     */
    export var GetRecommendHomeGoodsTypeList = (): Promise<any> => {
        return Get(`/api/GoodsType/recommendHomeGoodsTypeList`, '');
    };

    /**
     * 获取店铺品牌列表
     */
    export var GetShopBrand = (query: any): Promise<any> => {
        return Get(`/api/ShopBrand?${qs.stringify(query)}`, '');
    };
    /**
     * 获取商品分类列表
     */
    export var GetGoodsTypeList = (query: any): Promise<any> => {
        return Get(`/api/GoodsType?${qs.stringify(query)}`, {});
    };
}
