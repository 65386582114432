import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace Order {
    /**
     * 添加订单
     */
    export var addOrder = (body: any): Promise<any> => {
        return Post(`/api/Order`, body);
    };
    /**
     * 查询微信订单
     */
    export var getQueryWxOrder = (query: any): Promise<any> => {
        return Get(`/api/Order/QueryWxOrder?${qs.stringify(query)}`, {});
    };
    // /**
    //  * 统一下单
    //  */
    export var addUnifiedOrder = (body: any): Promise<any> => {
        return Post(`/api/Order/UnifiedOrder`, body);
    };
    /**
     * 统一下单
     */
    export var UnifiedOrder = (body: any): Promise<any> => {
        return Post(`/api/Order/UnionUnifiedOrder`, body);
    };
    /**
       * 订单列表
       */
    export var Order = (query: any): Promise<any> => {
        return Get(`/api/Order?${qs.stringify(query)}`, '');
    };
    /**
     * 删除订单
     */
    export var DeleteOrder = (id: any): Promise<any> => {
        return Put(`/api/Order/DeleteOrder/${id}`, {});
    };
    /**
     * 取消订单
     */
    export var SaveOrderStatus = (query: any): Promise<any> => {
        return Put(`/api/Order/SaveOrderStatus`, query);
    };
    /**
      * 订单详情
      */
    export var OrderDetail = (id: any): Promise<any> => {
        return Get(`/api/Order/${id}`, '');
    };
    /**
      * 物流
      */
    export var GetOrderLogList = (query: any): Promise<any> => {
        return Get(`/api/Order/GetOrderLogList?${qs.stringify(query)}`, '');
    };
    /**
      * 刷新订单状态
      */
    export var RefreshOrderStatus = (id: any): Promise<any> => {
        return Get(`/api/Order/RefreshOrderStatus/${id}`, '');
    };
    /**
     * 获取订单数量
     */
    export var GetOrderCountResult = (): Promise<any> => {
        return Get(`/api/Order/GetOrderCountResult`, '');
    };
}
