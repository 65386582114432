


























































import { Vue, Component, Watch } from 'vue-property-decorator';
import { RightTieba, RightAnchor2 } from '@/components';
import { NavBar } from '../home/components';
import { PostBar, ShopBrand, GeneralAdvert } from '@/http';
declare var returnCitySN: any;
export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: { NavBar, RightTieba, RightAnchor2 },
})
export default class interaction extends Vue {
    toPinPai() {
        this.$router.push({
            path: `/brand`,
        });
    }
    public imgsType10 = [];
    public hotPostBar = [];
    public GetHotPostBar() {
        this.hotPostBar = [];
        PostBar.GetHotPostListByViews().then((res) => {
            this.hotPostBar = res.data;
        });
    }
    shopBrandList = [];
    imgsType9 = [];
    public getGeneralAdvert() {
        GeneralAdvert.GetGeneralAdvertApi({
            generalAdvertTypes: '10',
        }).then((res) => {
            this.imgsType10 = [];
            if (res.data) {
                res.data.forEach((item) => {
                    if (item.generalAdvertType == 10 && item.webAttachment && item.webAttachment.path) {
                        this.imgsType10.push(item);
                    }
                });
            }
        });
    }
    /**获取品牌 */
    public async getShopBrandList() {
        let list = await ShopBrand.GetShopBrandList2({ initials: '', isTop: true, skip: 0, count: 10 });
        this.shopBrandList = list.data;
        GeneralAdvert.GetGeneralAdvertApi({
            generalAdvertTypes: '9',
        }).then((res) => {
            this.imgsType9 = [];
            if (res.data) {
                let temp = this.shopBrandList.concat(res.data);
                this.imgsType9 = temp.slice(0, 10);
            }
        });
    }
    toShopDetail(id) {
        this.$router.push({
            path: '/shop',
            query: {
                shopId: id,
            },
        });
    }
    jumpOut(item) {
        if (item.webHref != '') {
            if (item.webHref.indexOf('http') > -1) {
                window.open(item.webHref);
            } else {
                window.open('https://' + item.webHref);
            }
        } else {
        }
    }
    public postList: any[] = [];
    public postTypeList: any[] = [];
    public title: string = '全部';
    canShow = false;
    mounted() {
        window.addEventListener('scroll', this.handleScrollx, true);
        this.init();
        this.loadData('');
        this.getShopBrandList();
        this.getGeneralAdvert();
    }
    handleScrollx() {
        if (window.pageYOffset > 400) {
            this.canShow = true;
        } else {
            this.canShow = false;
        }
    }
    // 初始化
    async init() {
        let res = await PostBar.GetPostTypeList({
            suitUserType: sessionStorage.getItem('userType'),
            skip: 0,
            count: -1,
        });
        this.postTypeList = res.data;
        this.GetHotPostBar();
    }
    async loadData(pb_TypeId) {
        let res = await PostBar.GetPostList({
            pb_TypeId: pb_TypeId,
            creatorUserId: '',
            status: '2',
            userId: sessionStorage.getItem('id'),
            skip: 0,
            count: -1,
        });
        res.data.forEach((item) => {
            item.coverImagePath = item.coverImagePath.split(',');
        });
        this.postList = res.data;

        var element = document.getElementById('app');
        element.scrollIntoView(false); // 参数 false 代表 Bottom
    }
    release() {
        this.$router.push({
            path: '/release',
        });
    }
    toDetail(id) {
        this.$router.push({
            path: '/interactionDetail',
            query: {
                id: id,
                name: 'hudong',
            },
        });
    }
    chooseIsLike(item) {
        if (sessionStorage.getItem('id')) {
            if (item.isLike) {
                PostBar.CancleLike({
                    postId: item.id,
                }).then((res) => {
                    this.init();
                    this.loadData('');
                });
            } else {
                PostBar.LikePost({
                    postId: item.id,
                }).then((res) => {
                    this.init();
                    this.loadData('');
                });
            }
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    changeStatus(id) {
        if (id == '') {
            this.title = '全部';
        } else {
            this.postTypeList.forEach((e) => {
                if (e.id == id) {
                    this.title = e.name;
                }
            });
        }
        this.loadData(id);
    }
    handleOpen(key, keyPath) {
        console.log(key, keyPath);
    }
    handleClose(key, keyPath) {
        console.log(key, keyPath);
    }
}
