


































































import { Vue, Component } from 'vue-property-decorator';
import { formRules } from '@/common/util/validator';
import ImageNumUpload from '@/components/ImageNumUpload.vue';
import { Order, Evaluate } from '@/http';

interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        ImageNumUpload,
    },
})
export default class EvaluatPanel extends Vue {
    public id = '';
    public formRules = formRules;
    public shopId: string = '';
    public total: number = 30;
    public rePagin: boolean = false;
    public rateTexts: any = ['非常差', '差', '一般', '好', '非常好'];
    public imgList: any = [];
    public orderList: any = [];
    public colors: any = ['#e02020', '#e02020', '#e02020'];
    public serviceAttitude: number = 0;
    public tradingExperience: number = 0;
    public logisticsServices: number = 0;

    public userType;
    public apply: any = {
        displayImageAttachmentList: [],
    };
    evaluatInfo: any = {
        evaluatContent: '',
    };
    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };
    public $refs: {
        pagination: HTMLFormElement;
    };
    public created() {
        this.shopId = '2';
        this.init();
    }

    batchAddEvaluateList = [];

    public orderDetail: any = {};
    shopServiceAttitude = 5;
    shopTradingExperience = 5;
    shopLogisticsServices = 5;
    setData(data) {
        this.id = data;
        Order.OrderDetail(this.id).then((res) => {
            this.orderDetail = res;
            let list = [];
            res.orderChildList.map((v) => {
                list.push({
                    orderChildId: v.id,
                    orderCode: res.orderCode,
                    coverImageAttachment: v.coverImageAttachment,
                    name: v.name,
                    orderId: this.id,
                    goodsId: v.goodsId,
                    goodsProductQuality: 0,
                    rateTitle: '非常差',
                    shopId: v.shopId,
                    shopName: v.shopName,
                    shopServiceAttitude: this.shopServiceAttitude,
                    shopTradingExperience: this.shopTradingExperience,
                    shopLogisticsServices: this.shopLogisticsServices,
                    content: '',
                    data: [],
                });
            });

            this.batchAddEvaluateList = list;
        });
    }
    public mounted() {
        this.userType = sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : '';
    }
    public init() {}
    setAllImage(img, name, itemId, index) {
        this.batchAddEvaluateList[index].data.length = 0;
        if (img.length > 0) {
            img.forEach((item) => {
                this.batchAddEvaluateList[index].data.push({
                    path: item.path,
                });
            });
        }
        console.log('this.batchAddEvaluateList' + JSON.stringify(this.batchAddEvaluateList));
    }

    async submitRefunded() {
        let data = this.batchAddEvaluateList.map((v) => {
            return {
                creatorUserName: this.userType == 1 ? sessionStorage.getItem('userName') : sessionStorage.getItem('enterpriseName'),
                orderChildId: v.orderChildId,
                goodsName: v.name,
                orderCode: v.orderCode,
                orderId: v.orderId,
                goodsId: v.goodsId,
                goodsProductQuality: v.goodsProductQuality,
                shopId: v.shopId,
                shopName: v.shopName,
                shopServiceAttitude: this.shopServiceAttitude,
                shopTradingExperience: this.shopTradingExperience,
                shopLogisticsServices: this.shopLogisticsServices,
                content: v.content,
                evaluateAttachment: v.data.map((item) => {
                    return {
                        name: '',
                        path: item.path,
                        tag: '',
                        id: '',
                    };
                }),
            };
        });
        await Evaluate.BatchAddEvaluate({ evaluateList: data });
        this.$message.success('评价成功!');
        setTimeout(() => {
            this.$router.back();
        }, 1000);
    }
}
