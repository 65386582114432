


















import { Vue, Component, Watch } from 'vue-property-decorator';
import Bus from '@/common/util/bus';
import store from '@/vuex/index';
@Component({
    components: {},
})
export default class SideBar extends Vue {
    public collapsed: boolean = false;
    public admin: boolean = false;
    public userName: string = '';
    public enterpriseLogoImgUrl: string = '';
    public activeIndex: string = '';

    public mounted() {
        // this.$store.dispatch('rongCloud/getNumber');
        if (this.$route.path == '/') {
            this.activeIndex = '1';
        } else if (this.$route.path == '/brand') {
            this.activeIndex = '3';
        } else if (this.$route.path == '/own') {
            this.activeIndex = '4';
        } else if (this.$route.path == '/preferential') {
            this.activeIndex = '5';
        } else if (this.$route.path == '/interaction') {
            this.activeIndex = '6';
        } else if (this.$route.path == '/union') {
            this.activeIndex = '7';
        } else {
            this.activeIndex = '';
        }
    }
    @Watch('$route')
    public changeRoute(to, from) {
        if (this.$route.path == '/') {
            this.activeIndex = '1';
        } else if (this.$route.path == '/brand') {
            this.activeIndex = '3';
        } else if (this.$route.path == '/own') {
            this.activeIndex = '4';
        } else if (this.$route.path == '/preferential') {
            this.activeIndex = '5';
        } else if (this.$route.path == '/interaction') {
            this.activeIndex = '6';
        } else if (this.$route.path == '/union') {
            this.activeIndex = '7';
        } else {
            this.activeIndex = '';
        }
    }
    tiaozhuan(item) {
        this.$router.push(
            { path: item.path },
            (onComplete) => {},
            (onAbort) => {}
        );
        this.activeIndex = item.index;
    }
    get activeMenu() {
        const route = this.$route;
        const { meta, path } = route;
        if (meta.activeMenu) {
            return meta.activeMenu;
        }
        return path;
    }
    get sycount() {
        return this.$store.state.rongCloud.causeAuditCount;
    }
    get ylcount() {
        return this.$store.state.rongCloud.advanceAuditCount;
    }
    get bxcount() {
        return this.$store.state.rongCloud.reimburseAuditCount;
    }
    get apcount() {
        return this.$store.state.rongCloud.advanceToPayCount;
    }
    clear() {
        sessionStorage.clear();
    }
    collapsedfun() {
        this.collapsed = !this.collapsed;
    }
}
