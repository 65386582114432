
































import { Vue, Component } from 'vue-property-decorator';
import { Activity, AccessRecord } from '@/http';
declare var returnCitySN: any;
@Component
export default class activityDetail extends Vue {
    public item: any = {
        img: '@/assets/img/avatar.png',
        title: '标题',
        author: '[聚天网]',
        time: '2021-04-13',
    };
    public data = {} as any;

    public activityIntroduce: string = '';
    created() {}

    mounted() {
        if (this.$route.query.id) {
            this.getActivityDetail(this.$route.query.id);
        }
    }

    public async getActivityDetail(id) {
        this.data = await Activity.GetActivity(id);
        this.activityIntroduce = this.data.content.replace(/\.webp/g, '.jpg');
        AccessRecord.AddAccessRecord({
            webHost: returnCitySN['cip'],
            weChatOpenId: null,
            dataId: id,
            accessPage: 'Activity',
            creatorUserName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : '',
        });
    }
    videoErrorCallback(e) {
        console.log(e);
    }
}
