

const app = {
    namespaced:true,
    state: {
        // lang: '中文',
        // isFullScreen: false,
      
    },
    mutations: {
        
    }
};

export default app;
