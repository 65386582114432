/*
 * @Autor: dingxiaolin
 * @Date: 2021-01-05 14:03:07
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-02-03 22:40:52
 */
var OSS = require('ali-oss');
import Bus from './bus';
/**声明 */
var client = new OSS.Wrapper({
    /* sino */
    region: 'oss-cn-hangzhou',
    secure: true,
    accessKeyId: 'LTAI5tR38bhe9JRJTCVx2THa',
    accessKeySecret: 'OQvyipb5OTm9dCYA1WCHcHrkUnSqb1',
    bucket: 'jutian-bucket'
});
/**重命名 */
var getUuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        })
        .toUpperCase();
};
/**上传进度 */
var progress = function (p) {
    return function (done) {
        Bus.$emit('progress', Math.round(p * 100));
        done();
    };
};
/**上传图片**/
var upload = async function (file, name) {
    let filetype = name.substring(name.lastIndexOf('.') + 1);
    var storeAs = 'fms/' + getUuid() + '.' + filetype;
    try {
        return await client.multipartUpload(storeAs, file, {
            progress: progress
        });
    } catch (error) {
        console.log(error);
    }
};
/**上传图片流**/
var uploadStream = function (urlData, cb) {
    function convertBase64UrlToBlob(urlData) {
        var bytes = window.atob(urlData.split(',')[1]); //去掉url的头，并转换为byte
        //处理异常,将ascii码小于0的转换为大于0
        var ab = new ArrayBuffer(bytes.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i);
        }

        return new Blob([ab], { type: 'image/png' });
    }
    var blob = convertBase64UrlToBlob(urlData);
    if (blob.size > 0) {
        // blob转arrayBuffer
        var reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        reader.onload = e => {
            // 文件名
            var storeAs = 'avatar/' + getUuid() + '.' + blob.type.split('/')[1];
            // arrayBuffer转Buffer
            var buffer = new OSS.Buffer(e.target.result);
            // 上传
            client
                .put(storeAs, buffer)
                .then(result => {
                    cb(result);
                })
                .catch(err => {
                    console.log(err);
                });
        };
    }
};
export { upload, uploadStream };
