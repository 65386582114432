


































import { Vue, Component } from 'vue-property-decorator';
import { formRules } from '@/common/util/validator';
import { PaginationPanel } from '@/components';
import { PostBar } from '@/http';
export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}

@Component({
    components: {
        PaginationPanel,
    },
})
export default class publish extends Vue {
    public formRules = formRules;
    public evaluateList: any[] = [];
    public rePagin: boolean = false;
    public total: number = 0;
    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };
    mounted() {
        this.init();
    }
    async init() {
        let res = await PostBar.GetMyPostList({
            userId: sessionStorage.getItem('id'),
            skip: this.searchPagin.skip,
            count: this.searchPagin.count,
        });
        res.data.forEach((item) => {
            if (item.coverImagePath) {
                item.coverImagePath = item.coverImagePath.split(',');
            }
        });
        this.evaluateList = res.data;
    }
    public async onPaginationChange(pagin: Pagination) {
        this.searchPagin = pagin;
        this.init();
    }
    reissue(id) {
        this.$router.push({
            path: '/release',
            query: {
                id: id,
            },
        });
    }
    toDetail(id) {
        this.$router.push({
            path: '/interactionDetail',
            query: {
                id: id,
                name: 'fabu',
            },
        });
    }
}
