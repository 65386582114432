













import { Vue, Component } from 'vue-property-decorator';
@Component({
    components: {},
})
export default class BlankContailerPanel extends Vue {
    public created() {}
    public mounted() {}
}
