












































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import CommonPanel from './components/CommonPanel.vue';
import { fmoney } from '@/common/util/money';
import { NavBar } from '../home/components';
import { PreferentialActivity } from '@/http';
export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        CommonPanel,
        NavBar,
    },
})
export default class preferential extends Vue {
    public specialOfferList: any[] = [];
    public shopTogetherList: any[] = [];
    public newProducList: any[] = [];
    public userType: string = '1';
    // 特价甩货更多
    specialOfferMore() {
        this.$router.push({
            path: '/specialOff',
        });
    }
    // 限时拼购更多
    shopTogetherMore() {
        this.$router.push({
            path: '/shopTogether',
        });
    }
    // 新品试用更多
    newProducMore() {
        this.$router.push({
            path: '/newProduc',
        });
    }
    jumpDetail(v, item) {
        this.$router.push({
            path: '/preferentialGoodsDetail',
            query: {
                name: v,
                id: item.goodsId,
                preferentialActivityId: item.preferentialActivityId,
            },
        });
    }
    mounted() {
        this.init();
    }
    async init() {
        this.userType = sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : '1';
        PreferentialActivity.GetPaNewGoodsList({
            goodsTypeOneId: '',
            goodsName: '',
            activityStatus: '3,4',
            usePlatform: '1',
            useUserType: sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : null,
            orderby: '',
            ASC: '',
            skip: 0,
            count: 5,
        }).then((res) => {
            this.newProducList = res.data;
        });
        PreferentialActivity.GetPaSpecialPriceList({
            goodsTypeOneId: '',
            goodsName: '',
            activityStatus: '3,4',
            usePlatform: '1',
            useUserType: sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : null,
            orderby: '',
            ASC: true,
            skip: 0,
            count: 10,
        }).then((res) => {
            this.specialOfferList = res.data;
        });
        PreferentialActivity.GetPaShopTogetherList({
            goodsTypeOneId: '',
            goodsName: '',
            activityStatus: '3,4',
            usePlatform: '1',
            useUserType: sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : null,
            orderby: '',
            ASC: '',
            skip: 0,
            count: 6,
        }).then((res) => {
            this.shopTogetherList = res.data;
        });
    }
}
