























import { Vue, Component, Watch } from 'vue-property-decorator';
import { PaginationPanel } from '@/components';
import { fmoney } from '@/common/util/money';
import { NavBar } from '../home/components';
import { enums } from '@/common/enums';
import { GoodsType, Shop } from '@/http';
import { Goods } from '@/http';

export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        NavBar,
        PaginationPanel,
    },
})
export default class shop extends Vue {
    mounted() {
        this.GetShopQualificationList();
    }

    qualificationList: any = {};
    //获取企业资质
    public async GetShopQualificationList() {
        let list = await Shop.GetShopQualificationList({ id: this.$route.query.id });
        this.qualificationList = list.data;
    }

    toShop() {
        this.$router.back();
    }

    toHome() {
        this.$router.push('/');
    }
}
