






















import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { upload } from '@/common/util/ossUploadService';

@Component({
    components: {},
})
export default class ImageNumUpload4 extends Vue {
    @Prop()
    public data;
    @Prop()
    public num: number;
    @Prop()
    public name: string;
    @Prop()
    public itemId: string;
    @Prop()
    public itemIndex: string;

    mounted() {
        if (this.data.length > 0 && this.data.length == this.num) {
            this.allSrc = this.data;
            // this.isShow = true;
        }
    }
    public $refs: {
        imageUploadInput: HTMLFormElement;
    };
    // public isShow = false;
    public file;
    public src;
    public allSrc: any[] = [];
    public imgName = '';
    public async changeFile(e) {
        console.log(e.target.files.length);
        // console.log(e.target.files[1])
        // console.log(e.target.files[1])
        if (e.target.files.length > 1) {
            if (this.allSrc.length + e.target.files.length > 4) {
                this.$message.error('最多只能上传4张');
                return;
            }
            e.target.files.forEach(async (ele) => {
                console.log(ele);
                this.imgName = ele.name;
                let imgType = this.imgName.split('.').pop();
                let allowType = ['jpg', 'jpeg', 'png', 'gif', 'tiff'];
                this.file = ele;
                this.src = URL.createObjectURL(ele);
                if (allowType.indexOf(imgType.toLowerCase()) === -1) {
                    this.$alert('不支持该文件类型').then((e) => {
                        this.src = null;
                        this.$refs.imageUploadInput.value = null;
                    });
                    return;
                }
                if (ele.size > 5 * 1024 * 1024) {
                    this.$alert('文件大小不能超过5M').then((e) => {
                        this.src = null;
                        this.$refs.imageUploadInput.value = null;
                    });
                    return;
                }
                await this.upload();
            });
        } else {
            if (e.target.files[0]) {
                this.imgName = e.target.files[0].name;
                let imgType = this.imgName.split('.').pop();
                let allowType = ['jpg', 'jpeg', 'png', 'gif', 'tiff'];
                this.file = e.target.files[0];
                this.src = URL.createObjectURL(e.target.files[0]);
                if (allowType.indexOf(imgType.toLowerCase()) === -1) {
                    this.$alert('不支持该文件类型').then((e) => {
                        this.src = null;
                        this.$refs.imageUploadInput.value = null;
                    });
                    return;
                }
                if (e.target.files[0].size > 5 * 1024 * 1024) {
                    this.$alert('文件大小不能超过5M').then((e) => {
                        this.src = null;
                        this.$refs.imageUploadInput.value = null;
                    });
                    return;
                }
                this.upload();
            }
        }

        e.target.value = '';
    }

    public deleteSrc(i) {
        this.src = null;
        this.allSrc.splice(i, 1);
        this.$emit('setAllImage', this.allSrc, this.name, this.itemId, this.itemIndex);
        // this.isShow = false;
    }
    public async upload() {
        const res = await upload(this.file, this.imgName);
        if (res.res.statusCode == '200') {
            let url = res.res.requestUrls[0].split('?')[0];
            this.allSrc.push({
                path: url,
                name: this.imgName,
            });
            this.$emit('setAllImage', this.allSrc, this.name, this.itemId, this.itemIndex);
        } else {
            this.$message.warning('图片上传失败!');
        }
    }
}
