/*eslint-disable */
/*
 * @Autor: dingxiaolin
 * @Date: 2020-12-25 00:39:22
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-01-29 09:37:22
 */
/**
 * 个位数，加0前缀
 * @param {*} number
 * @returns
 */
import dayjs from 'dayjs';
export namespace time {
  export function addZeroPrefix(number) {
    return number < 10 ? `0${number}` : number;
  }
  // function addZeroPrefix (n) { //另类写法
  //     const str = n.toString()
  //     return str[1] ? str : `0${str}`
  // }

  /**
   * AM/PM替换为上午/下午
   * @param {Date} date
   */
  export function formatTime(date) {
    if (isToday(date)) {
      return dayjs(date)
        .format('A HH:mm')
        .replace('PM', '下午')
        .replace('AM', '上午');
    }
    return getDate(date);
  }
  /**
   * 返回年月日 分割符/
   * @param {Date} date
   * @param {String} splitor
   */
  export function getDate(date, splitor = '/') {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}${splitor}${addZeroPrefix(month)}${splitor}${addZeroPrefix(day)}`;
  }
  /**
   * 返回时分秒/时分
   * @export
   * @param {*} date
   * @param {boolean} [withSecond=false]
   * @returns
   */
  export function getTime(date, withSecond = false) {
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    return withSecond ? `${addZeroPrefix(hour)}:${addZeroPrefix(minute)}:${addZeroPrefix(second)}` : `${hour}:${addZeroPrefix(minute)}`;
  }
  /**
   * 年/月/日 时:分:秒
   * @param {DateTime} date
   */
  export function getFullDate(date) {
    return `${getDate(date)} ${getTime(date)}`;
  }
  /**
   * 判断是否是今天
   * @param {Boolean} date
   */
  export function isToday(date) {
    return date.toDateString() === new Date().toDateString();
  }
  /**
   * 总秒数计算为时分秒
   * @param {*} second 秒
   */
  export function formatDuration(second) {
    let interval = second;
    let continued = '';
    if (interval >= 3600) {
      const hour = Math.floor(interval / 3600);
      continued += hour + '小时';
      interval -= hour * 3600;
    }
    if (interval >= 60 && interval < 3600) {
      const min = Math.floor(interval / 60);
      continued += min + '分';
      interval -= min * 60;
    }
    if (interval < 60) {
      continued += Math.floor(interval) + '秒';
    }
    return continued;
  }
}
