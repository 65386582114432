import { render, staticRenderFns } from "./securityCenter.vue?vue&type=template&id=70a0d655&scoped=true"
import script from "./securityCenter.vue?vue&type=script&lang=ts"
export * from "./securityCenter.vue?vue&type=script&lang=ts"
import style0 from "./securityCenter.vue?vue&type=style&index=0&id=70a0d655&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70a0d655",
  null
  
)

export default component.exports