/*
 * @Description:
 * @Autor: jyf
 * @Date: 2021-04-27 16:48:15
 * @LastEditors: jyf
 * @LastEditTime: 2021-04-27 16:49:23
 */
import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace ShopBrand {
    /**
     * 获取店铺品牌列表
     */
    export var GetShopBrandList = (query: any): Promise<any> => {
        return Get(`/api/ShopBrand?${qs.stringify(query)}`, {});
    };
    /**
     * 获取店铺品牌列表2
     */
     export var GetShopBrandList2 = (query: any): Promise<any> => {
        return Get(`/api/ShopBrand/HomeBrandList?${qs.stringify(query)}`, {});
    };
    /**
     * 获取店铺品牌列表/api/ShopBrand/shopBrandListByGoodsType
     */
    export var shopBrandListByGoodsType = (query: any): Promise<any> => {
        return Get(`/api/ShopBrand/shopBrandListByGoodsType?${qs.stringify(query)}`, {});
    };


}
