




















import { Vue, Component } from 'vue-property-decorator';
import { CommonPanel } from '@/components';
import PaginationPanel from '@/components/PaginationPanel.vue';
import { Activity } from '@/http';

export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        PaginationPanel,
    },
})
export default class activityList extends Vue {
    /**活动列表 */
    public activityList: any[] = [];

    public totalData: any[] = [];

    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };

    pingtaiMore() {}

    public total: number = 0;
    public rePagin: boolean = false;

    mounted() {
        this.GetActivityList();
        this.GetActivityListTotal();
    }

    public async onPaginationChange(pagin: Pagination) {
        this.searchPagin = pagin;
        this.GetActivityList();
    }

    public async GetActivityListTotal() {
        let list = await Activity.GetActivityList({ skip: 0, count: -1 });
        this.total = list.data.length;
    }

    /**活动列表 */
    public async GetActivityList() {
        let list = await Activity.GetActivityList({ skip: this.searchPagin.skip, count: this.searchPagin.count });
        this.activityList = list.data;
    }
    toDetail(id) {
        this.$router.push({
            path: `/activity/activityDetail`,
            query: {
                id: id,
            },
        });
    }
}
