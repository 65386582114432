





















import { Vue, Component } from 'vue-property-decorator';
import BlankContailerPanel from '../../components/BlankContailerPanel.vue';
import newProduc from './newProduc.vue';
import { User } from '@/http';
@Component({
    components: {
        BlankContailerPanel,
        newProduc,
    },
})
export default class Order extends Vue {
    public activeName: string = '1';
    public tabList: any = [
        { label: '已申请', name: '1' },
        { label: '已取消', name: '2' },
    ];

    /**当前显示tabs */
    public currentPage: string | number = '1';

    refreshUser() {
        User.GetRefreshUserInfo(sessionStorage.getItem('id')).then((res) => {
            this.successLogin(res);
        });
    }
    successLogin(res) {
        this.enterpriseName = res.enterpriseName;
        this.userName = res.userName;
        this.userType = res.userType;
        this.headPath = res.headPath
            ? res.headPath
            : 'https://sinostoragedev.oss-cn-hangzhou.aliyuncs.com/fms/87852C02-44F5-43AC-A4CB-19678DF0E82C.png';
        this.phoneNumber = res.phoneNumber;
        this.enterpriseJob = res.enterpriseJob;
        this.realName = res.realName;
        sessionStorage.setItem('id', res.id);
        sessionStorage.setItem('headPath', res.headPath);
        sessionStorage.setItem('userName', res.userName);
        sessionStorage.setItem('phoneNumber', res.phoneNumber);
        sessionStorage.setItem('isOpenTencentCloud', res.isOpenTencentCloud);
        sessionStorage.setItem('mainBusiness', res.mainBusiness);
        sessionStorage.setItem('userType', res.userType);
        sessionStorage.setItem('enterpriseCode', res.enterpriseCode);
        sessionStorage.setItem('enterpriseJob', res.enterpriseJob);
        sessionStorage.setItem('enterpriseName', res.enterpriseName);
        sessionStorage.setItem('isEnabled', res.isEnabled);
        sessionStorage.setItem('isFirstLogin', res.isFirstLogin);
        sessionStorage.setItem('userSig', res.userSig);
        console.log('realName' + this.realName);
    }

    enterpriseName = '';
    userName = '';
    userType = '';
    headPath = '';
    phoneNumber = '';
    enterpriseJob = '';
    realName = '';
    public created() {}
    public mounted() {
        this.refreshUser();
    }
    public async handleClick(tab, event) {
        this.$nextTick(async () => {
            // await this.$refs['order_' + tab.index][0].clear();
            this.currentPage = Number(tab.index) + 1;
            this.$refs['order_' + tab.index][0].rePagin = true;
            await this.$refs['order_' + tab.index][0].setCurrentPage(this.currentPage);
            await this.$refs['order_' + tab.index][0].init();
            this.$refs['order_' + tab.index][0].rePagin = false;
        });
    }
    public async onpaginChange(type) {
        await this.$refs['order_' + (Number(this.currentPage) - 1)][0].turnPage(type);
    }
}
