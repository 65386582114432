






















import { Vue, Component } from 'vue-property-decorator';
import { CommonPanel } from '@/components';
import { Activity } from '@/http';
@Component
export default class activityVideo extends Vue {
    /**活动列表 */
    public activityList: any[] = [];

    pingtaiMore() {}

    mounted() {
        this.GetActivityList();
    }

    /**活动列表 */
    public async GetActivityList() {
        let list = await Activity.GetActivityList({ skip: 0, count: -1 });
        this.activityList = list.data;
    }
    toDetail(id) {
        this.$router.push({
            path: `/activity/activityDetail`,
            query: {
                id: id,
            },
        });
    }
}
