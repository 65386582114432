import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace Cart {
    /**
     * 批量新增购物车
     */
    export var addBatchAddCart = (body: any): Promise<any> => {
      return Post(`/api/Cart/BatchAddCart`, body);
    };
    /**
     * 获取购物车列表
     */
    export var getCart = (query: any): Promise<any> => {
      return Get(`/api/Cart?${qs.stringify(query)}`, '');
    };
    /**
     * 删除购物车记录
     */
    export var deleteCart = (body: any): Promise<any> => {
      return Put(`/api/Cart/Delete`, body);
    };
}
