































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { PaginationPanel } from '@/components';
import { Cart } from '@/http';
interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        PaginationPanel,
    },
})
export default class OrderPanelCart extends Vue {
    @Prop({
        default: '1',
    })
    public type: string;
    @Prop({
        default: '',
    })
    public current: string;
    public checkAll: boolean = false;
    public isIndeterminate: boolean = true;
    public checkedCities: any = [];
    public total: number = 30;
    public rePagin: boolean = false;
    public orderList: any = [];
    public userType: string = '';
    public number: number = 0;
    public ids: string[] = [];
    public subtotals: any[] = [];
    public priceTotal: string = '';
    public settleDisabled: boolean = true;
    public allChecked: boolean = false;
    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };
    public $refs: {
        pagination: HTMLFormElement;
    };
    jumpDetail(id) {
        this.$router.push({
            path: '/goodsDetail',
            query: {
                id: id,
            },
        });
    }
    getSubtotal(item) {
        let num = 0;
        if (sessionStorage.getItem('userType') == '1') {
            num = item.personalFixSalePrice * item.number;
        } else if (sessionStorage.getItem('userType') == '2' || sessionStorage.getItem('userType') == '3') {
            num = item.enterpriseFixSalePrice * item.number;
        }
        return num == 0 ? '——' : '￥' + num.toFixed(2);
    }
    async onPaginationChange(pagin: Pagination) {
        this.searchPagin = pagin;
    }
    @Watch('orderList')
    changeOrderList(v) {
        let i = 0;
        v.forEach((e) => {
            if (!e.mychecked) {
                i++;
            }
        });
        if (i == 0) {
            this.allChecked = true;
        } else {
            this.allChecked = false;
        }
    }
    firstChanged(index) {
        let tempData = this.orderList[index];
        if (this.orderList[index].mychecked === false) {
            tempData.cartList.forEach((item) => {
                if (item.status == 3) {
                    this.$set(item, 'mychecked', false);
                }
            });
        } else {
            tempData.cartList.forEach((item) => {
                if (item.status == 3) {
                    this.$set(item, 'mychecked', true);
                }
                if (item.isReselectSpec) {
                    this.$set(item, 'mychecked', false);
                }
            });
        }
        let i = 0;
        this.orderList.forEach((e) => {
            if (!e.mychecked) {
                i++;
            }
        });
        if (i == 0) {
            this.allChecked = true;
        } else {
            this.allChecked = false;
        }
        this.chooseList();
    }
    secondChanged(index, subItem) {
        const subData = this.orderList[index].cartList;
        let tickCount = 0;
        let arr = [];
        subData.forEach((e) => {
            if (e.status == 3) {
                arr.push(e);
            }
        });
        const len = arr.length;
        for (let i = 0; i < len; i += 1) {
            if (arr[i].status == 3) {
                if (arr[i].mychecked === true) {
                    tickCount += 1;
                } else {
                    tickCount -= 1;
                }
            }
        }
        if (tickCount === len) {
            // 二级全勾选  一级勾选
            this.$set(this.orderList[index], 'mychecked', true);
        } else {
            // 二级未全选  一级不勾选
            this.$set(this.orderList[index], 'mychecked', false);
        }
        let i = 0;
        this.orderList.forEach((e) => {
            if (!e.mychecked) {
                i++;
            }
        });
        if (i == 0) {
            this.allChecked = true;
        } else {
            this.allChecked = false;
        }
        this.chooseList();
    }
    chooseList() {
        this.number = 0;
        this.ids = [];
        this.subtotals = [];
        let pricetotals = [];
        console.log(this.orderList);
        this.orderList.forEach((e, i) => {
            this.subtotals.push({
                platform: '1',
                receiveAddressId: '',
                receiver: '',
                phoneNumber: '',
                province: '',
                city: '',
                county: '',
                detail: '',
                address: '',
                remark: '',
                shopId: e.shopId,
                shopName: e.shopName,
                payMethod: '',
                orderChildList: [],
            });
            e.cartList.forEach((ele, index) => {
                if (ele.mychecked) {
                    this.number += ele.number;
                    this.ids.push(ele.id);
                    if (this.userType == '1') {
                        this.subtotals[i].orderChildList.push({
                            cartId: ele.id,
                            brand: ele.brand,
                            merchantType: e.merchantType,
                            goodsId: ele.goodsId,
                            goodsNumber: ele.goodsNumber,
                            name: ele.goodsName,
                            deliveryAddress: ele.deliveryAddress,
                            weight: ele.goodsSpecification == null ? ele.weight : ele.goodsSpecification.weight,
                            personalFixSalePrice: ele.personalFixSalePrice,
                            personalSectionSalePriceOne: ele.personalSectionSalePriceOne,
                            personalSectionSalePriceTwo: ele.personalSectionSalePriceTwo,
                            enterpriseFixSalePrice: ele.enterpriseFixSalePrice,
                            enterpriseSectionSalePriceOne: ele.enterpriseSectionSalePriceOne,
                            enterpriseSectionSalePriceTwo: ele.enterpriseSectionSalePriceTwo,
                            specId: ele.goodsSpecification ? ele.goodsSpecification.id : '',
                            specName: ele.goodsSpecification ? ele.goodsSpecification.name : '',
                            goodsQuantity: ele.number,
                            coverImageAttachment: ele.coverImageAttachmentList[0],
                            mainBusiness: ele.mainBusiness,
                        });
                        pricetotals.push({
                            price: ele.personalFixSalePrice,
                            goodsQuantity: ele.number,
                        });
                    } else {
                        this.subtotals[i].orderChildList.push({
                            cartId: ele.id,
                            brand: ele.brand,
                            merchantType: e.merchantType,
                            goodsId: ele.goodsId,
                            goodsNumber: ele.goodsNumber,
                            name: ele.goodsName,
                            deliveryAddress: ele.deliveryAddress,
                            weight: ele.goodsSpecification == null ? ele.weight : ele.goodsSpecification.weight,
                            personalFixSalePrice: ele.personalFixSalePrice,
                            personalSectionSalePriceOne: ele.personalSectionSalePriceOne,
                            personalSectionSalePriceTwo: ele.personalSectionSalePriceTwo,
                            enterpriseFixSalePrice: ele.enterpriseFixSalePrice,
                            enterpriseSectionSalePriceOne: ele.enterpriseSectionSalePriceOne,
                            enterpriseSectionSalePriceTwo: ele.enterpriseSectionSalePriceTwo,
                            specId: ele.goodsSpecification ? ele.goodsSpecification.id : '',
                            specName: ele.goodsSpecification ? ele.goodsSpecification.name : '',
                            goodsQuantity: ele.number,
                            coverImageAttachment: ele.coverImageAttachmentList[0],
                            mainBusiness: ele.mainBusiness,
                        });
                        pricetotals.push({
                            price: ele.enterpriseFixSalePrice,
                            goodsQuantity: ele.number,
                        });
                    }
                }
            });
        });
        let i = 0;
        let num = 0;
        console.log('pricetotals' + JSON.stringify(pricetotals));
        if (pricetotals.length > 0) {
            pricetotals.forEach((e) => {
                if (e.price == null || e.price == 0) {
                    i++;
                } else {
                    num += (e.goodsQuantity * 10000 * e.price) / 10000;
                }
            });
        }
        this.priceTotal = i > 0 ? '——' : num.toFixed(2).toString();
    }
    @Watch('subtotals')
    public changeSubtotals(v) {
        let i = 0;
        v.forEach((e) => {
            if (e.orderChildList.length > 0) {
                i++;
            }
        });
        if (i == 0) {
            this.settleDisabled = true;
        } else {
            this.settleDisabled = false;
        }
    }
    deleteCart(id) {
        this.settleDisabled = true;
        Cart.deleteCart({ ids: id }).then((res) => {
            this.$message.success('删除成功');
            this.init();
        });
    }
    // 总的全选
    handleSelectAllClassfiy(val) {
        if (val) {
            for (let i = 0; i < this.orderList.length; i += 1) {
                this.orderList[i].mychecked = true;
                this.firstChanged(i); // 调用一级change事件
            }
        } else {
            for (let i = 0; i < this.orderList.length; i += 1) {
                this.orderList[i].mychecked = false;
                this.firstChanged(i); // 调用一级change事件
            }
        }
    }
    // allChecked() {
    //     let count = 0;
    //     for (let i = 0; i < this.orderList.length; i += 1) {
    //         if (this.orderList[i].mychecked === true) {
    //             count += 1;
    //         } else {
    //             count -= 1;
    //             if (count < 0) {
    //                 count = 0;
    //             }
    //         }
    //     }
    //     if (count === this.orderList.length) {
    //         return true;
    //     }
    //     return false;
    // }
    // set allChecked(val) {}
    turnPage(type) {
        if (type === 1) {
            this.$refs.pagination.handleCurrentChange(this.searchPagin.currentPage - 1);
        } else {
            this.$refs.pagination.handleCurrentChange(this.searchPagin.currentPage + 1);
        }
    }
    mounted() {
        if (sessionStorage.getItem('id')) {
            this.init();
        }
    }
    handleCheckAllChange(val) {
        this.checkedCities = val ? this.orderList.data : [];
        this.isIndeterminate = false;
    }
    async init() {
        let res = await Cart.getCart({
            skip: 0,
            count: -1,
        });
        this.orderList = res.data;
        this.userType = sessionStorage.getItem('userType');
        this.total = res.data.length;
        this.settleDisabled = true;
        this.number = 0;
        this.ids = [];
        this.chooseList();
    }
    handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.orderList.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.orderList.length;
    }
    getOrderDetails() {
        this.$router.push({
            path: '/orderDetail/detail',
        });
    }
    settlement() {
        let subtotals = [];
        this.subtotals.forEach((e) => {
            if (e.orderChildList.length != 0) {
                subtotals.push({
                    address: e.address,
                    city: e.city,
                    county: e.county,
                    detail: e.detail,
                    orderChildList: e.orderChildList,
                    payMethod: e.payMethod,
                    phoneNumber: e.phoneNumber,
                    platform: e.platform,
                    province: e.province,
                    receiveAddressId: e.receiveAddressId,
                    receiver: e.receiver,
                    remark: e.remark,
                    shopId: e.shopId,
                    shopName: e.shopName,
                });
            }
        });
        if (sessionStorage.getItem('id')) {
            sessionStorage.setItem('goodsList', JSON.stringify(subtotals));
            this.$router.push({
                path: '/submitOrder',
                query: {
                    type: 'cart',
                },
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
}
