























































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { formRules } from '@/common/util/validator';
import headerPanel from './component/headerPanel.vue';
import footerBar from './component/footerBar.vue';
import { AddressSelect, Message } from '@/components';
import { Cart, Order, ReceiverInfo, PreferentialActivity } from '@/http';

@Component({
    components: {
        headerPanel,
        footerBar,
        AddressSelect,
        Message,
    },
})
export default class preferentialInfo extends Vue {
    public formRules = formRules;
    public orderSuccess: boolean = false;
    public address: any[] = [];
    public goodsListAddress: string = '';
    public addreses: any[] = [];
    public isShow: number = null;
    public isShowAllAddress: boolean = true;
    public remarks: string = '';
    public goodsList: any[] = [];
    public userType: string = '';
    public subtotal: number = null;
    public dialogVisible: boolean = false;
    public payMethodDialogVisible: boolean = false;
    public isSubmitOrder: boolean = false;
    public payableAmount: string = '';
    public showName: string = '';
    public form: any = {
        receiver: '',
        phoneNumber: '',
        province: '',
        city: '',
        county: '',
        detail: '',
        address: '',
        isDefault: false,
    };
    public payMethodForm: any = {
        payMethod: '',
    };
    public area: string = '';
    public isOpenTencentCloud = false;
    public $refs: {
        form: HTMLFormElement;
        payMethodForm: HTMLFormElement;
    };
    mounted() {
        this.isOpenTencentCloud = sessionStorage.getItem('isOpenTencentCloud') == 'true' ? true : false;
        this.init();
    }
    async init() {
        if (sessionStorage.getItem('id')) {
            this.goodsList = JSON.parse(sessionStorage.getItem('goodsList'));
            this.userType = sessionStorage.getItem('userType');
            this.initAddress();
        }
    }
    lianxi(item) {
        if (sessionStorage.getItem('id')) {
            this.$root.$emit('lianxi', item.shopId);
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    async initAddress() {
        let res = await ReceiverInfo.getReceiverInfo({
            skip: 0,
            count: -1,
        });
        if (this.isShowAllAddress) {
            this.address = res.data.slice(0, 4);
        } else {
            this.address = res.data;
        }
        this.addreses = res.data;
        this.addreses.forEach((e, i) => {
            if (e.isDefault) {
                this.isShow = i;
                this.goodsList.forEach((ele, index) => {
                    this.goodsList[index].address = e.address;
                    this.goodsListAddress = e.address + e.detail;
                    this.goodsList[index].province = e.province;
                    this.goodsList[index].city = e.city;
                    this.goodsList[index].county = e.county;
                    this.goodsList[index].detail = e.detail;
                    this.goodsList[index].phoneNumber = e.phoneNumber;
                    this.goodsList[index].receiver = e.receiver;
                    this.goodsList[index].receiveAddressId = e.id;
                });
            }
        });
        let num = 0;
        let i = 0;
        if (sessionStorage.getItem('userType') == '1') {
            this.goodsList.forEach((e) => {
                if (e.personalFixSalePrice != null) {
                    num += (e.personalFixSalePrice * 10000 * e.goodsQuantity) / 10000;
                } else {
                    i++;
                }
            });
        } else if (sessionStorage.getItem('userType') == '2' || sessionStorage.getItem('userType') == '3') {
            this.goodsList.forEach((e) => {
                if (e.enterpriseFixSalePrice != null) {
                    num += (e.enterpriseFixSalePrice * 10000 * e.goodsQuantity) / 10000;
                } else {
                    i++;
                }
            });
        }
        this.payableAmount = num == 0 || i > 0 ? '——' : num.toString();
    }
    chooseAddress(item, i) {
        this.isShow = i;
        this.goodsList.forEach((ele, index) => {
            this.goodsList[index].address = item.address;
            this.goodsListAddress = item.address + item.detail;
            this.goodsList[index].province = item.province;
            this.goodsList[index].city = item.city;
            this.goodsList[index].county = item.county;
            this.goodsList[index].detail = item.detail;
            this.goodsList[index].phoneNumber = item.phoneNumber;
            this.goodsList[index].receiver = item.receiver;
            this.goodsList[index].receiveAddressId = item.id;
        });
    }
    addAddress() {
        this.dialogVisible = true;
    }
    showAllAddress() {
        if (this.isShowAllAddress) {
            this.address = this.addreses;
        } else {
            this.address = this.addreses.slice(0, 4);
        }
        this.isShowAllAddress = !this.isShowAllAddress;
    }
    manageAddress() {
        this.$router.push({
            path: '/personal/receivingAddress',
        });
    }
    getNum(item) {
        let num = 0;
        item.orderChildList.forEach((e) => {
            num += e.goodsQuantity;
        });
        return num;
    }
    getSubtotal(item) {
        let num = 0;
        let i = 0;
        if (sessionStorage.getItem('userType') == '1') {
            item.orderChildList.forEach((e) => {
                if (e.personalFixSalePrice != null) {
                    num += (e.personalFixSalePrice * 10000 * e.goodsQuantity) / 10000;
                }
                if (e.personalFixSalePrice == null) {
                    i++;
                }
            });
        } else if (sessionStorage.getItem('userType') == '2' || sessionStorage.getItem('userType') == '3') {
            item.orderChildList.forEach((e) => {
                if (e.enterpriseFixSalePrice != null) {
                    num += (e.enterpriseFixSalePrice * 10000 * e.goodsQuantity) / 10000;
                }
                if (e.enterpriseFixSalePrice == null) {
                    i++;
                }
            });
        }
        return num == 0 || i > 0 ? '——' : num;
    }
    //---地址选择start---
    public selectReset: boolean = true;
    public async updateReset() {
        this.selectReset = true;
    }

    public async getProvince(item, name) {
        if (item == '') {
            this.selectAddress.province.code = '';
            this.selectAddress.city.code = '';
            this.selectAddress.area.code = '';
        }
        this.selectAddress.province.code = item;
        this.selectAddress.province.text = name;
    }

    public async getCityCode(item, name) {
        this.selectAddress.city.code = item;
        this.selectAddress.city.text = name;
    }

    public async getAreaCode(item, name) {
        this.selectAddress.area.code = item;
        this.selectAddress.area.text = name;
        this.area = item;
    }

    public selectAddress = {
        province: {
            code: '',
            text: '',
        },
        city: {
            code: '',
            text: '',
        },
        area: {
            code: '',
            text: '',
        },
    };
    //---地址选择--end---
    handleClose() {
        this.form = {
            receiver: '',
            phoneNumber: '',
            province: '',
            city: '',
            county: '',
            detail: '',
            address: '',
            isDefault: false,
        };
        this.selectAddress = {
            province: {
                code: '',
                text: '',
            },
            city: {
                code: '',
                text: '',
            },
            area: {
                code: '',
                text: '',
            },
        };
        this.$nextTick(() => {
            this.$refs.form.resetFields();
        });
        this.dialogVisible = false;
    }
    closePayMethod() {
        this.$nextTick(() => {
            this.$refs.payMethodForm.resetFields();
        });
        this.payMethodDialogVisible = false;
    }
    submitOrder(v) {
        this.payMethodDialogVisible = true;
        this.isSubmitOrder = v;
    }
    settle(v) {
        this.payMethodDialogVisible = true;
        this.isSubmitOrder = v;
    }
    submitApply() {
        if (this.$route.query.name == 'shopTogether') {
            let obj = {
                preferentialActivityId: this.goodsList[0].preferentialActivityId,
                shopId: this.goodsList[0].shopId,
                goodsId: this.goodsList[0].goodsId,
                goodsName: this.goodsList[0].goodsName,
                goodsNumber: this.goodsList[0].goodsNumber,
                specId: this.goodsList[0].specId,
                spec: this.goodsList[0].spec,
                receiveAddressId: this.goodsList[0].receiveAddressId,
                receiver: this.goodsList[0].receiver,
                phoneNumber: this.goodsList[0].phoneNumber,
                province: this.goodsList[0].province,
                city: this.goodsList[0].city,
                county: this.goodsList[0].county,
                detail: this.goodsList[0].detail,
                address: this.goodsList[0].address,
                totalQuantity: this.goodsList[0].totalQuantity,
                price: this.goodsList[0].price,
                totalAmount: this.goodsList[0].totalAmount,
            };
            const loading = this.$loading({
                lock: true,
                text: '提交中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            });
            PreferentialActivity.AddShopTogetherApplyRecord({ ...obj })
                .then((res) => {
                    this.showName = '我的拼购';
                    this.orderSuccess = true;
                    loading.close();
                })
                .catch((err) => {
                    loading.close();
                });
        } else {
            let obj = {
                preferentialActivityId: this.goodsList[0].preferentialActivityId,
                shopId: this.goodsList[0].shopId,
                goodsId: this.goodsList[0].goodsId,
                goodsName: this.goodsList[0].goodsName,
                goodsNumber: this.goodsList[0].goodsNumber,
                specId: this.goodsList[0].specId,
                spec: this.goodsList[0].spec,
                receiveAddressId: this.goodsList[0].receiveAddressId,
                receiver: this.goodsList[0].receiver,
                phoneNumber: this.goodsList[0].phoneNumber,
                province: this.goodsList[0].province,
                city: this.goodsList[0].city,
                county: this.goodsList[0].county,
                detail: this.goodsList[0].detail,
                address: this.goodsList[0].address,
            };
            const loading = this.$loading({
                lock: true,
                text: '提交中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            });
            PreferentialActivity.AddNewGoodsApplyRecord({ ...obj })
                .then((res) => {
                    this.showName = '我的试用';
                    this.orderSuccess = true;
                    loading.close();
                })
                .catch((err) => {
                    loading.close();
                });
        }
    }
    save() {
        this.form.province = this.selectAddress.province.code;
        this.form.city = this.selectAddress.city.code;
        this.form.county = this.selectAddress.area.code;
        this.form.address = this.selectAddress.province.text + this.selectAddress.city.text + this.selectAddress.area.text;
        this.$refs.form.validate((valid) => {
            if (valid) {
                ReceiverInfo.addReceiverInfo({ ...this.form }).then(() => {
                    this.handleClose();
                    this.init();
                });
            }
        });
    }
    savePayMethod() {
        this.$refs.payMethodForm.validate((valid) => {
            if (valid) {
                this.goodsList.forEach((e) => {
                    e.payMethod = this.payMethodForm.payMethod;
                });
                const loading = this.$loading({
                    lock: true,
                    text: '提交中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                Order.addOrder({ orderList: this.goodsList })
                    .then((res) => {
                        this.payMethodDialogVisible = false;
                        if (this.$route.query.type == 'cart') {
                            let ids = [];
                            this.goodsList.forEach((e) => {
                                e.orderChildList.forEach((ele) => {
                                    ids.push(ele.cartId);
                                });
                            });
                            Cart.deleteCart({ ids: ids }).then((res) => {
                                loading.close();
                            });
                        } else {
                            loading.close();
                        }
                        if (this.payMethodForm.payMethod == '2' || this.isSubmitOrder) {
                            this.$router.push({
                                path: '/orderDetail/detail',
                                query: {
                                    id: res.extData,
                                },
                            });
                        } else if (this.payMethodForm.payMethod == '1') {
                            this.$router.push({
                                path: '/settleCenter',
                                query: {
                                    id: res.extData,
                                    type: '1',
                                },
                            });
                        } else if (this.payMethodForm.payMethod == '3') {
                            this.$router.push({
                                path: '/settleCenter',
                                query: {
                                    id: res.extData,
                                    type: '3',
                                },
                            });
                        } else if (this.payMethodForm.payMethod == '4') {
                            this.$router.push({
                                path: '/settleCenter',
                                query: {
                                    id: res.extData,
                                    type: '4',
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        loading.close();
                    });
            }
        });
    }
    jumpCart() {
        this.$router.push({
            path: '/personal/shoppingCart',
        });
    }
    jumpShouYe() {
        this.$router.push({
            path: '/',
        });
    }
    jumpOrderDetail() {
        let id = '';
        if (this.$route.query.name == 'shopTogether') {
            this.$router.push({
                path: '/personal/shopTogetherPanel',
            });
        } else {
            this.$router.push({
                path: '/personal/trialSaleSalePanel',
            });
        }
    }
}
