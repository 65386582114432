/*
 * @Autor: dingxiaolin
 * @Date: 2021-01-06 16:59:54
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-01-28 11:26:47
 */
import { Public } from '@/http';
const login = {
    namespaced: true,
    state: {
        jwtToken: '',
        actionPermissions: ['1111']
    },
    mutations: {
        logout(state, vm) {}
    },
    actions: {
        async login({ state, dispatch, commit, getters, rootGetters }) {
            // await Public.login({});
        }
    }
};

export default login;
