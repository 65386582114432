















import { Vue, Component, Watch } from 'vue-property-decorator';
import { User } from '@/http';

@Component({
    components: {},
})
export default class service extends Vue {
    public name: string = '';
    public text: string = '';
    public creationTime: string = '';
    mounted() {
        this.init();
    }
    async init() {
        let id = '';
        if (this.$route.path == '/service/settleNotice') {
            id = 'a0269c20-de5d-07f8-9017-5f911af207ec';
        } else if (this.$route.path == '/service/settleAgreement') {
            id = 'f669e536-c03f-f161-45c8-7ad991a2caff';
        } else if (this.$route.path == '/service/clause') {
            id = '9aa75e83-ab64-11e7-a796-a819781860b9';
        } else if (this.$route.path == '/service/policy') {
            id = 'b7f55bcc-c20e-7981-c813-3a077dd1297c';
        } else if (this.$route.path == '/service/process') {
            id = 'b5a65791-2e3e-561e-ef07-3594bbf562ae';
        } else if (this.$route.path == '/service/pay') {
            id = 'c26a1a1e-50fa-70b9-c545-38d3d87d7dd9';
        } else if (this.$route.path == '/service/distribution') {
            id = 'e688e4f9-b0c9-7fb1-e7f3-bfe2e95a1da0';
        } else if (this.$route.path == '/service/afterSales') {
            id = '0b3db50f-352f-4002-feea-cda148d95ae7';
        } else if (this.$route.path == '/service/briefIntroduction') {
            id = '3391d472-6bdf-9da4-66d6-3d3caea4a8f3';
        } else if (this.$route.path == '/service/contact') {
            id = '9ce78998-408c-2578-65c6-b6bf879c6457';
        }
        User.GetPlatformAccord(id).then((res) => {
            this.name = res.name;
            this.text = res.text;
            this.creationTime = res.lastModificationTime;
        });
    }
    @Watch('$route')
    changeRoute(to, from) {
        this.init();
    }
}
