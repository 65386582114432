






















































import { Vue, Component, Watch } from 'vue-property-decorator';
import store from '@/vuex/index';
import Bus from '@/common/util/bus';
import RongIM from '@/common/util/rongcloud';
import Message from '@/components/Message.vue';
@Component({
    components: {
        Message,
    },
})
export default class WorkHeaderBar extends Vue {
    public imgSrc: string = require('@/assets/img/logo.png');
    public enterpriseName: string = '';
    public userName: string = '';
    public enterpriseJob: string = '';
    public phoneNumber: string = '';
    public userType: string = '';
    public isLogin: boolean = false;
    public showCode: boolean = false;
    mouseover() {
        this.showCode = true;
    }

    mouseleave() {
        this.showCode = false;
    }

    public isOpenTencentCloud = false;
    mounted() {
        this.isOpenTencentCloud = sessionStorage.getItem('isOpenTencentCloud') == 'true' ? true : false;
        this.init();
    }
    init() {
        this.imgSrc = sessionStorage.getItem('headPath')
            ? sessionStorage.getItem('headPath') != 'null'
                ? sessionStorage.getItem('headPath')
                : require('@/assets/img/logo.png')
            : require('@/assets/img/logo.png');
        this.phoneNumber = sessionStorage.getItem('phoneNumber') ? sessionStorage.getItem('phoneNumber') : '';
        this.enterpriseName = sessionStorage.getItem('enterpriseName') ? sessionStorage.getItem('enterpriseName') : '';
        this.enterpriseJob = sessionStorage.getItem('enterpriseJob') ? sessionStorage.getItem('enterpriseJob') : '';
        this.userType = sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : '';
        this.isLogin = sessionStorage.getItem('id') ? true : false;
        this.$store.state.userName =
            sessionStorage.getItem('userType') == '2' ? sessionStorage.getItem('enterpriseName') : sessionStorage.getItem('userName');
    }
    login() {
        this.$router.push({
            path: '/login',
        });
    }
    register() {
        this.$router.push({
            path: '/register',
        });
    }

    @Watch('$store.state.isId')
    changeisId(v) {
        this.isLogin = v == '' ? false : true;
    }
    loginOut() {
        this.$confirm('确定要退出登录么?')
            .then(() => {
                sessionStorage.clear();
                this.$store.state.isId = '';
                this.isLogin = false;
                this.$root.$emit('loginOut');
                this.$router.push({
                    path: '/login',
                });
            })
            .catch(() => {});
    }
    settleNotice() {
        this.$router.push({
            path: '/service/settleNotice',
        });
    }
    applyRuzhu() {
        this.$router.push({
            path: '/merchantsEntering',
        });
    }
    shop() {
        console.log('process.env.SHOP_DES' + process.env.VUE_APP_SHOPDES);
        window.open(process.env.VUE_APP_SHOPDES);
    }
    toMyOrder() {
        if (sessionStorage.getItem('id')) {
            this.$router.push({
                path: '/personal/order',
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    toCollect() {
        if (sessionStorage.getItem('id')) {
            this.$router.push({
                path: '/collect/myCollect',
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    toTiezi() {
        if (sessionStorage.getItem('id')) {
            this.$router.push({
                path: '/invitation/publish',
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    toGouWu() {
        if (sessionStorage.getItem('id')) {
            this.$router.push({
                path: '/personal/shoppingCart',
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
}
