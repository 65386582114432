





























































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { formRules } from '@/common/util/validator';
import { User, Login } from '@/http';
import headerPanel from './component/headerPanel.vue';
import footerBar from './component/footerBar.vue';
import ImageUpload from './component/ImageUpload.vue';
import { AddressSelect } from '@/components';
import { JSEncrypt } from 'jsencrypt';

@Component({
    components: {
        headerPanel,
        footerBar,
        AddressSelect,
        ImageUpload,
    },
})
export default class register extends Vue {
    public data: any = {};
    public disYzm: boolean = true;
    public onLine = true;
    public passLogin: boolean = true;
    public yzmLogin: boolean = true;
    public canShow: boolean = false;
    public selectReset: boolean = true;
    public dialogVisible: boolean = false;
    public passWordIsTrue: boolean = false;
    public isRegister: boolean = false;
    public mainBusinessList: any[] = [];
    public address: any = {
        province: {
            value: '',
            label: '',
        },
        city: {
            value: '',
            label: '',
        },
        area: {
            value: '',
            label: '',
        },
    };
    public $refs: {
        registerForm: HTMLFormElement;
        registerForm1: HTMLFormElement;
        autocomplete: HTMLFormElement;
    };
    public registerForm = {
        phoneNumber: '',
        SMSCode: '',
        userName: '',
        password: '',
        repeatPassword: '',
        locationAddress: '',
        locationCountry: '',
        weChatOpenId: '',
        userHead: null,
        checked: ['0'],
        codeType: '8',
    };
    public registerForm1 = {
        enterpriseBrandLogo: {
            name: '',
            path: '',
            tag: '',
            id: '',
        },
        brand: '',
        enterpriseName: '',
        enterpriseCode: '',
        realName: '',
        enterpriseJob: '',
        mainBusiness: '',
        checked: ['0'],
    };
    public btnMessage: string = '获取验证码';
    public countDowning: boolean = false;
    public showdialogue: boolean = false;
    // 倒计时
    interval: any;
    public formRules = formRules;
    public id: string = '';
    public changeLogin(i) {
        if (i == 0) {
            this.passLogin = true;
        } else {
            this.passLogin = false;
        }
        this.canShow = false;
    }
    public async updateReset() {
        this.selectReset = false;
    }
    public async getProvince(item, name) {
        this.address.province.value = item;
        this.address.province.label = name;
    }
    public async getCityCode(item, name) {
        this.address.city.value = item;
        this.address.city.label = name;
    }
    public async getAreaCode(item, name) {
        this.address.area.value = item;
        this.address.area.label = name;
        this.registerForm.locationAddress = this.address.province.label + this.address.city.label + this.address.area.label;
        this.registerForm.locationCountry = this.address.area.value;
    }
    setImage(name, path) {
        this.registerForm1.enterpriseBrandLogo.name = name;
        this.registerForm1.enterpriseBrandLogo.path = path;
    }
    changePassword(value, num) {
        if (num == 0) {
            if (this.registerForm.repeatPassword != '') {
                if (value != this.registerForm.repeatPassword) {
                    this.passWordIsTrue = false;
                    console.log('2');
                    // this.$message.warning('两次密码不一致');
                } else {
                    this.passWordIsTrue = true;
                }
            }
        } else {
            if (this.registerForm.password != '') {
                if (value != this.registerForm.password) {
                    this.passWordIsTrue = false;
                    console.log('3');
                    // this.$message.warning('两次密码不一致');
                } else {
                    this.passWordIsTrue = true;
                }
            }
        }
    }
    created() {
        this.canShow = false;
    }
    async mounted() {
        if (this.registerForm.phoneNumber == '') {
            this.yzmLogin = true;
        } else {
            this.yzmLogin = false;
        }
        if (this.registerForm.phoneNumber.length == 11) {
            this.disYzm = false;
        } else {
            this.disYzm = true;
        }
        this.registerForm.userName = '';
        this.registerForm.password = '';
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
        this.id = sessionStorage.getItem('id') ? sessionStorage.getItem('id') : '';
        if (sessionStorage.getItem('id')) {
            this.setDataDictionary();
        }
    }
    updateOnlineStatus(e) {
        const { type } = e;
        this.onLine = type === 'online';
    }
    beforeDestroy() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    }
    resetForm(formName) {
        if (this.$refs[formName] !== undefined) {
            this.$refs[formName].resetFields();
        }
        switch (formName) {
            case 'registerForm':
                break;
            default:
                break;
        }
    }
    // 获取验证码
    getCode() {
        if (!this.disYzm) {
            User.GetSMSCode({
                phoneNum: this.registerForm.phoneNumber,
                codeType: '8',
            }).then((res) => {
                if (res.extData) {
                    this.dialogVisible = true;
                } else {
                    this.$message.success('验证码已发送');
                    this.countCode();
                }
            });
        }
    }
    // 倒计时
    countCode() {
        this.disYzm = true;
        this.countDowning = true;
        let i = 60;
        this.interval = setInterval(() => {
            this.btnMessage = `${--i}秒后重试`;
            if (i === 0) {
                clearInterval(this.interval);
                this.btnMessage = '获取验证码';
                this.disYzm = false;
                this.countDowning = false;
            }
        }, 1000);
    }
    // 注册
    handleRegister() {
        this.$refs.registerForm.validate((valid) => {
            if (valid) {
                const jsencrypt = new JSEncrypt({});
                jsencrypt.setPublicKey(
                    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDMkmzEoj7LFqLmfKqix11b2A5SMXsAE9ag/9Uq+YeiJfFq5TtP7VpGdJUx06SgQqghgWL5hXLRVTMUn8LVW2SmHV0WjvFAqhtTK9CyTYFQ9wFXy7tpxHcl6jZnw8rokzY2y4yN5WbzEJ4+1c9j0Yp+oeTk0NauyOC03lEOEY9BGwIDAQAB'
                );
                let password = jsencrypt.encrypt(this.registerForm.password);
                let repeatPassword = jsencrypt.encrypt(this.registerForm.repeatPassword);
                let obj = {
                    phoneNumber: this.registerForm.phoneNumber,
                    SMSCode: this.registerForm.SMSCode,
                    userName: this.registerForm.userName,
                    password: password,
                    repeatPassword: repeatPassword,
                    locationAddress: this.registerForm.locationAddress,
                    locationCountry: this.registerForm.locationCountry,
                    weChatOpenId: this.registerForm.weChatOpenId,
                    userHead: {
                        path: 'https://sinostoragedev.oss-cn-hangzhou.aliyuncs.com/fms/11B88022-3B62-43F7-AC27-BDA895E13783.png',
                        name: 'logo',
                    },
                    codeType: '8',
                };
                if (this.passWordIsTrue) {
                    User.AddUser({ ...obj })
                        .then((res) => {
                            let obj = {
                                codeType: '8',
                                webLoginMode: 2,
                                phoneNumber: this.registerForm.phoneNumber,
                                password: password,
                            };
                            Login.login(obj)
                                .then((res) => {
                                    this.successLogin(res);
                                    sessionStorage.setItem('jwtToken', res.jwtToken);
                                })
                                .catch((e) => {
                                    this.isRegister = false;
                                    this.canShow = true;
                                });
                        })
                        .catch((e) => {
                            this.isRegister = false;
                            this.canShow = true;
                        });
                } else {
                    console.log('1');
                    this.$message.warning('两次密码不一致');
                }
            } else {
                this.canShow = true;
            }
        });
    }
    successLogin(res) {
        sessionStorage.setItem('id', res.id);
        sessionStorage.setItem('headPath', res.headPath);
        sessionStorage.setItem('userName', res.userName);
        sessionStorage.setItem('phoneNumber', res.phoneNumber);
        sessionStorage.setItem('isOpenTencentCloud', res.isOpenTencentCloud);
        sessionStorage.setItem('mainBusiness', res.mainBusiness);
        sessionStorage.setItem('userType', res.userType);
        sessionStorage.setItem('enterpriseCode', res.enterpriseCode);
        sessionStorage.setItem('enterpriseJob', res.enterpriseJob);
        sessionStorage.setItem('enterpriseName', res.enterpriseName);
        sessionStorage.setItem('isEnabled', res.isEnabled);
        sessionStorage.setItem('isFirstLogin', res.isFirstLogin);
        sessionStorage.setItem('userSig', res.userSig);
        this.$store.state.isId = res.id;
        this.data = { ...res };
        this.isRegister = true;
        this.$store.state.userName =
            sessionStorage.getItem('userType') == '2' ? sessionStorage.getItem('enterpriseName') : sessionStorage.getItem('userName');
        this.setDataDictionary();
    }
    async setDataDictionary() {
        let res = await User.GetDataDictionary({
            parentId: '95e698f8-8d1e-4c82-7eea-0cc35528e281',
            skip: 0,
            count: -1,
        });
        this.mainBusinessList = res.data;
    }
    public async handleCustomerDropDown(qs, cb) {
        this.registerForm1.enterpriseCode = '';
        if (qs == '') {
            return;
        }
        let res = await User.GetEnterpriseCodeFromQcc({
            enterpriseName: qs,
            pageIndex: 0,
            count: 10,
        });
        if (res.data) {
            cb(
                res.data.map((v) => ({
                    value: v.enterpriseName,
                    enterpriseCode: v.enterpriseCode,
                }))
            );
        } else {
            cb([]);
        }
    }

    public handleSelect(item) {
        if (item.enterpriseCode == '') {
            this.$message.warning('未查询到该企业信息');
        } else {
            this.registerForm1.enterpriseCode = item.enterpriseCode;
        }
    }
    getEnterpriseCode() {
        this.$refs.autocomplete.focus();
    }
    // 提交
    handleClick() {
        const jsencrypt = new JSEncrypt({});
        jsencrypt.setPublicKey(
            'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDMkmzEoj7LFqLmfKqix11b2A5SMXsAE9ag/9Uq+YeiJfFq5TtP7VpGdJUx06SgQqghgWL5hXLRVTMUn8LVW2SmHV0WjvFAqhtTK9CyTYFQ9wFXy7tpxHcl6jZnw8rokzY2y4yN5WbzEJ4+1c9j0Yp+oeTk0NauyOC03lEOEY9BGwIDAQAB'
        );
        this.$refs.registerForm1.validate((valid) => {
            if (valid) {
                let obj = {
                    codeType: '8',
                    id: sessionStorage.getItem('id'),
                    enterpriseBrandLogo: this.registerForm1.enterpriseBrandLogo,
                    brand: this.registerForm1.brand,
                    enterpriseName: this.registerForm1.enterpriseName,
                    enterpriseCode: this.registerForm1.enterpriseCode,
                    realName: this.registerForm1.realName,
                    enterpriseJob: this.registerForm1.enterpriseJob,
                    mainBusiness: this.registerForm1.mainBusiness,
                };
                User.EditUserEnterpriseInfo(obj).then((res) => {
                    // window.location.href = 'http://jutiangas.com/#/';
                    this.refreshUser();
                    this.$router.push({
                        path: '/',
                    });
                });
            }
        });
    }
    refreshUser() {
        User.GetRefreshUserInfo(sessionStorage.getItem('id')).then((res) => {
            this.successLogin(res);
        });
    }
    @Watch('registerForm.phoneNumber')
    public loginForm1PwdChange(mobile) {
        if (mobile == '') {
            this.yzmLogin = true;
        } else {
            this.yzmLogin = false;
        }
        if (!this.countDowning && mobile.length == 11) {
            this.disYzm = false;
        } else {
            this.disYzm = true;
        }
    }
    login() {
        this.$router.push({
            path: '/login',
        });
    }
    jumpFuwu() {
        window.open('http://jutiangas.com/#/service/clause');
    }
    jumpYinsi() {
        window.open('http://jutiangas.com/#/service/policy');
    }
}
