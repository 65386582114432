



























































































































import { Vue, Component } from 'vue-property-decorator';
import store from '@/vuex/index';
import { Cart, User, GoodsType, ShopBrand } from '@/http';
@Component({
    components: {},
})
export default class search extends Vue {
    public input: string = '';

    public dataDictionaryList: any[] = [];
    public cartTotal: number = 0;
    public id: string = '';
    /**商品分类 */
    public fullGoodsTypeList: any[] = [];
    /**二级分类 */
    public twoLevelGoodsTypeList: any[] = [];
    public modeShow: boolean = false;
    public allFenlei: boolean = false;
    canShow = false;
    created() {
        window.addEventListener('scroll', this.handleScrollx, true);
    }

    mounted() {
        sessionStorage.setItem('searchInput', '');
        this.getDataDictionaryList();
        this.init();
    }
    handleScrollx() {
        if (window.pageYOffset > 400) {
            this.canShow = true;
        } else {
            this.canShow = false;
        }
    }

    public shopBrandListByGoodsTypeImgs = [];
    public shopBrandListByGoodsType(id) {
        this.shopBrandListByGoodsTypeImgs = [];
        ShopBrand.shopBrandListByGoodsType({ goodsTypeOneIds: id }).then((res) => {
            this.shopBrandListByGoodsTypeImgs = res;
        });
    }
    async init() {
        this.id = sessionStorage.getItem('id') ? sessionStorage.getItem('id') : '';
        if (sessionStorage.getItem('id')) {
            let res = await Cart.getCart({
                skip: 0,
                count: -1,
            });
            let i = 0;
            if (res.data.length > 0) {
                res.data.forEach((e) => {
                    i += e.cartList.length;
                });
                this.cartTotal = i;
            } else {
                this.cartTotal = 0;
            }
        }
        this.GetFullGoodsTypeList();
    }

    search(value) {
        this.$store.state.searchInput = value ? value : this.input;
        sessionStorage.setItem('searchInput', value);
        this.input = value;
        this.$root.$emit('shouyeSearch', value);
        this.$router.push({
            path: '/goodsList',
            // query: {
            //     search: value,
            // },
        });
        // this.input = value;
    }

    async getDataDictionaryList() {
        let list = await User.GetDataDictionary({
            parentId: '860804c6-2cda-b9d0-61c0-25df8f70e68f',
            skip: 0,
            count: -1,
        });
        this.dataDictionaryList = list.data;
    }
    toShopDetail(id) {
        this.modeShow = false;
        this.$router.push({
            path: '/shop',
            query: {
                shopId: id,
            },
        });
    }
    jumpCart() {
        if (sessionStorage.getItem('id')) {
            this.$router.push({
                path: '/personal/shoppingCart',
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    toShouYe() {
        this.$router.push({
            path: '/',
        });
    }
    jumpLogin() {
        this.$router.push({
            path: '/login',
        });
    }
    /**获取完整商品分类 */
    public async GetFullGoodsTypeList() {
        let list = await GoodsType.GetFullGoodsTypeList();
        this.fullGoodsTypeList = list.data;
    }
    jumpGoodList(v) {
        this.$router.push({
            path: '/goodsList',
            query: {
                id: v.id,
                level: v.goodsTypeLevel,
                parentGoodsTypeId: v.parentGoodsTypeId ? v.parentGoodsTypeId : '',
            },
        });
    }
    mouseAllfenlei() {
        if (this.$route.fullPath == '/') {
            this.allFenlei = false;
        } else {
            this.allFenlei = true;
        }
    }
    public mouseenterGoodsType(item) {
        this.twoLevelGoodsTypeList = item.twoLevelGoodsTypeList;
        this.shopBrandListByGoodsType(item.id);
        this.modeShow = true;
    }
}
