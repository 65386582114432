





































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { fmoney } from '@/common/util/money';
import { NavBar } from '../home/components';
import { PreferentialActivity } from '@/http';
import PaginationPanel from '@/components/PaginationPanel.vue';
export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        NavBar,
        PaginationPanel,
    },
})
export default class newProduc extends Vue {
    public total: number = 0;
    public rePagin: boolean = false;
    public fenleis: any[] = [];
    public fenleiNum: number = null;
    public closingUpOrDown: boolean = true;
    public onlineUpOrDown: boolean = true;
    public chooseClosing: boolean = false;
    public chooseOnline: boolean = false;
    public newProducList: any[] = [];
    public specialOfferList: any[] = [];
    public shopTogetherList: any[] = [];
    public userType: string = '1';
    public specialOff: any = {
        goodsName: '',
    };
    public newProduc: any = {
        goodsName: '',
    };
    public shopTogether: any = {
        goodsName: '',
    };
    public searchPanel = {
        goodsTypeOneId: '',
        activityStatus: '3,4',
        usePlatform: '1',
        useUserType: sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : null,
        orderby: '',
        ASC: true,
    };
    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };
    changeasc(v) {
        if (v == 0) {
            this.chooseClosing = false;
            this.chooseOnline = false;
            this.searchPanel.ASC = true;
            this.searchPanel.orderby = '';
        } else if (v == 1) {
            this.closingUpOrDown = !this.closingUpOrDown;
            this.chooseClosing = true;
            this.chooseOnline = false;
            this.searchPanel.ASC = this.closingUpOrDown;
            this.searchPanel.orderby = '2';
        } else if (v == 2) {
            this.onlineUpOrDown = !this.onlineUpOrDown;
            this.chooseClosing = false;
            this.chooseOnline = true;
            this.searchPanel.ASC = this.onlineUpOrDown;
            this.searchPanel.orderby = '1';
        }
        this.init();
    }
    searchGoodsName() {
        this.init();
    }

    changeFenlei(i, id) {
        this.fenleiNum = i;
        this.searchPanel.goodsTypeOneId = id;
        this.init();
    }
    mounted() {
        this.init();
    }
    async init() {
        this.userType = sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : '1';
        if (this.$route.path == '/specialOff') {
            PreferentialActivity.GetActivityGoodsTypeList({
                activityType: '2',
            }).then((res) => {
                this.fenleis = res.data;
            });
            PreferentialActivity.GetPaSpecialPriceList({
                ...this.specialOff,
                ...this.searchPanel,
                skip: this.searchPagin.skip,
                count: this.searchPagin.count,
            }).then((res) => {
                this.specialOfferList = res.data;
                this.total = res.total;
            });
        } else if (this.$route.path == '/shopTogether') {
            PreferentialActivity.GetActivityGoodsTypeList({
                activityType: '3',
            }).then((res) => {
                this.fenleis = res.data;
            });
            PreferentialActivity.GetPaShopTogetherList({
                ...this.shopTogether,
                ...this.searchPanel,
                skip: this.searchPagin.skip,
                count: this.searchPagin.count,
            }).then((res) => {
                this.shopTogetherList = res.data;
                this.total = res.total;
            });
        } else if (this.$route.path == '/newProduc') {
            PreferentialActivity.GetActivityGoodsTypeList({
                activityType: '1',
            }).then((res) => {
                this.fenleis = res.data;
            });
            PreferentialActivity.GetPaNewGoodsList({
                ...this.newProduc,
                ...this.searchPanel,
                skip: this.searchPagin.skip,
                count: this.searchPagin.count,
            }).then((res) => {
                this.newProducList = res.data;
                this.total = res.total;
            });
        }
    }
    jumpDetail(v, item) {
        this.$router.push({
            path: '/preferentialGoodsDetail',
            query: {
                name: v,
                id: item.goodsId,
                preferentialActivityId: item.preferentialActivityId,
            },
        });
    }
    public async onPaginationChange(pagin: Pagination) {
        this.searchPagin = pagin;
        // this.GetActivityList();
        this.init();
    }
}
