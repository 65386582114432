import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace User {
    /**
     * 添加用户
     */
    export var AddUser = (body: any): Promise<any> => {
        return Post(`/api/User`, body);
    };
    /**
     * 获取短信验证码
     */
    export var GetSMSCode = (query: any): Promise<any> => {
        return Get(`/api/SMSCode?${qs.stringify(query)}`, '');
    };
    //获取省市区
    export var GetZone = (query: any): Promise<any> => {
      return Get(`/api/Zone?${qs.stringify(query)}`, {});
    };
    /**
     * 完善企业用户信息
     */
    export var EditUserEnterpriseInfo = (body: any): Promise<any> => {
      return Put(`/api/User/EditUserEnterpriseInfo`, body);
    };
    /**
       * 获取企业注册信息
       */
      export var GetEnterpriseCodeFromHistory = (query: any): Promise<any> => {
        return Get(`/api/User/GetEnterpriseCodeFromHistory?${qs.stringify(query)}`, '');
    };
    /**
       * 获取企业机构代码信息
       */
      export var GetEnterpriseCodeFromQcc = (query: any): Promise<any> => {
        return Get(`/api/User/GetEnterpriseCodeFromQcc?${qs.stringify(query)}`, '');
    };
    /**
       * 获取用户信息
       */
      export var GetRefreshUserInfo = (id: string): Promise<any> => {
        return Get(`/api/User/RefreshUserInfo/${id}`, '');
    };
    /**
       * 获取微信银行卡枚举
       */
      export var GetWxBankCodeList = (): Promise<any> => {
        return Get(`/api/Enum/GetWxBankCodeList`, '');
    };
    /**
       * 添加商户
       */
      export var AddMerchantInfo = (body: any): Promise<any> => {
        return Post(`/api/MerchantInfo`, body);
    };
    /**
       * 获取商户详情
       */
      export var GetMerchantInfo = (): Promise<any> => {
        return Get(`/api/MerchantInfo`, '');
    };
    /**
     * 修改商户
     */
    export var EditMerchantInfo = (body: any): Promise<any> => {
      return Put(`/api/MerchantInfo?id=${body.id}`, body);
    };
    /**
       * 获取数据字典
       */
      export var GetDataDictionary = (query: any): Promise<any> => {
        return Get(`/api/DataDictionary?${qs.stringify(query)}`, '');
    };
     /**
       * 获取平台协议详情
       */
      export var GetPlatformAccord = (id: string): Promise<any> => {
        return Get(`/api/PlatformAccord/${id}`, '');
    };
    /**
     * 修改用户基础信息
     */
    export var EditUserBaseInfo = (body: any): Promise<any> => {
      return Put(`/api/User/EditUserBaseInfo`, body);
    };
    /**
     * 编辑用户密码
     */
    export var EditUserPwd = (body: any): Promise<any> => {
      return Put(`/api/User/EditUserPwd`, body);
    };
    /**
     * 编辑用户手机号
     */
    export var EditUserPhone = (body: any): Promise<any> => {
      return Put(`/api/User/EditUserPhone`, body);
    };
    /**
     * 重置密码
     */
    export var ResetPwd = (body: any): Promise<any> => {
      return Put(`/api/User/ResetPwd`, body);
    };
    /**
     * 统计用户数
     */
    export var GetAccountUserCount = (): Promise<any> => {
      return Get(`/api/User/AccountUserCount`, '');
    };
    /**
     * 验证手机号和验证码是否正确
     */
    export var CheckSMSCode = (body: any): Promise<any> => {
      return Post(`/api/User/CheckSMSCode`, body);
    };
}
