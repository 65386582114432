







































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { CommonPanel, PaginationPanel2 } from '@/components';
import { fmoney } from '@/common/util/money';
import { BannerAdvert, Activity, GoodsType, ShopBrand, Goods, Order, User, Cart, GeneralAdvert, PostBar } from '@/http';
import { type } from '../../http/interface';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import Bus from '@/common/util/bus';

export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        CommonPanel,
        PaginationPanel2,
        Carousel3d,
        Slide,
    },
})
export default class shouye extends Vue {
    tkey = 0;
    public fenleiId = null;
    public three1show = true;
    public three2show = false;
    public three3show = false;
    public pinpaiTuijian = null;

    mouseover1() {
        this.three1show = true;
        this.three2show = false;
        this.three3show = false;
        this.tkey++;
    }
    mouseover2() {
        this.three1show = false;
        this.three2show = true;
        this.three3show = false;
        this.tkey++;
    }
    mouseover3() {
        this.three1show = false;
        this.three2show = false;
        this.three3show = true;
        this.tkey++;
    }

    mouseover4(i) {
        this.pinpaiTuijian = i;
    }
    mouseleave4() {
        this.pinpaiTuijian = null;
    }

    public fmoney = fmoney;
    public rePagin: boolean = false;
    public modeShow: boolean = false;
    public id: string = '';
    public enterpriseName: string = '';
    public userName: string = '';
    public isLogin: boolean = false;
    public hotBrands: any[] = [];
    /**banner */
    public bannerAdvertList: any[] = [];
    /**活动列表 */
    public activityList: any[] = [];
    /**商品分类 */
    public fullGoodsTypeList: any[] = [];
    /**二级分类 */
    public twoLevelGoodsTypeList: any[] = [];
    /**品牌 */
    public shopBrandList: any[] = [];
    /**产品类别 */
    public goodsTypeList: any[] = [];
    /**商品列表 */
    public goodsList: {
        [key: string]: type.GoodsItemVM;
    } = {} as any;
    public total: {
        [key: string]: type.GoodsItemVM;
    } = {} as any;
    public currutData: type.GoodsItemVM = {} as any;
    /**用户类型 */
    public userType: string | number = '';
    public waitPayCount: number = 0;
    public waitDeliveryCount: number = 0;
    public waitReceiveCount: number = 0;
    public allCount: number = 0;
    public enterpriseCount: number = 0;
    public async onPaginationChange(pagin: Pagination, id: string) {
        this.searchPagin = pagin;
        let list = await GoodsType.GetGoodsTypeList({
            goodsTypeLevel: '1',
            parentGoodsTypeId: '',
            isRecommendHome: true,
            skip: 0,
            count: -1,
        });
        this.goodsTypeList = list.data;

        list.data.map((v) => {
            if (v.id == id) {
                Goods.GetGoods({
                    isTop: true,
                    name: '',
                    goodsTypeOneId: v.id,
                    goodsTypeTwoId: '',
                    goodsTypeThreeId: '',
                    shopId: '',
                    skip: this.searchPagin.skip,
                    count: this.searchPagin.count,
                }).then((res) => {
                    this.goodsList[v.id] = res.data;
                    this.total[v.id] = res.total;
                    this.$forceUpdate();
                });
            }
        });
    }
    public searchPagin: Pagination = {
        skip: 0,
        count: 6,
        currentPage: 1,
    };
    public $refs: {
        top: HTMLFormElement;
    };
    mounted() {
        sessionStorage.setItem('attractIndex', '1');
        window.addEventListener('scroll', this.handleScrollx, true);
        this.init();
        this.getCartTotal();
        this.hotBrands = [{ path: '' }, { path: '' }, { path: '' }, { path: '' }, { path: '' }, { path: '' }];
    }
    //跳转我的订单页
    waitPay() {
        this.$router.push({
            path: '/personal/order?activeName=2',
        });
    }
    waitDelivery() {
        this.$router.push({
            path: '/personal/order?activeName=3',
        });
    }
    waitReceive() {
        this.$router.push({
            path: '/personal/order?activeName=4',
        });
    }

    toReTieDetail(id) {
        console.log('idx' + id);
        this.$router.push({
            path: '/interactionDetail',
            query: {
                id: id,
                name: 'hudong',
            },
        });
    }

    me() {}

    public shouyeImgObj = [];
    public pingtaiHuodongImg = [];
    public imgsType3 = [];
    public imgsType4 = [];
    public imgsType5 = [];
    public imgsType6 = [];
    public imgsType7 = [];
    public imgsType8 = [];
    public imgsType10 = [];
    public count = 0;
    public count3 = 0;
    public count4 = 0;
    public count5 = 0;
    public getGeneralAdvert() {
        GeneralAdvert.GetGeneralAdvertApi({
            generalAdvertTypes: '1,2,3,4,5,6,7,8,10',
        }).then((res) => {
            this.count = 0;
            this.count3 = 0;
            this.count4 = 0;
            this.count5 = 0;
            this.shouyeImgObj = [];
            this.pingtaiHuodongImg = [];
            this.imgsType3 = [];
            this.imgsType4 = [];
            this.imgsType5 = [];
            this.imgsType6 = [];
            this.imgsType7 = [];
            this.imgsType8 = [];
            this.imgsType10 = [];

            if (res.data) {
                res.data.forEach((item) => {
                    if (item.generalAdvertType == 1 && item.webAttachment && item.webAttachment.path) {
                        //首页
                        this.shouyeImgObj.push(item);
                    }
                    if (item.generalAdvertType == 2 && item.webAttachment && item.webAttachment.path) {
                        if (this.count > 4) return;
                        //平台活动
                        this.pingtaiHuodongImg.push(item);
                        this.count++;
                    }
                    if (item.generalAdvertType == 3 && item.webAttachment && item.webAttachment.path) {
                        if (this.count3 > 4) return;
                        this.imgsType3.push(item);
                        this.count3++;
                        this.tkey++;
                    }
                    //特价甩卖
                    if (item.generalAdvertType == 4 && item.webAttachment && item.webAttachment.path) {
                        if (this.count4 > 4) return;
                        this.imgsType4.push(item);
                        this.count4++;
                    }
                    if (item.generalAdvertType == 5 && item.webAttachment && item.webAttachment.path) {
                        if (this.count5 > 4) return;
                        this.imgsType5.push(item);
                        this.count5++;
                    }
                    if (item.generalAdvertType == 6 && item.webAttachment && item.webAttachment.path) {
                        this.imgsType6.push(item);
                    }
                    if (item.generalAdvertType == 7 && item.webAttachment && item.webAttachment.path) {
                        this.imgsType7.push(item);
                    }
                    if (item.generalAdvertType == 8 && item.webAttachment && item.webAttachment.path) {
                        this.imgsType8.push(item);
                    }
                    if (item.generalAdvertType == 10 && item.webAttachment && item.webAttachment.path) {
                        this.imgsType10.push(item);
                    }
                });
            }
        });
    }
    public hotPostBar = [];
    public GetHotPostBar() {
        this.hotPostBar = [];
        PostBar.GetHotPostListByViews().then((res) => {
            this.hotPostBar = res.data;
        });
    }
    public tuijianGoodsList = [];
    getTuijianList() {
        this.tuijianGoodsList = [];
        Goods.simplifyList({
            orderby: 'SaleCount',
            asc: false,
            count: 20,
        }).then((res) => {
            this.tuijianGoodsList = res.data;
        });
    }
    public async init() {
        this.userType = sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : '';

        this.getBannerAdvertList();
        this.GetActivityList();
        this.GetFullGoodsTypeList();
        this.getShopBrandList();
        this.GetGoodsTypeList();
        this.GetHotPostBar();
        this.getTuijianList();
        this.GetFullGoodsTypeList2();
        this.enterpriseName = sessionStorage.getItem('enterpriseName') ? sessionStorage.getItem('enterpriseName') : '';
        this.userName = sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : '';
        this.userType = sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : '';
        this.isLogin = sessionStorage.getItem('id') ? true : false;
        let result = await User.GetAccountUserCount();
        this.allCount = result.allCount;
        this.enterpriseCount = result.enterpriseCount;
        if (sessionStorage.getItem('id')) {
            let res = await Order.GetOrderCountResult();
            this.waitPayCount = res.waitPayCount;
            this.waitDeliveryCount = res.waitDeliveryCount;
            this.waitReceiveCount = res.waitReceiveCount;
        }
        this.getGeneralAdvert();
        // this.getAll();
    }

    public allBrandListImgs = [];
    public group(array, subGroupLength) {
        let index = 0;
        let newArray = [];
        while (index < array.length) {
            newArray.push(array.slice(index, (index += subGroupLength)));
        }
        return newArray;
    }
    public getAll() {
        ShopBrand.shopBrandListByGoodsType({ goodsTypeOneIds: this.ids }).then((res) => {
            this.allBrandListImgs = this.group(res, 4);
        });
    }
    login() {
        this.$router.push({
            path: '/login',
        });
    }
    register() {
        this.$router.push({
            path: '/register',
        });
    }
    toMe() {
        this.$router.push({
            path: '/personal/order',
        });
    }
    @Watch('$store.state.isId')
    changeisId(v) {
        this.isLogin = v == '' ? false : true;
    }
    loginOut() {
        this.$confirm('确定要退出登录么?')
            .then(() => {
                sessionStorage.clear();
                this.$store.state.isId = '';
                this.isLogin = false;
                this.$root.$emit('loginOut');
                this.$router.push({
                    path: '/login',
                });
            })
            .catch(() => {});
    }

    imgChanged(data) {
        // console.log('data'+data)
        // this.$refs.top.style.backgroundColor = this.colors[data]
    }

    /**获取banner列表 */
    public colors = [];
    public async getBannerAdvertList() {
        let list = await BannerAdvert.GetBannerAdvertList({ bannerAdvertType: '2' });
        this.bannerAdvertList = list;
        this.tkey++;
        // this.$nextTick(()=>{
        //     for(let i = 0;i<this.bannerAdvertList.length;i++){
        //         let domImg:any = document.querySelector('#index'+i);
        //         domImg.crossOrigin = "Anonymous";
        //         domImg.addEventListener('load', ()=>{
        //             let color = this.colorthief.getColor(domImg);
        //             let temp = 'rgb('+color[0]+','+color[1]+','+color[2]+')'
        //             this.colors.push(temp)
        //             if(i == 0){
        //                 this.$refs.top.style.backgroundColor = this.colors[0]
        //             }
        //         })
        //     }

        // })
    }
    /**活动列表 */
    public async GetActivityList() {
        let list = await Activity.GetActivityList({ skip: 0, count: 4 });
        this.activityList = list.data;
    }

    public idsArray = [];
    public ids = '';
    /**获取完整商品分类 */
    public async GetFullGoodsTypeList() {
        this.ids = '';
        this.idsArray = [];
        let list = await GoodsType.GetFullGoodsTypeList();
        this.fullGoodsTypeList = list.data;
        if (this.fullGoodsTypeList.length > 0) {
            for (let i = 0; i < this.fullGoodsTypeList.length; i++) {
                this.idsArray.push(this.fullGoodsTypeList[i].id);
                this.fullGoodsTypeList[i].fenleiId = this.fullGoodsTypeList[i].twoLevelGoodsTypeList[0].id;
                this.fullGoodsTypeList[i].twoLevelGoodsTypeList = this.fullGoodsTypeList[i].twoLevelGoodsTypeList.slice(0, 5);
            }
            this.ids = this.idsArray.join(',');
            // this.getAll();
        }
    }
    mouseleaveGoodsType() {
        this.modeShow = false;
        this.showGun = false;
    }
    fullGoodsTypeList2 = [];
    public async GetFullGoodsTypeList2() {
        this.ids = '';
        this.idsArray = [];
        let list = await GoodsType.GetRecommendHomeGoodsTypeList();
        this.fullGoodsTypeList2 = list.data;
        if (this.fullGoodsTypeList2.length > 0) {
            for (let i = 0; i < this.fullGoodsTypeList2.length; i++) {
                this.idsArray.push(this.fullGoodsTypeList2[i].id);
                this.fullGoodsTypeList2[i].fenleiId = this.fullGoodsTypeList2[i].twoLevelGoodsTypeList[0].id;
                this.fullGoodsTypeList2[i].twoLevelGoodsTypeList = this.fullGoodsTypeList2[i].twoLevelGoodsTypeList.slice(0, 5);
            }
            this.ids = this.idsArray.join(',');
            // this.getAll();
        }
    }
    imgsType9 = [];
    /**获取品牌 */
    public async getShopBrandList() {
        let list = await ShopBrand.GetShopBrandList2({ initials: '', isTop: true, skip: 0, count: 4 });
        this.shopBrandList = list.data;
        GeneralAdvert.GetGeneralAdvertApi({
            generalAdvertTypes: '9',
        }).then((res) => {
            this.imgsType9 = [];
            if (res.data) {
                let temp = this.shopBrandList.concat(res.data);
                this.imgsType9 = temp.slice(0, 4);
            }
        });
    }
    /**获取产品类别 */
    public async GetGoodsTypeList() {
        let list = await GoodsType.GetGoodsTypeList({
            goodsTypeLevel: '1',
            parentGoodsTypeId: '',
            isRecommendHome: true,
            skip: 0,
            count: -1,
        });
        //xxxxxxxxxxxxxxx
        list.data.forEach((item) => {
            if (item.shopBrandItems) {
                item.shopBrandItems = item.shopBrandItems.slice(0, 4);
            }
        });
        this.goodsTypeList = list.data;
        list.data.map((v) => {
            Goods.GetGoods({
                name: '',
                isFirst: true,
                goodsTypeOneId: v.id,
                goodsTypeTwoId: '',
                goodsTypeThreeId: '',
                shopId: '',
                skip: 0,
                count: 6,
            }).then((res) => {
                this.goodsList[v.id] = res.data;
                this.total[v.id] = res.total;
                this.$forceUpdate();
            });
        });
    }

    /**获取商品列表 */
    public async getGoodsList(id) {
        let list = await Goods.GetGoods({
            isTop: true,
            name: '',
            goodsTypeOneId: id,
            goodsTypeTwoId: '',
            goodsTypeThreeId: '',
            shopId: '',
            skip: 0,
            count: 10,
        });
        return list.data;
    }

    toTehui() {
        this.$router.push({
            path: `/preferential`,
        });
    }

    toPinPai() {
        this.$router.push({
            path: `/brand`,
        });
    }

    toTieba() {
        this.$router.push({
            path: `/interaction`,
        });
    }
    jumpOut(item) {
        if (item.webHref != '') {
            if (item.webHref.indexOf('http') > -1) {
                window.open(item.webHref);
            } else {
                window.open('https://' + item.webHref);
            }
        } else {
        }
    }
    showGun = false;
    public mouseenterGoodsType(item) {
        this.showGun = true;
        this.twoLevelGoodsTypeList = item.twoLevelGoodsTypeList;
        //xxx
        this.shopBrandListByGoodsType(item.id);
        this.modeShow = true;
    }

    public shopBrandListByGoodsTypeImgs = [];
    public shopBrandListByGoodsType(id) {
        this.shopBrandListByGoodsTypeImgs = [];
        ShopBrand.shopBrandListByGoodsType({ goodsTypeOneIds: id }).then((res) => {
            this.shopBrandListByGoodsTypeImgs = res;
        });
    }
    public mouseleaveGoodsType2(item, id, i) {
        // Goods.GetGoods({
        //     // isTop: true,
        //     name: '',
        //     goodsTypeOneId: id,
        //     goodsTypeTwoId: '',
        //     goodsTypeThreeId: '',
        //     shopId: '',
        //     skip: 0,
        //     count: 6,
        // }).then((res) => {
        //     this.goodsList[id] = res.data;
        //     this.$forceUpdate();
        // });
    }
    public mouseenterGoodsType2(item, id, i) {
        i.fenleiId = item.id;
        Goods.simplifyList({
            isTop: true,
            name: '',
            goodsTypeOneId: '',
            goodsTypeTwoId: item.id,
            goodsTypeThreeId: '',
            shopId: '',
            skip: 0,
            count: 6,
        }).then((res) => {
            this.goodsList[id] = res.data;
            // this.total[v.id] = res.total;
            this.$forceUpdate();
        });
    }
    toDetail(id) {
        this.$router.push({
            path: `/activity/activityDetail`,
            query: {
                id: id,
            },
        });
    }
    /**品牌专区 */
    public brandZone() {
        this.$router.push(`/brand`);
    }
    /**商品详情 */
    public getGoodsDetail(id) {
        this.$router.push({
            path: '/goodsDetail',
            query: {
                id: id,
            },
        });
    }

    // 平台活动 查看更多
    pingtaiMore() {
        this.$router.push(`/activity/activityList`);
    }

    jumpGoodList(v) {
        this.$router.push({
            path: '/goodsList',
            query: {
                id: v.id,
                level: v.goodsTypeLevel,
                parentGoodsTypeId: v.parentGoodsTypeId ? v.parentGoodsTypeId : '',
            },
        });
    }
    jumpGoodList2(v) {
        this.$router.push({
            path: '/goodsList',
            query: {
                id: v.id,
                level: 'One',
                parentGoodsTypeId: '',
            },
        });
    }

    toShopDetail(id) {
        if (id) {
            this.$router.push({
                path: '/shop',
                query: {
                    shopId: id,
                },
            });
        } else {
            this.$alert('商户审核中!', '提示');
        }
    }

    tiaozhuan(item) {
        if (item.hrefType == 1) {
            if (item.href.indexOf('http') > -1) {
                window.open(item.href);
            } else {
                window.open('https://' + item.href);
            }
        } else if (item.hrefType == 2) {
            this.$router.push({
                path: './GoodsDetail',
                query: {
                    id: item.href,
                },
            });
        }
    }

    toAnchor(name) {
        var element = document.getElementById(name);
        var Top = element.offsetTop; //元素距离顶部距离
        window.scrollTo({
            top: Top + 550,
            behavior: 'smooth',
        });
    }

    toTop() {
        var element = document.getElementById('app');
        element.scrollIntoView({ behavior: 'smooth' }); // 参数 false 代表 Bottom
    }

    public isMaoShow = false;
    public anchorIndex = null;
    handleScrollx() {
        // console.log('滚动高度', window.pageYOffset);
        if (window.pageYOffset > 430) {
            this.isMaoShow = true;
        } else {
            this.isMaoShow = false;
        }
        // if (window.pageYOffset > 1108 && window.pageYOffset <= 1681) {
        //     this.anchorIndex = 0;
        // } else if (window.pageYOffset > 1681 && window.pageYOffset <= 2254) {
        //     this.anchorIndex = 2;
        // } else if (window.pageYOffset > 2254 && window.pageYOffset <= 2827) {
        //     this.anchorIndex = 4;
        // }
    }
    jumpCart() {
        if (sessionStorage.getItem('id')) {
            this.$router.push({
                path: '/personal/shoppingCart',
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    jumpLogin() {
        this.$router.push({
            path: '/login',
        });
    }
    public cartTotal: number = 0;
    public cartid = '';
    async getCartTotal() {
        this.cartid = sessionStorage.getItem('id') ? sessionStorage.getItem('id') : '';
        if (sessionStorage.getItem('id')) {
            let res = await Cart.getCart({
                skip: 0,
                count: -1,
            });
            let i = 0;
            if (res.data.length > 0) {
                res.data.forEach((e) => {
                    i += e.cartList.length;
                });
                this.cartTotal = i;
            } else {
                this.cartTotal = 0;
            }
        }
    }

    public showCode: boolean = false;
    mouseover() {
        // this.showCode = true;
    }

    mouseleave() {
        this.showCode = false;
    }
}
