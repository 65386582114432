const Validator = {
    /**
     *
     * @param str 手机号
     */
    isPhone(str) {
        const reg = /^1\d{10}$/;
        return reg.test(str);
    },
    getPhoneValidator(isRequire: boolean) {
        let validPhone = (rule, value, callback) => {
            if (!value) {
                if (isRequire) {
                    callback(new Error('请输入手机号码'));
                } else {
                    callback();
                }
            } else if (!Validator.isPhone(value)) {
                callback(new Error('请输入正确的11位手机号码'));
            } else {
                callback();
            }
        };
        return validPhone;
    },
    /**
     *
     * @param str 金额   可保留两位小数的数字
     */
    isMoney(str) {
        const reg = /^[0-9]+(\.[0-9]{1,2})?$/;
        return reg.test(str);
    },
    getMoneyValidator(isRequire: boolean) {
        let validPhone = (rule, value, callback) => {
            if (!value) {
                if (isRequire) {
                    callback(new Error('请输入总金额'));
                } else {
                    callback();
                }
            } else if (!Validator.isMoney(value)) {
                callback(new Error('请输入正确的金额数字'));
            } else {
                callback();
            }
        };
        return validPhone;
    },
    getRuleNameValidator(isRequire: boolean) {
        let validRuleName = (rule, value, callback) => {
            if (Validator.strlen(value) < 4) {
                callback(new Error('规则名称长度为4~30'));
            } else {
                callback();
            }
        };
        return validRuleName;
    },
    /**获取字节长度 */
    strlen(str) {
        var len = 0;
        for (var i = 0; i < str.length; i++) {
            var c = str.charCodeAt(i);
            //单字节加1
            if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
                len++;
            } else {
                len += 2;
            }
        }
        return len;
    }
};
export default {
    install: function (Vue, Option) {
        Object.defineProperty(Vue.prototype, '$valid', { value: Validator });
    }
};

export var formRules = {
    // phone:{validator : Validator.getPhoneValidator(true),trigger:'change' }  /* 自定义校验 */
    /* input框 */
    preMoney: [
        {
            required: true,
            pattern: /^\d{1,9}(\.\d{1,2})?$/,
            message: '最多输入9位和2位小数'
        }
    ],
    blur: [{ required: true, pattern: /\S/, message: '此项为必填项', trigger: 'blur' }],
    codeBlur: [{ required: true, pattern: /\S/, message: '请输入验证码', trigger: 'blur' }],
    name: [{ required: true, pattern: /\S/, message: '请输入姓名', trigger: 'change' }],
    newName: [{ required: true, pattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/, message: '请输入中英文数字名称', trigger: 'change' }],
    departmentName: [{ required: true, pattern: /^[\u4E00-\u9FA5]+$/, message: '请输入中文名称', trigger: 'change' }],
    userName: [
        {
            required: true,
            pattern: /\S/,
            message: '请输入用户名',
            trigger: 'change'
        }
    ],
    /* select框 */
    change: [{ required: true, message: '此项为必填项', trigger: 'change' }],
    changeXieYi: [{ required: true, message: '请选择我已阅读并同意《服务条款》和《隐私政策》', trigger: 'change' }],
    /* 登录密码 */
    password: [
        { min: 6, required: true, message: '请输入6位以上密码', trigger: 'change' }
        // { max: 20, required: true, message: '密码最多输入20位字符', trigger: 'change' }
    ],
    password1: [
        { min: 6, required: true, message: '请输入6位以上密码', trigger: 'change' },
        {
            required: true,
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/,
            message: '请输入字母数字组合密码',
            trigger: 'change'
        }
    ],
    password2: [
        { min: 6, max: 20, required: true, message: '请输入6位以上20位以下的密码', trigger: 'change' },
        {
            required: true,
            pattern: /^[0-9a-zA-Z]+$/,
            message: '请输入字母数字密码',
            trigger: 'change'
        }
    ],
    /**备注 */
    remarks: [
        {
            max: 50,
            required: false,
            message: '请输入50字以内备注',
            trigger: 'change'
        }
    ],
    /**50字符内 */
    alias50: [
        {
            max: 50,
            required: true,
            message: '请输入50字以内字符',
            trigger: 'change'
        }
    ],
    /**项目组名称 */
    projectName: [
        {
            required: true,
            pattern: /^[\u4E00-\u9FA5]{1,20}$/,
            message: '请输入20字以内中文名称',
            trigger: 'change'
        }
    ],
    /* 日期 */
    date: [{ required: true, message: '请选择日期时间', trigger: 'change' }],
    /* 手机号验证 */
    phone: [
        {
            required: true,
            pattern: /^1\d{10}$/,
            message: '请输入正确的手机号码',
            trigger: 'change'
        }
    ],
    /* 短信验证码校验 */
    messageCode: [
        {
            required: true,
            pattern: /^\d{6}$/,
            message: '请输入6位数字验证码',
            trigger: 'blur'
        }
    ],
    /* 密码校验 */
    pass: [
        {
            min: 6,
            required: false,
            message: '请输入6位以上密码',
            trigger: 'change'
        },
        {
            required: false,
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/,
            message: '请输入字母数字组合密码',
            trigger: 'change'
        }
    ],
    checkPass: {},
    /* 0-1 保留两位小数 */
    percent: [
        {
            required: true,
            pattern: /^(0(\.\d{1,2})?|1(\.0{1,2})?)$/,
            message: '请输入0到1区间的数字'
        }
    ],
    timeCycle: [{ required: true, pattern: /^[0-9]*$/, message: '请输入正确的数字' }],
    number: [{ pattern: /^[0-9]+(.[0-9]{1,4})?$/, message: '请输入正确的数字' }],
    // 保留小数点后两位
    twoDecimalPoint: [{ required: true, pattern: /^[0-9]+(.[0-9]{1,2})?$/, message: '请输入正确的数字' }],
    numberRequired: [
        {
            required: true,
            pattern: /^[0-9]+(.[0-9]{1,4})?$/,
            message: '请输入正确的数字'
        }
    ],
    projectTotalRequired: [
        { pattern: /^[0-9]{1,6}?$/, message: '请输入正确的数字' },
        { required: true, message: '此项为必填项', trigger: 'blur' }
    ],
    email: [
        {
            required: true,
            pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: '请输入正确邮箱'
        }
    ],
    contactname: [
        { required: true, pattern: /\S/, message: '此项为必填项', trigger: 'blur' },
        {
            required: true,
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9_]{0,20}$/,
            message: '联系人最多输入20位字符',
            trigger: 'change'
        }
    ],
    adminpassword: [
        {
            required: true,
            pattern: /(?!.*[\u4E00-\u9FA5\s])(?!^[a-zA-Z]+$)(?!^[\d]+$)(?!^[^a-zA-Z\d]+$)^.{6,20}$/,
            message: '6-20位字符,数字/字母/符号（除空格）至少包括2种'
        }
    ],
    adminname: [
        {
            required: true,
            pattern: /^[a-zA-Z0-9_]{3,12}$/,
            message: '3-12位字符，字母/数字/下划线，区分大小写'
        }
    ],
    codeValue: [{ required: true, pattern: /^[0-9]{6}?$/, message: '验证码格式错误' }],
    diskSize: [
        {
            required: true,
            pattern: /^(?:[1-9]?\d|100)$/,
            message: '只能输入0-100%',
            trigger: 'change'
        }
    ],
    diskTotal: [{ required: true, pattern: /^[0-9]*$/, message: '只能输入0-9数字' }],
    validCode: [
        { required: true, message: '此项不能为空' },
        { min: 6, required: true, message: '请输入6位以上密码' },
        { required: true, pattern: /^[0-9]*$/, message: '只能输入0-9数字' }
    ],
    identityNo: [
        {
            required: true,
            pattern: /^[A-Z]{4}\d{11}$/,
            message: '请输入正确的标识',
            trigger: 'change'
        }
    ]
};

export var addSignForms = {
    businessNum: [
        { required: true, message: '请输入企业内部业务编号', trigger: 'blur' },
        {
            required: true,
            pattern: /^[A-Za-z0-9]+$/,
            message: '格式错误，请输入数字、英文字母',
            trigger: 'change'
        }
    ],
    tags: [
        // JSON格式校验
        {
            validator: (rule, value, cb) => {
                if (value) {
                    try {
                        var obj = JSON.parse(value);
                        if (typeof obj == 'object' && obj) {
                            for (var key in obj) {
                                if (typeof obj[key] == 'string' && obj[key] != '' && typeof key == 'string' && key != '') {
                                    cb();
                                    return true;
                                } else {
                                    cb(new Error('标签数据格式为tags:{"a":"a","b":"b"},请检查'));
                                    return false;
                                }
                            }
                        } else {
                            cb(new Error('标签数据格式为tags:{"a":"a","b":"b"},请检查'));
                            return false;
                        }
                    } catch (e) {
                        cb(new Error('标签数据格式为tags:{"a":"a","b":"b"},请检查'));
                        return false;
                    }
                } else {
                    cb();
                    return true;
                }
            },
            trigger: 'change'
        }
    ]
};
