/*
 * @Autor: dingxiaolin
 * @Date: 2021-01-25 13:46:42
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-02-19 13:19:51
 */
import { SystemNotify } from '@/http';
const rongCloud = {
    namespaced: true,
    state: {
        causeAuditCount: 0,
        advanceAuditCount: 0,
        reimburseAuditCount: 0,
        noReadCount: 0,
        advanceToPayCount: 0,
        messageList: []
    },
    mutations: {
        updateNumber(state, vm) {
            state.causeAuditCount = vm.causeAuditCount;
            state.advanceAuditCount = vm.advanceAuditCount;
            state.reimburseAuditCount = vm.reimburseAuditCount;
            state.noReadCount = vm.noReadCount;
            state.advanceToPayCount = vm.advanceToPayCount;
        },
        uMessageList(state, vm) {
            vm.map(v => (v.content = JSON.parse(v.content)));
            state.messageList = vm;
        },
        addMessage(state, vm) {
            if (state.messageList.length > 4) {
                state.messageList.pop();
            }
            state.messageList.unshift(vm);
        }
    },
    actions: {
        /**获取消息数，审批数 */
        async getNumber({ state, dispatch, commit, getters, rootGetters }) {
            let data = await SystemNotify.GetNumber();
            commit('updateNumber', data);
        },
        /**获取顶部消息列表 */
        async getMessage({ state, dispatch, commit, getters, rootGetters }) {
            let data = await SystemNotify.GetList({
                isRead: false,
                skip: 0,
                count: 5
            });
            state.noReadCount = data.noReadCount;
            commit('uMessageList', data.data);
        },
        /**更新消息为已读状态 */
        async uAllRead({ state, dispatch, commit, getters, rootGetters }) {
            await SystemNotify.UpdateAllRead();
            dispatch('getMessage');
        }
    }
};

export default rongCloud;
