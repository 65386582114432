








































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import PaginationPanel from './components/PaginationPanel.vue';
import { fmoney } from '@/common/util/money';
import { NavBar } from '../home/components';
import { enums } from '@/common/enums';
import { GoodsType, Shop, Collection, Cart, Focus } from '@/http';
import goodsPeriphery from './components/goodsPeriphery.vue';
import RightAnchor from '@/components/RightAnchor.vue';
import { Goods, AccessRecord } from '@/http';
declare var returnCitySN: any;
export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        NavBar,
        PaginationPanel,
        goodsPeriphery,
        RightAnchor,
    },
})
export default class shop extends Vue {
    public middlePrice = true;
    public downPrice = false;
    public upPrice = false;

    public $refs: {
        goodsPeriphery: HTMLFormElement;
        pagination: HTMLFormElement;
    };

    index = 0;
    priceChange() {
        this.index++;
        console.log('this.index' + this.index);
        console.log('this.index % 3' + (this.index % 3));
        if (this.userType == '1') {
            this.searchValue.orderby = '2';
        } else {
            this.searchValue.orderby = '1';
        }
        if (this.index % 3 == 1) {
            this.middlePrice = false;
            this.downPrice = true;
            this.upPrice = false;
            this.searchValue.asc = false;
        } else if (this.index % 3 == 2) {
            this.middlePrice = false;
            this.downPrice = false;
            this.upPrice = true;
            this.searchValue.asc = true;
        } else if (this.index % 3 == 0) {
            this.middlePrice = true;
            this.downPrice = false;
            this.upPrice = false;
            this.searchValue.asc = true;
            //此时无排序
            this.searchValue.orderby = '';
        }
        this.loadData();
    }

    @Watch('radio1')
    public radio1Change() {
        console.log('this.radio1' + this.radio1);
        if (this.radio1 == '2') {
            console.log('点击了销量');
            this.searchValue.orderby = '3';
            this.searchValue.asc = false;
            //排序图标还原
            this.middlePrice = true;
            this.downPrice = false;
            this.upPrice = false;
        }
        if (this.radio1 == '1') {
            console.log('点击了综合');
            this.searchValue.orderby = '';
            //排序图标还原
            this.middlePrice = true;
            this.downPrice = false;
            this.upPrice = false;
        }
        if (this.radio1 == '3') {
            console.log('点击了价格');
            if (this.userType == '1') {
                this.searchValue.orderby = '2';
            } else {
                this.searchValue.orderby = '1';
            }
        }
        this.loadData();
    }

    @Watch('checkList')
    public checkListChange() {
        console.log('this.checkList' + this.checkList);
        if (this.checkList.indexOf('4') > -1 && this.checkList.indexOf('5') > -1) {
            this.searchValue.merchantType = '';
        } else if (this.checkList.indexOf('4') > -1) {
            this.searchValue.merchantType = '1';
        } else if (this.checkList.indexOf('5') > -1) {
            this.searchValue.merchantType = '2';
        } else {
            this.searchValue.merchantType = '';
        }
        this.loadData();
    }
    //查看更多资质
    showMore() {
        this.$router.push({
            path: './moreShopQualification',
            query: {
                id: this.shopId,
            },
        });
    }

    toDetail(row) {
        this.$router.push({
            path: '/goodsDetail',
            query: {
                id: row.id,
            },
        });
    }

    goumai(item) {
        let orderList = [
            {
                platform: '1',
                receiveAddressId: '',
                receiver: '',
                phoneNumber: '',
                province: '',
                city: '',
                county: '',
                detail: '',
                address: '',
                remark: '',
                shopId: item.shopId,
                shopName: item.shopName,
                payMethod: '',
                orderChildList: [
                    {
                        cartId: '',
                        brand: item.brand,
                        merchantType: item.merchantType,
                        goodsId: item.id,
                        goodsNumber: item.goodsNumber,
                        name: item.name,
                        deliveryAddress: item.deliveryAddress,
                        weight: item.weight,
                        personalFixSalePrice: item.personalFixSalePrice,
                        personalSectionSalePriceOne: item.personalSectionSalePriceOne,
                        personalSectionSalePriceTwo: item.personalSectionSalePriceTwo,
                        enterpriseFixSalePrice: item.enterpriseFixSalePrice,
                        enterpriseSectionSalePriceOne: item.enterpriseSectionSalePriceOne,
                        enterpriseSectionSalePriceTwo: item.enterpriseSectionSalePriceTwo,
                        specId: item.specId,
                        specName: item.specName,
                        goodsQuantity: item.num,
                        coverImageAttachment: item.coverImageAttachmentList[0],
                        mainBusiness: item.mainBusiness,
                    },
                ],
            },
        ];
        if (sessionStorage.getItem('id')) {
            sessionStorage.setItem('goodsList', JSON.stringify(orderList));
            this.$router.push({
                path: '/submitOrder',
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }

    addCart(item) {
        if (sessionStorage.getItem('id')) {
            if (item.num == 0) {
                this.$message('请选择数量');
                return;
            }
            Cart.addBatchAddCart({
                cartList: [
                    {
                        goodsId: item.id,
                        goodsSpecificationId: '',
                        number: item.num,
                    },
                ],
            }).then(() => {
                this.$message.success('添加购物车成功!');
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }

    public total: number = 0;
    public pageSize: number = 0;
    public rePagin: boolean = false;
    public radio1: string = '';
    public enterPriceLists: any[] = [];
    public checkList: string[] = [];
    public orderLists: any[] = [];
    public num: number = 0;
    public isfollowShop: boolean = true;
    public searchPagin: Pagination = {
        skip: 0,
        count: 20,
        currentPage: 1,
    };
    public goodsTypeOneId: string = '';
    public goodsTypeTwoId: string = '';
    public goodsTypeThreeId: string = '';
    public goodsTypeOneList: any[] = [];
    public goodsTypeTwoList: any[] = [];
    public goodsTypeThreeList: any[] = [];
    public searchValue: any = {
        name: '',
        goodsTypeOneId: '',
        goodsTypeTwoId: '',
        goodsTypeThreeId: '',
        shopId: '',
        orderby: '',
        asc: true,
    };
    public async onPaginationChange(pagin: Pagination) {
        this.$nextTick(() => {
            window.scroll(0, 580);
        });
        this.searchPagin = pagin;
        this.loadData();
    }

    radioChange(val) {
        console.log(val);
    }

    shopData: any = {};
    //获取企业详情
    public async GetFullShopDetail() {
        let list = await Shop.GetFullShopDetail(this.shopId);
        this.shopData = list;
    }
    qualificationList: any = {};

    isUserFocus = false;
    //获取是否关注
    public async getIfFollow() {
        let isUserFocus = await Focus.isUserFocus({ id: this.$route.query.shopId });
        if (isUserFocus.extData) {
            this.isUserFocus = true;
        } else {
            this.isUserFocus = false;
        }
    }
    //获取企业资质
    public async GetShopQualificationList() {
        let list = await Shop.GetShopQualificationList({ id: this.shopId });
        this.qualificationList = list.data;
    }
    shopNewsList: any = [];
    //获取企业动态
    public async GetShopNewsList() {
        let list = await Shop.GetShopNewsList({ id: this.shopId });
        this.shopNewsList = list.data;
        console.log('shopNewsList' + this.shopNewsList.length);
    }

    public goodsList = [];
    public async loadData() {
        let list = await Goods.GetGoods({
            userId: sessionStorage.getItem('id'),
            ...this.searchValue,
            ...this.searchPagin,
        });
        list.data.forEach((item) => {
            item.num = 1;
        });
        this.goodsList = list.data;
        this.total = list.total;
        this.pageSize = this.total > 0 ? Math.floor(this.total < 20 ? 1 : this.total % 20 ? this.total / 20 + 1 : this.total / 20) : 0;
    }

    jumpShopNew(index) {
        this.$router.push({
            path: '/shop/shopNews',
            query: {
                name: index,
                shopId: this.shopId,
            },
        });
    }

    public userType = '2';
    public shopId: any = '';
    @Watch('$route')
    public routerChange() {
        this.init();
    }
    mounted() {
        this.init();
    }

    init() {
        this.shopId = this.$route.query.shopId ? this.$route.query.shopId : '';
        this.searchValue.shopId = this.shopId;
        this.GetFullShopDetail();
        this.GetShopQualificationList();
        this.GetShopNewsList();
        this.loadData();
        this.getRecommend();
        if (sessionStorage.getItem('id')) {
            this.getIfFollow();
        } else {
            this.isUserFocus = false;
        }

        AccessRecord.AddAccessRecord({
            webHost: returnCitySN['cip'],
            weChatOpenId: null,
            dataId: this.shopId,
            accessPage: 'Shop',
            creatorUserName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : '',
        });
        this.getGoodsTypeOneList(null, 1, 1);
        this.userType = sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : '';
    }
    // 关注店铺
    followShop() {}
    async getGoodsTypeOneList(id, level, num) {
        let res = await GoodsType.GetGoodsTypeList({
            parentGoodsTypeId: id,
            goodsTypeLevel: level,
            skip: 0,
            count: -1,
        });
        if (num == 1) {
            this.goodsTypeOneList = res.data;
        } else if (num == 2) {
            this.goodsTypeTwoList = res.data;
        } else {
            this.goodsTypeThreeList = res.data;
        }
    }
    // 修改一级分类
    public changeGoodsTypeOneId(id) {
        this.searchValue.goodsTypeTwoId = '';
        this.searchValue.goodsTypeThreeId = '';
        this.getGoodsTypeOneList(id, 2, 2);
    }
    // 修改二级分类
    public changeGoodsTypeTwoId(id) {
        this.searchValue.goodsTypeThreeId = '';
        this.getGoodsTypeOneList(id, 3, 3);
    }
    search() {
        this.loadData();
    }

    recommendData = [];
    async getRecommend() {
        let list = await Goods.GetGoods({
            userId: sessionStorage.getItem('id'),
            shopId: this.shopId,
            orderby: 3,
            asc: false,
            skip: 0,
            count: 20,
        });
        this.recommendData = list.data;
        this.$nextTick(() => {
            this.$refs.goodsPeriphery.setData(this.recommendData);
        });
    }

    setFocus() {
        if (sessionStorage.getItem('id')) {
            Focus.addFocus({
                shopId: this.shopId,
            }).then(() => {
                this.getIfFollow();
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }

    lianxi(data) {
        if (sessionStorage.getItem('id')) {
            this.$root.$emit('lianxi', data.id);
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    handleChange(value) {
        console.log(value);
    }
    shoucang(item) {
        if (sessionStorage.getItem('id')) {
            Collection.addCollection({
                goodsId: item.id,
            }).then(() => {
                this.$message.success('已收藏!');
                item.isUserCollection = !item.isUserCollection;
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }

    lastPage() {
        if (this.searchPagin.currentPage > 1) {
            this.searchPagin.currentPage -= 1;
            this.$refs.pagination.handleCurrentChange(this.searchPagin.currentPage);
        }
    }
    nextPage() {
        if (this.searchPagin.currentPage < this.pageSize) {
            this.searchPagin.currentPage++;
            this.$refs.pagination.handleCurrentChange(this.searchPagin.currentPage);
        }
    }
    login() {
        this.$router.push({
            path: '/login',
        });
    }
    register() {
        this.$router.push({
            path: '/register',
        });
    }
    jumpCart() {
        if (sessionStorage.getItem('id')) {
            this.$router.push({
                path: '/personal/shoppingCart',
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    toTop() {
        var element = document.getElementById('app');
        element.scrollIntoView({ behavior: 'smooth' }); // 参数 false 代表 Bottom
    }
}
