/*
 * @Autor: dingxiaolin
 * @Date: 2021-02-04 18:04:59
 * @LastEditors: dingxiaolin
 * @LastEditTime: 2021-02-24 09:24:38
 */
import Vue from 'vue';
import { MessageBox, Message } from 'element-ui';
const directive = function () {
  //防重复点击事件
  Vue.directive('noReClick', function (el: HTMLFormElement, binding) {
    el.onclick = function (e) {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 1500);
      }
    };
  });
  //无权限点击事件[button]
  Vue.directive('noPermission', function (el: HTMLFormElement, binding, vnode) {
    var authlist = sessionStorage.getItem("permissions") ? sessionStorage.getItem("permissions").split(",") : [];
    if (!authlist.includes(binding.value)) {
      vnode.componentInstance.$off('click');
      vnode.componentInstance.$on('click', function () {
        Message.warning('没有权限');
      });
    }
  });
  //无权限 移除Dom元素
  Vue.directive('noPermissionDom', function (el: HTMLFormElement, binding) {
    var authlist = sessionStorage.getItem("permissions") ? sessionStorage.getItem("permissions").split(",") : [];
    if (!authlist.includes(binding.value)) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  });
};

export default directive;
