



























































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PaginationPanel } from '@/components';
import { fmoney } from '@/common/util/money';
import { NavBar } from '../home/components';
import { Shop } from '@/http';
export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: {
        PaginationPanel,
        NavBar,
    },
})
export default class own extends Vue {
    public total: number = 0;
    public rePagin: boolean = false;
    public letters: any[] = [];
    public szm: string = '';
    public brandLists: any[] = [];
    public shopList: any[] = [];
    /**用户类型 */
    public userType: string | number = '';
    public searchPagin: Pagination = {
        skip: 0,
        count: 10,
        currentPage: 1,
    };
    public async onPaginationChange(pagin: Pagination) {
        this.searchPagin = pagin;
        // this.loadData();
    }
    mounted() {
        this.userType = sessionStorage.getItem('userType') ? sessionStorage.getItem('userType') : '';
        this.letters = [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
            '#',
        ];
        this.brandLists = [{}, {}, {}];
        this.getShopList();
    }
    /**获取自营店铺 */
    public async getShopList() {
        let list = await Shop.GetShopList({ merchantType: 1, initials: this.szm, isShowGoods: true, skip: 0, count: 10 });
        this.shopList = list.data;
    }
    radioChange(e) {
        this.szm = this.letters[e];
        this.getShopList();
    }

    async cancel() {
        this.szm = '';
        let list = await Shop.GetShopList({ merchantType: 1, initials: '', isShowGoods: true, skip: 0, count: 10 });
        this.shopList = list.data;
    }

    /**商品详情 */
    public getGoodsDetail(id) {
        this.$router.push({
            path: '/goodsDetail',
            query: {
                id: id,
            },
        });
    }
    lianxi(item) {
        console.log(JSON.stringify(item));
        if (sessionStorage.getItem('id')) {
            this.$root.$emit('lianxi', item.id);
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
}
