














































































import { Vue, Component } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class footerBar extends Vue {
    outLink() {
        window.open(
            'http://www.beian.gov.cn/portal/registerSystemInfo?spm=a21bo.21814703.1997523009.43.5af911d90nMhwK&recordcode=32118102000382',
            '_blank'
        );
    }
    openIcp() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
    }
    settleNotice() {
        this.$router.push({
            path: '/service/settleNotice',
        });
    }
    settleAgreement() {
        this.$router.push({
            path: '/service/settleAgreement',
        });
    }
    merchantsEntering() {
        this.$router.push({
            path: '/merchantsEntering',
        });
    }
    tiaozhuan(item) {
        this.$router.push(
            { path: item.path },
            (onComplete) => {},
            (onAbort) => {}
        );
    }
    clause() {
        this.$router.push({
            path: '/service/clause',
        });
    }
    policy() {
        this.$router.push({
            path: '/service/policy',
        });
    }
    order() {
        if (sessionStorage.getItem('id')) {
            this.$router.push({
                path: '/personal/order',
            });
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    process() {
        this.$router.push({
            path: '/service/process',
        });
    }
    pay() {
        this.$router.push({
            path: '/service/pay',
        });
    }
    distribution() {
        this.$router.push({
            path: '/service/distribution',
        });
    }
    afterSales() {
        this.$router.push({
            path: '/service/afterSales',
        });
    }
    briefIntroduction() {
        this.$router.push({
            path: '/service/briefIntroduction',
        });
    }
    contact() {
        this.$router.push({
            path: '/service/contact',
        });
    }
}
