













































import { Vue, Component, Prop } from 'vue-property-decorator';
import BlankContailerPanel from '../../components/BlankContailerPanel.vue';
import OrderPanel from './orderPanel.vue';
import { User } from '@/http';
import Bus from '@/common/util/bus';
@Component({
    components: {
        BlankContailerPanel,
        OrderPanel,
    },
})
export default class Order extends Vue {
    public activeName: string = '1';
    public tabList: any = [
        { label: '全部', name: '1' },
        { label: '待付款', name: '2' },
        { label: '待发货', name: '3' },
        { label: '待收货', name: '4' },
        { label: '待评价', name: '5' },
        // { label: '退款/售后', name: '6' },
    ];
    /**当前显示tabs */
    public currentPage: string | number = '1';

    isDisable = false;
    haveTotal(total) {
        console.log('sub' + total);
        if (total > 10) {
            this.isDisable = true;
        } else {
            console.log('total!' + total);
            this.isDisable = false;
        }
    }

    refreshUser() {
        User.GetRefreshUserInfo(sessionStorage.getItem('id')).then((res) => {
            this.successLogin(res);
        });
    }
    successLogin(res) {
        this.enterpriseName = res.enterpriseName;
        this.userName = res.userName;
        this.userType = res.userType;
        this.headPath = res.headPath
            ? res.headPath
            : 'https://sinostoragedev.oss-cn-hangzhou.aliyuncs.com/fms/87852C02-44F5-43AC-A4CB-19678DF0E82C.png';
        this.phoneNumber = res.phoneNumber;
        this.enterpriseJob = res.enterpriseJob;
        this.realName = res.realName;
        sessionStorage.setItem('id', res.id);
        sessionStorage.setItem('headPath', res.headPath);
        sessionStorage.setItem('userName', res.userName);
        sessionStorage.setItem('phoneNumber', res.phoneNumber);
        sessionStorage.setItem('isOpenTencentCloud', res.isOpenTencentCloud);
        sessionStorage.setItem('mainBusiness', res.mainBusiness);
        sessionStorage.setItem('userType', res.userType);
        sessionStorage.setItem('enterpriseCode', res.enterpriseCode);
        sessionStorage.setItem('enterpriseJob', res.enterpriseJob);
        sessionStorage.setItem('enterpriseName', res.enterpriseName);
        sessionStorage.setItem('isEnabled', res.isEnabled);
        sessionStorage.setItem('isFirstLogin', res.isFirstLogin);
        sessionStorage.setItem('userSig', res.userSig);
        console.log('realName' + this.realName);
    }

    enterpriseName = '';
    userName = '';
    userType = '';
    headPath = '';
    phoneNumber = '';
    enterpriseJob = '';
    realName = '';
    public created() {}
    public mounted() {
        this.refreshUser();
        this.showtabs();
    }
    showtabs(){
    if(this.$route.query.activeName!=null){
        (this as any).activeName = this.$route.query.activeName;
    }
    let current1 ={index:''}
	current1.index = String(Number(this.activeName) - 1);
        if(this.activeName=='2'){
          this.handleClick(current1)
        }
        if(this.activeName=='3'){
          this.handleClick(current1)
        }
        if(this.activeName=='4'){
          this.handleClick(current1)
        }
  }
    public async handleClick(tab) {
        console.log('tab------>' + tab.index)
        this.$nextTick(async () => {
            await this.$refs['order_' + tab.index][0].clear();
            this.currentPage = Number(tab.index) + 1;
            this.$refs['order_' + tab.index][0].rePagin = true;
            await this.$refs['order_' + tab.index][0].setCurrentPage(this.currentPage);
            await this.$refs['order_' + tab.index][0].init();
            this.$refs['order_' + tab.index][0].rePagin = false;
        });
    }
    public async onpaginChange(type) {
        await this.$refs['order_' + (Number(this.currentPage) - 1)][0].turnPage(type);
    }
    lastPage() {
        this.$nextTick(() => {
            this.$refs['order_' + (Number(this.currentPage) - 1)][0].lastPage();
        });
    }
    nextPage() {
        this.$nextTick(() => {
            this.$refs['order_' + (Number(this.currentPage) - 1)][0].nextPage();
        });
    }
    jump() {
        this.$router.push({
            path: '/register',
        });
    }
}
