



























































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { NavBar } from '../home/components';
import ImageNumUpload from './components/ImageNumUpload.vue';
import { formRules } from '@/common/util/validator';
import refundorderInfo from './components/refundorderInfo.vue';
import { RefundOrder, Order, User } from '@/http';

@Component({
    components: {
        NavBar,
        ImageNumUpload,
        refundorderInfo,
    },
})
export default class Refund extends Vue {
    public formRules = formRules;
    public labelPosition: string = 'right';
    public regionList: any[] = [];
    public tuikuanInfo: any = {
        refundReason: '',
        remark: '',
        refundCertificateList: [],
    };
    public imgList: any = [];
    public refundStatus: string = '0';
    public id: string | string[];
    public refundType: string = '';
    public refundInfo: any = {};
    public productList: any[] = [];
    public tipList: any[] = [];
    public dialogVisible: boolean = false;
    public rejection: any = {
        logistics: '',
        logisticsNum: '',
        returnGoodsRemark: '',
    };
    public $refs: {
        rejection: HTMLFormElement;
        settledFrom: HTMLFormElement;
    };
    public mounted() {
        if (this.$route.query.id) {
            this.init();
        }
    }
    async init() {
        this.id = this.$route.query.id;
        RefundOrder.getRefundOrder(this.id)
            .then((res) => {
                this.refundType = res.refundType;
                this.refundStatus = res.refundStatus;
                if (this.refundType == '2') {
                    this.tipList = [
                        {},
                        {
                            tip: '申请已提交，等待商家处理',
                            dateTip: '退款申请时间',
                            date: res.applyTime,
                        },
                        {
                            tip: '卖家已同意，请尽快将商品邮寄至卖家',
                            dateTip: '商家处理时间',
                            date: res.merchantHandleTime,
                        },
                        {
                            tip: '商品已退回，商家将在收到商品后进行退款',
                            dateTip: '退货时间',
                            date: res.refundGoodsTime,
                        },
                        {
                            tip: '商家已收到退回的商品，正在退款中',
                            dateTip: '商家收货时间',
                            date: res.receivedGoodsTime,
                        },
                        {
                            tip: '退款成功',
                            dateTip: '退款成功时间',
                            date: res.refundTime,
                            money: res.realRefundAmount,
                        },
                        {
                            tip: '退款申请未通过',
                            dateTip: '商家处理时间',
                            date: res.merchantHandleTime,
                        },
                    ];
                } else {
                    this.tipList = [
                        {},
                        {
                            tip: '申请已提交，等待商家处理',
                            dateTip: '退款申请时间',
                            date: res.applyTime,
                        },
                        {},
                        {},
                        {
                            tip: '商家已处理，正在退款中',
                            dateTip: '商家处理时间',
                            date: res.merchantHandleTime,
                        },
                        {
                            tip: '退款成功',
                            dateTip: '退款成功时间',
                            date: res.refundTime,
                            money: res.realRefundAmount,
                        },
                        {
                            tip: '退款申请未通过',
                            dateTip: '商家处理时间',
                            date: res.merchantHandleTime,
                        },
                    ];
                }
                this.refundInfo = res;
                this.productList = res.orderChildList;
            })
            .catch((err) => {
                Order.OrderDetail(this.id).then((res) => {
                    this.refundInfo = res;
                    this.productList = res.orderChildList;
                    this.refundStatus = '0';
                    if (res.orderStatus == '3') {
                        this.refundType = '2';
                        this.tipList = [
                            {},
                            {
                                tip: '申请已提交，等待商家处理',
                                dateTip: '退款申请时间',
                                date: res.applyTime,
                            },
                            {
                                tip: '卖家已同意，请尽快将商品邮寄至卖家',
                                dateTip: '商家处理时间',
                                date: res.merchantHandleTime,
                            },
                            {
                                tip: '商品已退回，商家将在收到商品后进行退款',
                                dateTip: '退货时间',
                                date: res.refundGoodsTime,
                            },
                            {
                                tip: '商家已收到退回的商品，正在退款中',
                                dateTip: '商家收货时间',
                                date: res.receivedGoodsTime,
                            },
                            {
                                tip: '退款成功',
                                dateTip: '退款成功时间',
                                date: res.refundTime,
                                money: res.realRefundAmount,
                            },
                            {
                                tip: '退款申请未通过',
                                dateTip: '商家处理时间',
                                date: res.merchantHandleTime,
                            },
                        ];
                    } else {
                        this.refundType = '1';
                        this.tipList = [
                            {},
                            {
                                tip: '申请已提交，等待商家处理',
                                dateTip: '退款申请时间',
                                date: res.applyTime,
                            },
                            {},
                            {},
                            {
                                tip: '商家已处理，正在退款中',
                                dateTip: '商家处理时间',
                                date: res.merchantHandleTime,
                            },
                            {
                                tip: '退款成功',
                                dateTip: '退款成功时间',
                                date: res.refundTime,
                                money: res.realRefundAmount,
                            },
                            {
                                tip: '退款申请未通过',
                                dateTip: '商家处理时间',
                                date: res.merchantHandleTime,
                            },
                        ];
                    }
                });
                User.GetDataDictionary({
                    parentId: '94b98283-c2e0-2830-fc94-a42a92f63c90',
                    skip: 0,
                    count: -1,
                }).then((res) => {
                    this.regionList = res.data;
                });
            });
    }
    setAllImage(img, name, itemId) {
        this.tuikuanInfo.refundCertificateList = img;
    }
    tijiao() {
        this.$refs.settledFrom.validate((valid) => {
            if (valid) {
                let obj = {
                    orderId: this.id,
                    refundType: this.refundType,
                    refundReason: this.tuikuanInfo.refundReason,
                    applyRefundAmount: this.refundInfo.totalAmount,
                    remark: this.tuikuanInfo.remark,
                    refundCertificateList: this.tuikuanInfo.refundCertificateList,
                };
                RefundOrder.addRefundOrder({ ...obj }).then((res) => {
                    this.$router.push({
                        path: '/refund/detail',
                        query: {
                            id: res,
                        },
                    });
                });
            }
        });
    }
    @Watch('$route')
    public changeRoute(to, from) {
        window.history.back();
    }
    resetfrom() {
        this.rejection = {
            logistics: '',
            logisticsNum: '',
            returnGoodsRemark: '',
        };
        this.$nextTick(() => {
            this.$refs.rejection.resetFields();
        });
    }
    sumitRejection() {
        RefundOrder.addReturnGoods({
            refundOrderId: this.refundInfo.id,
            logistics: this.rejection.logistics,
            logisticsNum: this.rejection.logisticsNum,
            returnGoodsRemark: this.rejection.returnGoodsRemark,
        }).then((res) => {
            this.dialogVisible = false;
            this.init();
        });
    }
}
