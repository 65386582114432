import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace Collection {
    /**
     * 用户是否关收藏商品
     */
    export var isUserCollection = (query: any): Promise<any> => {
        return Get(`/api/Collection/IsUserCollection?${qs.stringify(query)}`, '');
    };
    /**
     * 添加收藏记录
     */
    export var addCollection = (query: any): Promise<any> => {
        return Post(`/api/Collection?${qs.stringify(query)}`, query);
    };
    /**
       * 删除收藏记录
       */
    export var deleteCollection = (query: any): Promise<any> => {
        return Put(`/api/Collection/DeleteByGoodsId?${qs.stringify(query)}`, {});
    };
    /**
       * 批量删除收藏记录
       */
    export var DeleteAll = (query: any): Promise<any> => {
        return Put(`/api/Collection/Delete/${query}`, {});
    };
    /**
       * 收藏的列表
       */
    export var getCollection = (query: any): Promise<any> => {
        return Get(`/api/Collection?${qs.stringify(query)}`, '');
    };
}
