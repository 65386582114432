








































































import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
    components: {},
})
export default class goodsPeriphery extends Vue {
    public userType: string = '';
    recommendData = [];

    setData(data) {
        this.recommendData = data;
    }

    public mounted() {
        this.userType = sessionStorage.getItem('userType');
    }

    toDetail(row) {
        this.$router.push({
            path: '/goodsDetail',
            query: {
                id: row.id,
            },
        });
    }
}
