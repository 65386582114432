import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace Evaluate {
    /**
     * 发表评价
     */
    export var BatchAddEvaluate = (body: any): Promise<any> => {
        return Post(`/api/Evaluate/BatchAddEvaluate`, body);
    };

    /**
     * 评价列表
     */
    export var Evaluate = (query: any): Promise<any> => {
        return Get(`/api/Evaluate?${qs.stringify(query)}`, '');
    };
}
