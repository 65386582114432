































import { Vue, Component } from 'vue-property-decorator';
const gif = require('@/assets/img/401.gif');

@Component
export default class Page401 extends Vue {
    public errGif = gif;
    created() {}
    back() {
        this.$router.back();
    }
    goLogin() {
        sessionStorage.clear();
        location.reload();
        this.$router.push('/login');
    }
}
