







































































import { Vue, Component, Watch } from 'vue-property-decorator';
import DescribePanel from '@/components/DescribePanel.vue';
import { NavBar } from '../home/components';
import ImageNumUpload4 from '@/components/ImageNumUpload4.vue';
import { formRules } from '@/common/util/validator';
import { PostBar } from '@/http';
export interface Pagination {
    skip: number;
    count: number;
    currentPage: number;
}
@Component({
    components: { NavBar, DescribePanel, ImageNumUpload4 },
})
export default class release extends Vue {
    public postTypeList: any[] = [];
    public formRules = formRules;
    public dialogVisible: boolean = false;
    public $refs: {
        ImageUpload: HTMLFormElement;
        DescribePanel: HTMLFormElement;
        form: HTMLFormElement;
    };
    public form: any = {
        titile: '',
        pB_TypeId: '',
        pB_Type: '',
        summary: '',
        content: '',
        coverImagePath: '',
        contentImageList: [],
    };
    setAllImage(img, name, itemId) {
        let coverImage = [];
        if (img && img.length > 0) {
            img.forEach((item) => {
                coverImage.push(item.path);
            });
        }
        this.form.coverImagePath = coverImage.join(',');
    }
    mounted() {
        if (sessionStorage.getItem('id')) {
            this.init();
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
    // 初始化
    async init() {
        let res = await PostBar.GetPostTypeList({
            suitUserType: sessionStorage.getItem('userType'),
            skip: 0,
            count: -1,
        });
        this.postTypeList = res.data;
        if (this.$route.query.id) {
            let detailInfo = await PostBar.GetPostDetailForEdit({
                id: this.$route.query.id,
                userId: sessionStorage.getItem('id'),
            });
            this.form.titile = detailInfo.titile;
            this.form.pB_TypeId = detailInfo.pB_TypeId;
            this.form.pB_Type = detailInfo.pB_Type;
            this.form.summary = detailInfo.summary;
            this.form.content = detailInfo.content;
            this.form.coverImagePath = detailInfo.coverImagePath;
            this.form.contentImageList = detailInfo.contentImageList;
        }
    }
    onSubmit() {
        this.$refs.form.validate((valid) => {
            if (valid) {
                if (this.form.summary == '') {
                    this.form.summary = this.form.content
                        .replace(/<.*?>/g, '')
                        .replace(/[&middot;]|[&nbsp;]/g, '')
                        .replace(/[&ldquo;]/g, '')
                        .replace(/[&rdquo;]/g, '')
                        .substr(0, 100);
                }
                if (this.form.content.replace(/<.*?>/g, '').length > 15000) {
                    this.$message.warning('文字不能多于一万五千字');
                    return;
                }
                if (this.$route.query.id) {
                    PostBar.SavePost({ id: this.$route.query.id, ...this.form }).then((res) => {
                        this.dialogVisible = true;
                    });
                } else {
                    PostBar.AddPost({ ...this.form }).then((res) => {
                        this.dialogVisible = true;
                    });
                }
            }
        });
    }
    jumpInteraction() {
        this.dialogVisible = false;
        this.$router.push({
            path: '/interaction',
        });
    }
    changePostType(id) {
        this.postTypeList.forEach((e) => {
            if (e.id == id) {
                this.form.pB_Type = e.name;
            }
        });
    }
    setImage(name, url) {
        this.form.coverImagePath = url;
    }
    deleteImage() {
        this.form.attachmentName = '';
        this.form.attachmentPath = '';
    }
    dataChange(data) {
        this.form.content = data;
        var content = data;
        let imgReg = /<img.*?(?:>|\/>)/gi; //匹配图片中的img标签
        let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i; // 匹配图片中的src
        let arr = content.match(imgReg); //筛选出所有的img
        if (arr != null) {
            let srcArr = [];
            for (let i = 0; i < arr.length; i++) {
                let src = arr[i].match(srcReg);
                // 获取图片地址
                srcArr.push(src[1]);
            }
            this.form.contentImageList = srcArr;
        }
    }
}
