import Vue from 'vue';
import Router from 'vue-router';
import { Message } from 'element-ui'
const load = (path) => { // 路由懒加载
    return () => Promise.resolve(require(`@/views/${path}`).default)
}
const $import = (path) => { // 直接加载
    return () => import(`@/views/${path}`)
}

const Home = load('home/Home.vue');
/**error 401 */
const Page401 = load('errorPage/401.vue');
const Page404 = load('errorPage/404.vue');
/* 登录页 */
import login from '@/views/login/login.vue'
// 注册页
import register from '@/views/login/register.vue'
// 找回密码页
import forgetPassword from '@/views/login/forgetPassword.vue'
// 提交订单
import submitOrder from '@/views/login/submitOrder.vue'
// 结算中心
import settleCenter from '@/views/login/settleCenter.vue'
// 商户入驻
import merchantsEntering from '@/views/login/merchantsEntering.vue'
/**首页 */
const shouye = load('shouye/shouye.vue');
// 商品列表
const goodsList = load('shouye/goodsList.vue');
// 商品详情
const goodsDetail = load('shouye/goodsDetail.vue');
// 品牌专区
const brand = load('shouye/brand.vue');
// 企业动态
const shopNews = load('shouye/shopNews.vue')
const shopName = load('shouye/shopNews.vue')
// 聚天自营
const own = load('shouye/own.vue');
// 聚天特惠
const preferential = load('shouye/preferential.vue');
// 新品试用
const newProduc = load('shouye/newProduc.vue');
// 聚天特惠商品详情
const preferentialGoodsDetail = load('shouye/preferentialGoodsDetail.vue');
// 确认订单信息
const preferentialInfo = load('login/preferentialInfo.vue');
// 聚天联盟
const union = load('shouye/union.vue');
const interaction = load('shouye/interaction.vue');
const interactionDetail = load('shouye/interactionDetail.vue');
const release = load('shouye/release.vue')
// 店铺主页
const shop = load('shouye/shop.vue');
// 店铺主页更多企业资质
const moreShopQualification = load('shouye/moreShopQualification.vue');
//个人中心
const personal = load('personal/main.vue');
//我的订单
const myOrder = load('personal/order.vue');
//售后
const AfterSale = load('personal/afterSale.vue');
//订单详情
const orderDetail = load('personal/orderDetail.vue');
//退款
const Refund = load('personal/refund.vue');
// 我的收藏
const myCollect = load('personal/myCollect.vue');
// 关注的店铺
const followShops = load('personal/followShops.vue');
//我的评价
const myEvaluate = load('personal/evaluate.vue')
//评价详情
const evaluateDetail = load('personal/evaluateDetail.vue')
//购物车
const shoppingCart = load('personal/shoppingCart.vue')
//收货地址
const receivingAddress = load('personal/receivingAddress.vue')
//我的试用
const trialSaleSalePanel = load('personal/trialSaleSalePanel.vue');
//我的拼购
const shopTogetherPanel = load('personal/shopTogetherPanel.vue');
//安全中心
const securityCenter = load('personal/securityCenter.vue')
const activityList = load('activity/activityList.vue')
const activityDetail = load('activity/activityDetail.vue')
const activityVideo = load('activity/activityVideo.vue')
const activityLive = load('activity/activityLive.vue')
const activity = load('activity/activity.vue')
//服务协议
const service = load('service/main.vue');
//入驻协议
const settleAgreement = load('service/settleAgreement.vue');
const publish = load('personal/publish.vue');
const comment = load('personal/comment.vue');
//门店招商
const attract = load('shouye/attract.vue');
const attractShow = load('shouye/attractShow.vue');



import NProgress from 'nprogress';

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router);
let constRouters = [
    // {
    //     path: '/',
    //     hidden: true,
    //     redirect: {
    //         name: 'home'
    //     }
    // },
    {
        path: '/',
        component: Home,
        leaf: false, //只有一个节点
        name: '首页',
        index: '1',
        meta: {
            keepAlive: true,
            title: '首页'
        },
        children: [
            {
                path: '/',
                name: '首页',
                component: shouye,
                meta: {
                    title: '首页',
                }
            },
            {
                path: '/shop',
                name: '店铺主页',
                component: shop,
                meta: {
                    title: '店铺主页',
                },
            },
            {
                path: '/goodsList',
                name: '商品列表',
                component: goodsList,
                meta: {
                    title: '商品列表',
                }
            },
            {
                path: '/goodsDetail',
                name: '商品详情',
                component: goodsDetail,
                meta: {
                    title: '商品详情',
                }
            },
            {
                path: '/release',
                name: '发布',
                component: release,
                meta: {
                    title: '发布',
                }
            },
            {
                path: '/interactionDetail',
                name: '帖子详情',
                component: interactionDetail,
                meta: {
                    title: '帖子详情',
                }
            },
            {
                path: '/shop/shopNews',
                name: '企业动态',
                leaf: false, //只有一个节点
                component: shopNews,
                meta: {
                    title: '企业动态',
                },
            },
            {
                path: '/moreShopQualification',
                name: '更多资质',
                component: moreShopQualification,
                meta: {
                    title: '更多资质',
                }
            },
        ]
    },
    // {
    //     path: '/order',
    //     component: Home,
    //     leaf: false, //只有一个节点
    //     name: '业务实景',
    //     index: '2',
    //     meta: {
    //         title: '业务实景'
    //     }
    // },
    {
        path: '/brand',
        component: Home,
        icon: 'icontuihuanhuo',
        leaf: false, //只有一个节点
        name: '品牌专区',
        index: '3',
        meta: {
            title: '首页'
        },
        children: [
            {
                path: '/',
                name: '品牌专区',
                icon: 'icontuihuanhuo',
                component: brand,
                meta: {
                    title: '品牌专区',
                },
            },

        ]
    },
    {
        path: '/interaction',
        component: Home,
        leaf: false, //只有一个节点
        name: '聚天贴吧',
        index: '6',
        meta: {
            title: '首页'
        },
        children: [
            {
                path: '/',
                name: '聚天贴吧',
                component: interaction,
                meta: {
                    title: '聚天贴吧',
                },

            },
        ]
    },
    {
        path: '/attract',
        component: Home,
        leaf: false, //只有一个节点
        name: '门店招商',
        index: '4',
        meta: {
            title: '首页'
        },
        children: [
            {
                path: '/attract',
                name: '门店招商',
                component: attract,
                meta: {
                    title: '门店招商',
                }
            },
            // {
            //     path: '/shop',
            //     name: '店铺主页',
            //     component: shop,
            //     meta: {
            //         title: '店铺主页',
            //     },
            // },
            // {
            //     path: '/shop/shopNews',
            //     name: '企业动态',
            //     leaf: false, //只有一个节点
            //     component: shopNews,
            //     meta: {
            //         title: '企业动态',
            //     },
            // },
            // {
            //     path: '/moreShopQualification',
            //     name: '更多资质',
            //     component: moreShopQualification,
            //     meta: {
            //         title: '更多资质',
            //     }
            // },

        ]
    },
    {
        path: '/preferential',
        component: Home,
        leaf: false, //只有一个节点
        name: '聚天特惠',
        index: '5',
        meta: {
            title: '首页'
        },
        children: [
            {
                path: '/',
                name: '聚天特惠',
                component: preferential,
                meta: {
                    title: '聚天特惠',
                },
            },
            {
                path: '/newProduc',
                name: '新品试用',
                component: newProduc,
                meta: {
                    title: '新品试用',
                },
            },
            {
                path: '/specialOff',
                name: '特价甩卖',
                component: newProduc,
                meta: {
                    title: '特价甩卖',
                },
            },
            {
                path: '/shopTogether',
                name: '限时拼购',
                component: newProduc,
                meta: {
                    title: '限时拼购',
                },
            },
            {
                path: '/preferentialGoodsDetail',
                name: '商品详情',
                component: preferentialGoodsDetail,
                meta: {
                    title: '商品详情',
                },
            },
        ]
    },

    {
        path: '/union',
        component: Home,
        leaf: false, //只有一个节点
        name: '聚天联盟',
        index: '7',
        meta: {
            title: '首页'
        },
        children: [
            {
                path: '/',
                name: '聚天联盟',
                component: union,
                meta: {
                    title: '聚天联盟',
                }
            },
        ]
    },
    {
        path: '/attractShow',
        name: 'attract',
        component: attractShow,
        hidden: true,
        meta: {
            title: '门店招商'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: login,
        hidden: true,
        meta: {
            title: '从节点登录'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: register,
        hidden: true,
        meta: {
            title: '注册'
        }
    },
    {
        path: '/forgetPassword',
        name: 'forgetPassword',
        component: forgetPassword,
        hidden: true,
        meta: {
            title: '找回密码'
        }
    },
    {
        path: '/submitOrder',
        name: 'submitOrder',
        component: submitOrder,
        hidden: true,
        meta: {
            title: '提交订单'
        }
    },
    {
        path: '/preferentialInfo',
        name: '确认订单信息',
        component: preferentialInfo,
        hidden: true,
        meta: {
            title: '确认订单信息',
        },
    },
    {
        path: '/settleCenter',
        name: 'settleCenter',
        component: settleCenter,
        hidden: true,
        meta: {
            title: '结算中心'
        }
    },
    {
        path: '/merchantsEntering',
        name: 'merchantsEntering',
        component: merchantsEntering,
        hidden: true,
        meta: {
            title: '商户入驻'
        }
    },
    {
        path: '/personal',
        component: Home,
        leaf: false,    //只有一个节点
        name: '首页',
        hidden: true,
        meta: {
            title: '首页'
        },
        children: [
            {
                path: '/personal/personalizar',
                name: '个人中心',
                component: personal,
                meta: {
                    title: '个人中心',
                },
                children: [
                    {
                        path: '/personal/order',
                        name: '我的订单',
                        component: myOrder,
                        meta: {
                            title: '我的订单',
                        },
                    },
                    {
                        path: '/personal/afterSale',
                        name: '退款售后',
                        component: AfterSale,
                        meta: {
                            title: '退款售后'
                        }
                    },
                    {
                        path: '/personal/evaluateDetail',
                        name: '我的评价',
                        component: evaluateDetail,
                        meta: {
                            title: '我的评价'
                        }
                    },
                    {
                        path: '/personal/shoppingCart',
                        name: '购物车',
                        component: shoppingCart,
                        meta: {
                            title: '购物车'
                        }
                    },
                    {
                        path: '/personal/receivingAddress',
                        name: '收货地址',
                        component: receivingAddress,
                        meta: {
                            title: '收货地址'
                        }
                    },
                    {
                        path: '/personal/securityCenter',
                        name: '安全中心',
                        component: securityCenter,
                        meta: {
                            title: '安全中心'
                        }
                    },
                    {
                        path: '/collect/myCollect',
                        name: '收藏的商品',
                        component: myCollect,
                        meta: {
                            title: '收藏的商品',
                        }
                    },
                    {
                        path: '/collect/followShops',
                        name: '关注的店铺',
                        component: followShops,
                        meta: {
                            title: '关注的店铺',
                        }
                    },
                    {
                        path: '/invitation/publish',
                        name: '我发布的',
                        component: publish,
                        meta: {
                            title: '我发布的',
                        }
                    },
                    {
                        path: '/invitation/comment',
                        name: '我评价的',
                        component: comment,
                        meta: {
                            title: '我评价的',
                        }
                    },
                    {
                        path: '/personal/trialSaleSalePanel',
                        name: '我的试用',
                        component: trialSaleSalePanel,
                        meta: {
                            title: '我的试用'
                        }
                    },
                    {
                        path: '/personal/shopTogetherPanel',
                        name: '我的拼购',
                        component: shopTogetherPanel,
                        meta: {
                            title: '我的拼购'
                        }
                    },
                ]
            },
        ]
    },
    {
        path: '/orderDetail',
        component: Home,
        leaf: false,    //只有一个节点
        name: '个人中心',
        hidden: true,
        meta: {
            title: '个人中心'
        },
        children: [
            {
                path: '/orderDetail/detail',
                name: '订单详情',
                component: orderDetail,
                meta: {
                    title: '订单详情',
                }
            },
            {
                path: '/orderDetail/evaluate',
                name: '发表评价',
                component: myEvaluate,
                meta: {
                    title: '发表评价'
                }
            },
        ]
    },
    {
        path: '/refund',
        component: Home,
        leaf: false,    //只有一个节点
        name: '个人中心',
        hidden: true,
        meta: {
            title: '个人中心'
        },
        children: [
            {
                path: '/refund/detail',
                name: '退款详情',
                component: Refund,
                meta: {
                    title: '退款详情',
                }
            },
        ]
    },
    {
        path: '/activity',
        component: Home,
        leaf: false,    //只有一个节点
        name: '首页',
        hidden: true,
        meta: {
            title: '首页'
        },
        children: [
            {
                path: '/activity/activity',
                name: '平台活动',
                component: activity,
                meta: {
                    title: '平台活动',
                },
                children: [
                    {
                        path: '/activity/activityList',
                        name: '活动中心',
                        component: activityList,
                        meta: {
                            title: '活动中心',
                        }
                    },
                    {
                        path: '/activity/activityDetail',
                        name: '活动详情',
                        component: activityDetail,
                        meta: {
                            title: '活动详情',
                        }
                    },
                    {
                        path: '/activity/activityVideo',
                        name: '视频中心',
                        component: activityVideo,
                        meta: {
                            title: '视频中心',
                        }
                    },
                    {
                        path: '/activity/activityLive',
                        name: '聚天直播间',
                        component: activityLive,
                        meta: {
                            title: '聚天直播间',
                        }
                    }
                ]
            },

        ]
    },
    {
        path: '/service',
        component: Home,
        leaf: false,    //只有一个节点
        name: '首页',
        hidden: true,
        meta: {
            title: '首页'
        },
        children: [
            {
                path: '/service/personalizar',
                name: '商家服务',
                component: service,
                meta: {
                    title: '商家服务',
                },
                children: [
                    {
                        path: '/service/settleNotice',
                        name: '入驻须知',
                        component: settleAgreement,
                        meta: {
                            title: '入驻须知',
                        },
                    },
                    {
                        path: '/service/settleAgreement',
                        name: '入驻协议',
                        component: settleAgreement,
                        meta: {
                            title: '入驻协议',
                        },
                    },
                ]
            },
            {
                path: '/service/member',
                name: '会员服务',
                component: service,
                meta: {
                    title: '会员服务',
                },
                children: [
                    {
                        path: '/service/clause',
                        name: '服务条款',
                        component: settleAgreement,
                        meta: {
                            title: '服务条款',
                        },
                    },
                    {
                        path: '/service/policy',
                        name: '隐私政策',
                        component: settleAgreement,
                        meta: {
                            title: '隐私政策',
                        },
                    },
                ]
            },
            {
                path: '/service/shopping',
                name: '购物指南',
                component: service,
                meta: {
                    title: '购物指南',
                },
                children: [
                    {
                        path: '/service/process',
                        name: '购物流程',
                        component: settleAgreement,
                        meta: {
                            title: '购物流程',
                        },
                    },
                    {
                        path: '/service/pay',
                        name: '支付方式',
                        component: settleAgreement,
                        meta: {
                            title: '支付方式',
                        },
                    },
                ]
            },
            {
                path: '/service/servicePolicy',
                name: '服务政策',
                component: service,
                meta: {
                    title: '服务政策',
                },
                children: [
                    {
                        path: '/service/distribution',
                        name: '配送服务',
                        component: settleAgreement,
                        meta: {
                            title: '配送服务',
                        },
                    },
                    {
                        path: '/service/afterSales',
                        name: '售后服务',
                        component: settleAgreement,
                        meta: {
                            title: '售后服务',
                        },
                    },
                ]
            },
            {
                path: '/service/about',
                name: '关于我们',
                component: service,
                meta: {
                    title: '关于我们',
                },
                children: [
                    {
                        path: '/service/briefIntroduction',
                        name: '公司简介',
                        component: settleAgreement,
                        meta: {
                            title: '公司简介',
                        },
                    },
                    {
                        path: '/service/contact',
                        name: '联系我们',
                        component: settleAgreement,
                        meta: {
                            title: '联系我们',
                        },
                    },
                ]
            },
        ]
    },
];
let permissonRoutes = [
];

const router = new Router({
    routes: constRouters,
    mode: 'hash'
});
/**
 * 过滤路由
 */
function hasPermission(roles, route) {
    if (route.meta && route.meta.auth) {
        return roles.some(role => route.meta.auth.includes(role));
    } else {
        return true;
    }
}
/**
 * 递归查询
 */
function filterAsyncRoutes(routes, roles) {
    const res = [];
    routes.forEach(route => {
        const tmp = { ...route };
        if (hasPermission(roles, tmp)) {
            if (tmp.children) {
                tmp.children = filterAsyncRoutes(tmp.children, roles);
            }
            res.push(tmp);
        }
    });
    return res;
}

function hasRoute(routes, path) {
    if (JSON.stringify(routes).includes(path)) {
        return true;
    } else {
        return false;
    }
}
// 免登录路径
const whitelist = ['/', '/login'];
// 避免无权限时重复生成路由
var filterRoutes = [];

router.beforeEach(async (to, from, next) => {
    NProgress.start()
    const hasToken = sessionStorage.getItem('jwtToken');
    if (hasToken) {
        // if (to.path === '/login') {
        next()
        //   NProgress.done()
        // } else {
        //   if (filterRoutes.length == 0) {
        //     try {
        //       var authlist = sessionStorage.getItem("permissions") ? sessionStorage.getItem("permissions").split(",") : [];
        //       filterRoutes = filterAsyncRoutes(permissonRoutes, authlist);
        //       router["options"].routes = [...constRouters, ...filterRoutes];
        //       router.addRoutes(filterRoutes);
        //       next({ ...to });
        //     } catch (err) {
        //       Message.error(err || 'Verification failed, please login again')
        //       next(`/login?redirect=${to.path}`)
        //       sessionStorage.clear();
        //       NProgress.done()
        //     }
        //   } else {
        //     next()
        //   }
        // }
    } else {
        next();
        // if (whitelist.indexOf(to.path) !== -1) {
        //     next()
        // } else {
        //     next({ path: `/login?redirect=${to.path}`, replace: true })
        //     NProgress.done()
        // }
    }
})

router.afterEach((to, from) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    NProgress.done();
});

// 解决Loading chunk (\d)+ failed问题
router.onError((error) => {
    console.error(error)
    const pattern = /Loading chunk/g
    const isChunkLoadFailed = error.message.match(pattern)
    console.log('router_error', router)
    const targetPath = (router as any).history.pending.fullPath
    if (isChunkLoadFailed && error['type'] === 'missing') {
        router.push(targetPath)
    }
})
export default router;
