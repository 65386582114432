






























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class SearchPanel extends Vue {
    /**
     * 将搜索按钮显示在上面一排
     */
    @Prop({
        default: false,
    })
    public isTop: boolean;
    @Prop({
        default: true,
    })
    public isReset: boolean;
    @Prop({
        default: false,
    })
    public loading: boolean;
}
