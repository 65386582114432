/**
 * http配置
 */

import axios from 'axios';
import store from '../vuex/modules/login';
import { MessageBox, Message } from 'element-ui';
import router from '../router';
import { downloadFile } from '@/common/util';

function buildHeader(): { [key: string]: string } {
    return {
        'Content-Type': 'application/json;charset=UTF-8;multipart/form-data'
    };
}
export function host(): string {
    if (window.location.hostname == 'localhost' || window.location.hostname.indexOf('192.168') > -1) {
        // return '/'; //代理
        // return 'http://192.168.3.26:5000/'; //孙天宇
        // return 'http://192.168.3.40:5001/'; //徐雪
        // return 'http://192.168.3.71:5000/'; //陈旭
        // return 'https://jutiangas.com/'; //线上
        return 'https://test.jutiangas.com/'; //测试服
    } else {
        return '/';
    }
}
export let ax = axios.create({
    baseURL: host(),
    headers: buildHeader(),
    timeout: 300000,
    responseType: 'json',
    withCredentials: false,
    transformRequest: [
        function (data) {
            if (data instanceof FormData) return data;
            return JSON.stringify(data);
        }
    ],
    transformResponse: [
        function (data) {
            if (data) {
                var data = typeof data == 'object' ? data : JSON.parse(data); //兼容IE
                return data;
            } else {
                let msg = 'Unknow Error';
                throw new Error(msg);
            }
        }
    ],
    // `onUploadProgress`允许处理上传的进度事件
    onUploadProgress: function (progressEvent) {
        // 使用本地 progress 事件做任何你想要做的
    },
    // `onDownloadProgress`允许处理下载的进度事件
    onDownloadProgress: function (progressEvent) {
        // Do whatever you want with the native progress event
    }
});


// http request 拦截器
ax.interceptors.request.use(
    config => {
        store.state.jwtToken = sessionStorage.getItem('jwtToken');

        if (store.state.jwtToken) {
            config.headers.Authorization = `Bearer ${store.state.jwtToken}`;
        }
        return config;
    },
    err => {
        // return Promise.reject(err);
    }
);

// http response 拦截器
ax.interceptors.response.use(
    response => {
        if (!response.data.success) {
            switch (response.data.errorCode) {
                case '-1':
                    break;
                case '4005':
                    MessageBox.alert(`${response.data.errorMessage}`, '提示', {
                        confirmButtonText: '确定',
                        type: 'warning',
                        callback: action => {
                            window.history.back()
                        }
                    });
                    break;
                case '4004':
                    MessageBox.alert(`${response.data.errorMessage}`, '提示', {
                        confirmButtonText: '确定',
                        type: 'warning',
                        callback: action => {
                            window.history.back()
                        }
                    });
                    break;
                case '4006':
                    MessageBox.alert(`${response.data.errorMessage}`, '提示', {
                        confirmButtonText: '确定',
                        type: 'warning',
                        callback: action => {
                            window.history.back()
                        }
                    });
                    break;
                case '4002':
                    MessageBox.alert(`${response.data.errorMessage}`, '提示', {
                        confirmButtonText: '确定',
                        type: 'warning',
                        callback: action => {
                            window.history.back()
                        }
                    });
                    break;
                case '1013':
                case '100003':
                case '100004':
                case '100005':
                case '100006':
                case '100007':
                case '100008':
                    MessageBox.alert(`${response.data.errorMessage}`, '提示', {
                        confirmButtonText: '确定',
                        type: 'warning',
                        callback: action => {
                            MessageBox.close();
                            sessionStorage.clear();
                            router.push('/login');
                        }
                    });
                    break;
                default:
                    MessageBox.alert(`${response.data.errorMessage}`, '提示', {
                        confirmButtonText: '确定',
                        type: 'warning',
                        callback: action => {
                            MessageBox.close();
                        }
                    });
                    return Promise.reject(response.data.errorMessage);
            }

        } else {
            return response.data;
        }
    },
    error => {
        if (error.code === 'ECONNABORTED') {
            MessageBox.alert('接口超时！', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    MessageBox.close();
                }
            });
        }
        if (error.response) {
            switch (error.response.status) {
                case 502:
                    MessageBox.alert('后台发布中，请稍候！', '提示', {
                        confirmButtonText: '确定',
                        callback: action => {
                            MessageBox.close();
                        }
                    });
                    break;
            }
        }
    }
);

/* 手动取消请求的不显示报错 */
function handleError(err) {
    // 如果是手动取消的请求，不显示错误信息
    // console.log('handleError1', err);
    // MessageBox.alert(`${err}`, '提示', {
    //     confirmButtonText: '确定',
    //     type: 'warning',
    //     callback: action => {
    //         MessageBox.close();
    //     }
    // });
}

/* GET  */
export function Get<T>(url, data): Promise<any> {
    // `params`是要与请求一起发送的URL参数
    // 必须是纯对象或URLSearchParams对象
    return ax
        .get(url, {
            params: data
        })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            handleError(err);
            throw err;
        });
}

//下载excel
export function Download<T>(url, data): Promise<any> {
    return axios({
        baseURL: host(),
        url: url,
        params: data,
        headers: { Authorization: `Bearer ${store.state.jwtToken}` },
        method: 'get',
        responseType: 'arraybuffer' //二进制流
    })
        .then(res => {
            downloadFile(res);
        })
        .catch(err => {
            handleError('导出失败');
        });
}

//上传流文件
export function Import<T>(url, data): Promise<any> {
    return ax
        .post(url, data, {
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            handleError(err);
            throw err;
        });
}

/* POST */
export function Post<T>(url, data): Promise<any> {
    return ax
        .post(url, data)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            handleError(err);
            throw err;
        });
}
/* PUT */
export function Put<T>(url, data): Promise<any> {
    return ax
        .put(url, data)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            handleError(err);
            throw err;
        });
}
/* PATCH */
export function Patch<T>(url, data): Promise<any> {
    return ax
        .patch(url, data)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            handleError(err);
            throw err;
        });
}
/* DELETE */
export function Delete<T>(url, data): Promise<any> {
    return ax
        .delete(url, data)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            handleError(err);
            throw err;
        });
}
