/*
 * @Description:
 * @Autor: jyf
 * @Date: 2021-04-27 15:42:27
 * @LastEditors: jyf
 * @LastEditTime: 2021-04-28 14:41:17
 */
import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace Goods {
    /**
     * 获取商品列表
     */
    export var GetGoods = (query: any): Promise<any> => {
        return Get(`/api/Goods?${qs.stringify(query)}`, '');
    };
    /**
     * 获取商品列表/api/Goods/simplifyList
     */
    export var simplifyList = (query: any): Promise<any> => {
        return Get(`/api/Goods/simplifyList?${qs.stringify(query)}`, '');
    };
    /**
     * 获取商品详情
     */
    export var GetGoodsDetail = (id: any): Promise<any> => {
        return Get(`/api/Goods/${id}`, '');
    };
    /**
     * 商品推荐
     */
    export var GetRecommend = (query: any): Promise<any> => {
        return Get(`/api/Goods/recommend?${qs.stringify(query)}`, '');
    };
    /**
     * 获取商品一级分类Id集合
     */
    export var GetGoodsTypeOneIdList = (query: any): Promise<any> => {
        return Get(`/api/Goods/GetGoodsTypeOneIdList?${qs.stringify(query)}`, '');
    };
}
