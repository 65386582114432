


































import { Vue, Component } from 'vue-property-decorator';
import { NavBar } from '@/views/home/components';
@Component({
    components: {
        NavBar,
    },
})
export default class activity extends Vue {}
