



































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Order } from '@/http';
@Component({
    components: {},
})
export default class orderInfo extends Vue {
    @Prop()
    public line: boolean;

    public id = '';
    public orderData: any = {};
    public mounted() {}

    setData(data) {
        this.id = data;
        Order.OrderDetail(this.id).then((res) => {
            this.orderData = res;
        });
    }
    public async handleClick(tab, event) {}
    lianxi(item) {
        if (sessionStorage.getItem('id')) {
            this.$root.$emit('lianxi', item.orderChildList[0].shopId);
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }
}
