import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace RefundOrder {
    /**
     * 添加退款单
     */
    export var addRefundOrder = (body: any): Promise<any> => {
        return Post(`/api/RefundOrder`, body);
    };
    /**
     * 获取退款单列表
     */
    export var getReceiverInfo = (query: any): Promise<any> => {
      return Get(`/api/RefundOrder?${qs.stringify(query)}`, '');
    };
    /**
     * 获取退款单详情
     */
    export var getRefundOrder = (id: any): Promise<any> => {
      return Get(`/api/RefundOrder/${id}`, '');
    };
    /**
     * 添加退货记录
     */
    export var addReturnGoods = (body: any): Promise<any> => {
      return Post(`/api/ReturnGoods`, body);
    };
}
