








































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { formRules } from '@/common/util/validator';
import { User } from '@/http';

interface MapAddress {
    code: string;
    name: string;
    fullName: string;
    parentCode: string;
    parentName: string;
    type: string;
}

// 表示所选
export interface SelectAddress {
    province?: MapAddress;
    city?: MapAddress;
    area?: MapAddress;
}

@Component({
    components: {},
})
export default class AddressSelect extends Vue {
    @Prop({
        default: false,
    })
    public reset: boolean;
    public selectRest = null;
    @Prop({
        default: '',
    })
    public disabledStatus: any;
    @Prop()
    public searchTerm: boolean;
    public formRules = formRules;
    public $refs: {
        form: HTMLFormElement;
    };
    public form = {
        selectProvince: '',
        selectCity: '',
        selectArea: '',
    };
    public provinceArray: MapAddress[] = [];
    public cityOptions: MapAddress[] = [];
    public areaOptions: MapAddress[] = [];
    public selectProvince = '';
    public selectCity = '';
    public selectArea = '';

    @Prop()
    public value: SelectAddress;

    @Prop({
        default: false,
    })
    public notUseArea: boolean;

    @Prop({
        default: false,
    })
    public isplaceholder: boolean;

    public isplaceholderStatus = null;

    @Watch('isplaceholder')
    public async getPlaceholder(state: boolean) {}

    public selfValue: MapAddress[] = [];

    @Watch('reset')
    public async reloadSelect(val: boolean) {
        if (val) {
            console.log('valvalval', val);

            await this.init();
            this.$emit('updateReset');
        }
    }

    @Watch('value')
    public async reloadValue(v) {
        await this.init();
    }

    setValue(data) {
        this.value = data;
        this.init();
    }

    public clear() {
        this.form = {
            selectProvince: '',
            selectCity: '',
            selectArea: '',
        };
        this.$refs['form'].resetFields();
    }

    public created() {
        this.selectRest = this.reset;
        this.isplaceholderStatus = this.isplaceholder;
        if (this.selectRest) {
            this.init();
        }
    }

    // 初始化
    public async init() {
        await this.getProvince();
        if (this.value) {
            if (this.value.province.code) {
                this.form.selectProvince = this.value.province.code;
            } else {
                this.form.selectProvince = this.value.province.code;
            }
            if (this.value.city.code) {
                await this.getCityList(this.value.province.code);
                this.form.selectCity = this.value.city.code;
            } else {
                this.form.selectCity = this.value.city.code;
            }
            if (this.value.area.code) {
                await this.getCountyList(this.value.city.code);
                this.form.selectArea = this.value.area.code;
            } else {
                this.$refs['form'].resetFields();
                this.form.selectArea = this.value.area.code;
            }
        }
    }

    async getProvince() {
        let area = await User.GetZone({
            type: 1,
            code: '',
            parentCode: '',
            skip: 0,
            count: -1,
        });
        this.provinceArray = area.data;
    }

    async getCityList(code) {
        let city = await User.GetZone({
            type: 2,
            code: '',
            parentCode: code,
            skip: 0,
            count: -1,
        });
        this.cityOptions = city.data;
    }

    async getCountyList(code) {
        let County = await User.GetZone({
            type: 3,
            code: '',
            parentCode: code,
            skip: 0,
            count: -1,
        });
        this.areaOptions = County.data;
    }

    public getProvinceAddress(id: string): MapAddress {
        for (let item of this.provinceArray) {
            if (id === item.code) {
                return item;
            }
        }
    }

    public getCityAddress(id: string): MapAddress {
        for (let item of this.cityOptions) {
            if (id === item.code) {
                return item;
            }
        }
    }

    public getAreaAddress(id: string): MapAddress {
        for (let item of this.areaOptions) {
            if (id === item.code) {
                return item;
            }
        }
    }

    // 选择省
    public provinceChange(selectValue) {
        this.cityOptions = [];
        this.areaOptions = [];
        this.form.selectCity = '';
        this.form.selectArea = '';
        if (this.form.selectProvince != '') {
            this.getCityList(this.form.selectProvince);
        }
        let name = '';
        this.provinceArray.forEach((e) => {
            if (this.form.selectProvince == e.code) {
                name = e.name;
            }
        });
        this.$emit('provincePrompts', `${this.form.selectProvince}`, `${name}`);
    }

    // 选市
    public cityChange(selectValue) {
        if (this.form.selectCity != '') {
            this.getCountyList(this.form.selectCity);
        }
        this.areaOptions = [];
        this.form.selectArea = '';
        let name = '';
        this.cityOptions.forEach((e) => {
            if (this.form.selectCity == e.code) {
                name = e.name;
            }
        });
        this.$emit('cityPrompts', `${this.form.selectCity}`, `${name}`);
    }

    // 选区
    public areaChange(selectValue) {
        let name = '';
        this.areaOptions.forEach((e) => {
            if (this.form.selectArea == e.code) {
                name = e.name;
            }
        });
        this.$emit('areaPrompts', `${this.form.selectArea}`, `${name}`);
    }

    /* 触发地址组件的校验 */
    public validateForm() {
        let flag = false;
        this.$refs.form.validate((valid) => {
            flag = valid;
        });
        return flag;
    }
}
