































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { NavBar } from '../home/components';
import goodsPeriphery from './components/goodsPeriphery.vue';
import { Goods, Order, User, Cart, RefundOrder } from '@/http';
// import myFunc from '@/utils/index';
import myFuncTime from '@/utils/time';
import { formRules } from '@/common/util/validator';

@Component({
    components: {
        NavBar,
        goodsPeriphery,
    },
})
export default class OrderDetail extends Vue {
    public payMethodForm: any = {
        payMethod: '',
    };
    public $refs: {
        goodsPeriphery: HTMLFormElement;
    };

    public goodsList: any[] = [];
    settle() {
        this.$router.push({
            path: '/settleCenter',
            query: {
                id: this.orderDetail.id,
            },
        });
    }

    id: any = '';
    orderDetail: any = {};

    reason = '';
    reasonDialog = false;
    reasonData = [];
    cancelId = '';
    cancelOrder(id) {
        this.cancelId = id;
        this.reasonDialog = true;
        this.reasonData = [];
        this.reason = '';
        User.GetDataDictionary({
            parentId: '3d2e4da3-c4a9-0d2a-b968-78946f73fcfc',
            skip: 0,
            count: -1,
        }).then((res) => {
            this.reasonData = res.data;
        });
    }

    doCancel() {
        if (!this.reason) {
            this.$alert('请选择原因');
            return;
        }
        Order.SaveOrderStatus({
            cancelReason: this.reason,
            id: this.cancelId,
            orderStatus: 5,
        }).then(() => {
            this.$message.success('取消成功');
            this.reasonDialog = false;
            this.init();
        });
    }

    handleReasonDialogClose() {
        this.reasonDialog = false;
    }

    showRefund(item) {
        this.$router.push({
            path: '/refund/detail',
            query: {
                id: item.orderChildList[0].refundOrderId,
            },
        });
    }

    toDetail(row) {
        this.$router.push({
            path: '/goodsDetail',
            query: {
                id: row.goodsId,
            },
        });
    }
    refundOrder(item) {
        this.$router.push({
            path: '/refund/detail',
            query: {
                id: item.id,
            },
        });
    }
    logisticsInfo: any = {
        trackList: [
            {
                time: '2021-04-12 15:15:41',
                remark: '派车中',
            },
            {
                time: '2021-04-12 20:15:41',
                remark: '装车中',
            },
            {
                time: '2021-04-12 20:15:41',
                remark: '装车中',
            },
        ],
    };

    public created() {}

    lianxi(item) {
        if (sessionStorage.getItem('id')) {
            this.$root.$emit('lianxi', item.orderChildList[0].shopId);
        } else {
            this.$router.push({
                path: '/login',
            });
        }
    }

    /**获取剩余时间 */
    getRemainTime(time) {
        console.log('ww', myFuncTime.getFullDate(new Date()));
        return myFuncTime.getRemainTime(time);
    }

    confirmReceipt() {
        this.$confirm('确认收货?')
            .then(() => {
                Order.SaveOrderStatus({
                    cancelReason: '',
                    id: this.orderDetail.id,
                    orderStatus: 4,
                }).then(() => {
                    this.init();
                });
            })
            .catch(() => {});
    }

    public mounted() {
        this.id = this.$route.query.id;
        this.init();
    }

    goodsIds = [];
    goodsIdsStr = '';
    goodsTypeOneId = '';
    recommendData = [];
    public init() {
        Order.OrderDetail(this.id).then((res) => {
            this.goodsIdsStr = '';
            this.goodsIds = [];
            this.orderDetail = res;
            if (res.orderChildList.length > 0) {
                res.orderChildList.forEach((item) => {
                    this.goodsIds.push(item.goodsId);
                });
                this.goodsIdsStr = this.goodsIds.join(',');
            }
            if (this.orderDetail.orderStatus == 3 || this.orderDetail.orderStatus == 4) {
                Order.GetOrderLogList({
                    itemCode: this.orderDetail.logisticsNum,
                }).then((res) => {
                    this.logisticsInfo = res.data;
                });
            }

            Goods.GetGoodsTypeOneIdList({
                goodsIds: this.goodsIdsStr,
            }).then((res) => {
                Goods.GetRecommend({
                    goodsTypeOneId: res.extData[0],
                    notGoodsId: this.goodsIdsStr,
                    isGoodsAround: true,
                    skip: 0,
                    count: 10,
                }).then((r) => {
                    this.recommendData = r.data;
                    this.$nextTick(() => {
                        this.$refs.goodsPeriphery.setData(this.recommendData);
                    });
                });
            });
        });
    }
    public refund() {
        this.$router.push(`/refund/detail`);
    }
    pinglun() {
        this.$router.push({
            path: '/orderDetail/evaluate',
            query: {
                id: this.id,
            },
        });
    }
}
