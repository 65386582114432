/*
 * @Description:
 * @Autor: jyf
 * @Date: 2021-04-27 15:42:27
 * @LastEditors: jyf
 * @LastEditTime: 2021-04-28 11:26:32
 */
import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace JuTianUnion {
  /**
   * 获取商品列表
   */
  export var GetJuTianUnion = (query: any): Promise<any> => {
    return Get(`/api/JuTianUnion?${qs.stringify(query)}`, {});
  };


}