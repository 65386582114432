











































































































































































import { Vue, Watch, Component } from 'vue-property-decorator';
import TIM from 'tim-js-sdk';
import TIMUploadPlugin from 'tim-upload-plugin';
import { genTestUserSig } from '@/utils/GenerateTestUserSig.js';
import { emojiMap, emojiUrl } from '@/assets/emj';

@Component({
    components: {},
    filters: {
        //接收时间
        offTime: function (value) {
            if (value == 0) return '';
            var v = value;
            var date = new Date(v * 1000);
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() + ':';
            var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            let dt = new Date(Date.parse(new Date().toString()));
            let current = dt.getFullYear() + '-' + dt.getMonth() + '-' + dt.getDate();
            let system = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
            return Y + M + D + '   ' + h + m + s;
        },
    },
})
export default class Message extends Vue {
    public tim;
    public userSig;
    dialogVisible = false;
    ShowPopover = false;
    innerVisible = false;
    addUserID = '';
    IMId = '';

    defaultAvatar = 'https://sinostoragedev.oss-cn-hangzhou.aliyuncs.com/fms/6024AC86-0BB3-4AEB-BB27-D22C8A2127F9.png'; //默认头像,如果用户没有上传头像或者头像路径错误展示这个路径
    myAvatar = 'https://sinostoragedev.oss-cn-hangzhou.aliyuncs.com/fms/6024AC86-0BB3-4AEB-BB27-D22C8A2127F9.png';
    chatName = ''; //查看的某个人name
    toUserId = ''; //查看的某个人id
    avatar = ''; //查看的某个人avatar
    lookup = ''; //用户查找的名字
    isActive = '1';
    loading = false; //加载中
    textarea = ''; //输入信息
    rListOff = false;
    rRightOff = false;
    emojiMap = emojiMap;
    emojiUrl = emojiUrl;
    rList: any = []; //会话列表
    hList: any = []; //具体信息
    showPopover = false;
    unreadCount = 0;

    public $refs: {
        imagePicker: HTMLFormElement;
    };

    @Watch('rList', { immediate: false, deep: true })
    public rListChange() {
        this.unreadCount = 0;
        if (this.rList.length > 0) {
            this.rList.forEach((item) => {
                if (item.unreadCount && item.unreadCount > 0) {
                    this.unreadCount++;
                }
            });
        }
    }
    @Watch('hList', { immediate: false, deep: true })
    public rhListChange() {
        if (this.hList.length > 0) {
            this.rList.forEach((item) => {
                if (item.unreadCount && item.unreadCount > 0) {
                    this.unreadCount++;
                }
            });
        }
    }

    handleConfirm() {
        // if (this.addUserID !== '@TIM#SYSTEM') {
        //     this.tim
        //         .getConversationProfile(`C2C${this.addUserID}`)
        //         .then((res) => {
        //             if (this.rList.length > 0) {
        //                 this.rList.forEach((item) => {
        //                     console.log('item.conversationID' + item.conversationID);
        //                     console.log(' res.data.conversation.conversationID' + res.data.conversation.conversationID);
        //                     if (item.conversationID != res.data.conversation.conversationID) {
        //                         this.rList.push(res.data.conversation);
        //                     }
        //                 });
        //             }
        //             this.innerVisible = false;
        //         })
        //         .catch(() => {
        //             this.$confirm('没有找到该用户');
        //         });
        // }
        this.addUserID = '';
    }

    addNew() {
        this.innerVisible = true;
    }

    showTalk() {
        this.dialogVisible = true;
        this.cLookup();
    }

    //消息查看更多
    seeMore() {
        const self = this;
        let nextReqMessageID = self.hList.nextReqMessageID;
        let promise = self.tim.getMessageList({ conversationID: self.hList.messageList[0].conversationID, nextReqMessageID, count: 15 });
        promise.then(function (imResponse) {
            self.hList.messageList = [...imResponse.data.messageList, ...self.hList.messageList];
            self.hList.nextReqMessageID = imResponse.data.nextReqMessageID; // 分页
            self.hList.isCompleted = imResponse.data.isCompleted; // 是否已经拉完
            if (self.hList) self.rRightOff = true;
        });
    }
    //发送表情
    setEmj(val) {
        let obj = this.emojiMap;
        let findKey = (value, compare = (a, b) => a === b) => {
            return Object.keys(obj).find((k) => compare(obj[k], value));
        };
        this.ShowPopover = false;
        this.textarea = this.textarea.concat(findKey(val));
    }
    //接收表情判断
    emj(value) {
        let emImg;
        for (let key in emojiMap) {
            emImg = emojiUrl + emojiMap[key];
            while (value.indexOf(key) != '-1') {
                value = value.replace(key, `<img style='width:25px;' src=${emImg}>`);
            }
        }
        return value;
    }

    loginOut() {
        console.log('注销了');
        this.tim.logout(() => {
            console.log('注销成功了了');
        });
    }
    //查找用户 模糊查找
    cLookup() {
        const self = this;
        let promise = self.tim.getConversationList();
        let isClean = false;
        promise
            .then(function (imResponse) {
                self.rList = imResponse.data.conversationList;
                self.rListOff = true;
                let arr = [];
                if (self.lookup.split(' ').join('').length == 0) {
                    //业务逻辑
                    isClean = true;
                    //........
                } else {
                    isClean = false;
                    self.rList.map((item) => {
                        if (item.userProfile.nick.indexOf(self.lookup) != -1 || item.userProfile.userID.indexOf(self.lookup) != -1) {
                            arr.push(item);
                        }
                    });
                }
                if (arr.length == 0) {
                    if (isClean) {
                        self.hlData();
                    } else {
                        self.rList = [];
                    }
                } else {
                    self.rList = arr;
                }
            })
            .catch(function (imError) {
                console.log(imError);
                // console.warn('getConversationList error:', imError); // 获取会话列表失败的相关信息
            });
    }

    //图片上传
    getFile(e) {
        const self = this;
        // 1. 创建消息实例
        self.loading = true;
        let message = this.tim.createImageMessage({
            to: self.toUserId,
            conversationType: TIM.TYPES.CONV_C2C,
            payload: {
                file: document.getElementById('imagePicker'),
            },
            onProgress: function (event) {
                console.log('file uploading:', event);
            },
        });
        // 2. 发送消息
        let promise = this.tim.sendMessage(message);
        promise
            .then(function (imResponse) {
                // 发送成功
                self.textarea = '';
                self.hList.messageList.push(imResponse.data.message);
                self.below();
                self.loading = false;
            })
            .catch(function (imError) {
                console.warn('sendMessage error:', imError);
                self.loading = false;
            });
        this.$refs.imagePicker.value = null;
    }
    //自动保持在最底部
    below() {
        this.$nextTick(() => {
            let container = document.querySelector('#t_right_con');
            console.log('container--' + container);
            if (container.scrollHeight) {
                container.scrollTop = container.scrollHeight;
            }
        });
    }
    //图片初始化
    newImg(val) {
        let w = val.payload.imageInfoArray[0].width;
        let h = val.payload.imageInfoArray[0].height;
        if (w > 300) {
            h = h / (w / 300);
            w = 300;
        }
        return `<img style='width:${w}px;height:${h}px;' src='${val.payload.imageInfoArray[0].imageUrl}'/>`;
    }
    //回车发送文本 阻止浏览器默认换行操作
    messageSendlisten(event) {
        if (event.keyCode === 13) {
            if (this.textarea.split(' ').join('').length == 0 || this.textarea.indexOf('\n') > -1) {
                this.$message({
                    message: '请输入正确信息',
                    type: 'warning',
                });
                return;
            }
            this.setButton(); // 发送文本
            event.preventDefault(); // 阻止浏览器默认换行操作
            return false;
        }
    }
    //消息已读
    read(id) {
        let promise = this.tim.setMessageRead({ conversationID: id });
        promise
            .then(function (imResponse) {
                // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
            })
            .catch(function (imError) {
                // 已读上报失败
                console.warn('setMessageRead error:', imError);
            });
    }
    //发送消息
    setButton() {
        const self = this;
        if (self.textarea.split(' ').join('').length == 0)
            return this.$message({
                message: '请输入正确信息',
                type: 'warning',
            });

        let message = this.tim.createTextMessage({
            to: self.hList.userID,
            conversationType: TIM.TYPES.CONV_C2C,
            payload: {
                text: self.textarea,
            },
        });
        // 发送消息
        let promise = this.tim.sendMessage(message);
        promise
            .then(function (imResponse) {
                // 发送成功
                self.textarea = '';
                self.hList.messageList.push(imResponse.data.message);
                self.below();
            })
            .catch(function (imError) {
                // 发送失败
                console.warn('sendMessage error:', imError);
            });
    }
    //获取会话资料
    setZi(conversationID, userID, avatar, chatName) {
        const self = this;
        if (userID == self.toUserId) {
            // return;
        } else {
            self.hList = [];
        }
        self.toUserId = userID;
        self.avatar = avatar;
        self.isActive = conversationID;
        self.chatName = chatName || userID;
        let promise = this.tim.getMessageList({ conversationID: conversationID, count: 15 });
        promise.then(function (imResponse) {
            let hList: any = {};
            hList.messageList = imResponse.data.messageList; // 消息列表。
            console.log('hList.messageList=====================================' + JSON.stringify(hList.messageList));
            hList.nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
            hList.isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
            hList.userID = userID; // 点击进去的用户id。
            hList.conversationID = conversationID;
            hList.isPeerRead = imResponse.data.isPeerRead;
            self.hList = hList;
            if (self.hList) self.rRightOff = true;
            self.below();
            //设置消息已读
            self.read(conversationID);
        });
    }
    //获取会话列表
    hlData() {
        const self = this;
        let promise = self.tim.getConversationList();
        promise
            .then(function (imResponse) {
                self.rList = imResponse.data.conversationList;
                self.rListOff = true;
            })
            .catch(function (imError) {
                console.log(imError);
            });
    }

    handleClose() {
        this.dialogVisible = false;
        this.toUserId = '';
        this.avatar = '';
        this.isActive = '1';
        this.chatName = '';
        this.rRightOff = false;
    }

    login() {
        let promise = this.tim.login({ userID: this.IMId, userSig: sessionStorage.getItem('userSig') });
        promise
            .then(function (imResponse) {
                if (imResponse.data.repeatLogin === true) {
                    // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
                    this.cLookup();
                }
            })
            .catch(function (imError) {
                console.warn('login error:', imError); // 登录失败的相关信息
            });
    }

    // keyupSubmit() {
    //     document.onkeydown = (e) => {
    //         let _key = (window as any).event.keyCode;
    //         if (_key === 13 && this.dialogVisible) {
    //             this.setButton();
    //         }
    //     };
    // }

    userName = '';
    created() {
        // this.keyupSubmit();
        const self = this;
        this.$root.$on('loginOut', () => {
            this.loginOut();
        });

        this.$root.$on('lianxi', (shopid) => {
            if (sessionStorage.getItem('enterpriseName')) {
                this.userName = sessionStorage.getItem('enterpriseName');
            } else {
                this.userName = sessionStorage.getItem('userName');
            }
            if (sessionStorage.getItem('headPath') != 'null') {
                self.myAvatar = sessionStorage.getItem('headPath');
            } else {
                self.myAvatar = '';
            }

            self.addUserID = shopid.replace(/-/g, '');
            console.log('联系:' + self.IMId);

            let promise = self.tim.getConversationList();
            promise
                .then(function (imResponse) {
                    self.rList = imResponse.data.conversationList;
                    self.rListOff = true;
                    self.tim
                        .getConversationProfile(`C2C${self.addUserID}`)
                        .then((res) => {
                            let isHave = false;
                            if (self.rList.length > 0) {
                                try {
                                    self.rList.forEach((item) => {
                                        if (item.conversationID == res.data.conversation.conversationID) {
                                            isHave = true;
                                            throw new Error('have');
                                        } else {
                                            isHave = false;
                                        }
                                    });
                                } catch (e) {
                                    isHave = true;
                                }
                            } else if (self.rList.length == 0) {
                                isHave = false;
                            }
                            if (!isHave) {
                                self.rList.push(res.data.conversation);
                            }
                            self.setZi(
                                res.data.conversation.conversationID,
                                res.data.conversation.userProfile.userID,
                                res.data.conversation.userProfile.avatar,
                                res.data.conversation.userProfile.nick
                            );
                            self.dialogVisible = true;
                            if (self.myAvatar != '') {
                                let promise2 = self.tim.updateMyProfile({
                                    nick: self.userName,
                                    avatar: self.myAvatar,
                                });
                                promise2
                                    .then(function (imResponse) {
                                        console.log('更新成功' + imResponse.data); // 更新资料成功
                                    })
                                    .catch(function (imError) {
                                        console.warn('updateMyProfile error:', imError); // 更新资料失败的相关信息
                                    });
                            } else {
                                let promise2 = self.tim.updateMyProfile({
                                    nick: self.userName,
                                });
                                promise2
                                    .then(function (imResponse) {
                                        console.log('更新成功' + imResponse.data); // 更新资料成功
                                    })
                                    .catch(function (imError) {
                                        console.warn('updateMyProfile error:', imError); // 更新资料失败的相关信息
                                    });
                            }
                        })

                        .catch(() => {
                            // self.dialogVisible = false;
                            self.$alert('没有找到该用户');
                        });
                })
                .catch(function (imError) {
                    console.log(imError);
                });
        });

        if (sessionStorage.getItem('id') == null) {
            return;
        }
        this.isUserLogin();
    }

    isUserLogin() {
        const self = this;
        let options;
        options = {
            SDKAppID: process.env.VUE_APP_SDKAppID,
        };
        this.IMId = sessionStorage.getItem('id');
        this.tim = TIM.create(options);
        this.tim.setLogLevel(1);
        this.tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });
        this.userSig = sessionStorage.getItem('userSig');
        this.$store.state.tim = this.tim;
        // 监听事件
        this.tim.on(TIM.EVENT.SDK_READY, function (event) {
            // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
            self.hlData();
        });

        this.tim.on(TIM.EVENT.MESSAGE_RECEIVED, function (event) {
            console.log('收到消息了');
            // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
            self.hlData();
            if (self.hList) {
                if (event.data[0].from == self.hList.userID) {
                    // console.log('这是正在聊天的聊天界面')
                    // console.log(event.data[0].conversationID)
                    self.read(event.data[0].conversationID);
                    self.hList.messageList.push(event.data[0]);
                    self.below();
                }
            }
        });
        // 监听事件，例如：
        this.tim.on(TIM.EVENT.SDK_READY, function (event) {
            // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
            // event.name - TIM.EVENT.SDK_READY
        });
        this.tim.on(TIM.EVENT.MESSAGE_REVOKED, function (event) {
            // 收到消息被撤回的通知
            // event.name - TIM.EVENT.MESSAGE_REVOKED
            // event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isRevoked 属性值为 true
        });
        this.tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, function (event) {
            // SDK 收到对端已读消息的通知，即已读回执。使用前需要将 SDK 版本升级至 v2.7.0 或以上。仅支持单聊会话。
            // event.name - TIM.EVENT.MESSAGE_READ_BY_PEER
            // event.data - event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isPeerRead 属性值为 true
        });
        this.tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, function (event) {
            // 收到会话列表更新通知，可通过遍历 event.data 获取会话列表数据并渲染到页面
            // event.name - TIM.EVENT.CONVERSATION_LIST_UPDATED
            // event.data - 存储 Conversation 对象的数组 - [Conversation]
        });
        this.tim.on(TIM.EVENT.GROUP_LIST_UPDATED, function (event) {
            // 收到群组列表更新通知，可通过遍历 event.data 获取群组列表数据并渲染到页面
            // event.name - TIM.EVENT.GROUP_LIST_UPDATED
            // event.data - 存储 Group 对象的数组 - [Group]
        });
        this.tim.on(TIM.EVENT.PROFILE_UPDATED, function (event) {
            // 收到自己或好友的资料变更通知
            // event.name - TIM.EVENT.PROFILE_UPDATED
            // event.data - 存储 Profile 对象的数组 - [Profile]
        });
        this.tim.on(TIM.EVENT.BLACKLIST_UPDATED, function (event) {
            // 收到黑名单列表更新通知
            // event.name - TIM.EVENT.BLACKLIST_UPDATED
            // event.data - 存储 userID 的数组 - [userID]
        });
        this.tim.on(TIM.EVENT.ERROR, function (event) {
            // 收到 SDK 发生错误通知，可以获取错误码和错误信息
            // event.name - TIM.EVENT.ERROR
            // event.data.code - 错误码
            // event.data.message - 错误信息
        });
        this.tim.on(TIM.EVENT.SDK_NOT_READY, function (event) {
            // 收到 SDK 进入 not ready 状态通知，此时 SDK 无法正常工作
            // event.name - TIM.EVENT.SDK_NOT_READY
        });
        this.tim.on(TIM.EVENT.KICKED_OUT, function (event) {
            // 收到被踢下线通知
            // event.name - TIM.EVENT.KICKED_OUT
            // event.data.type - 被踢下线的原因，例如:
            //    - TIM.TYPES.KICKED_OUT_MULT_ACCOUNT 多实例登录被踢
            //    - TIM.TYPES.KICKED_OUT_MULT_DEVICE 多终端登录被踢
            //    - TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED 签名过期被踢 （v2.4.0起支持）。
        });
        this.tim.on(TIM.EVENT.NET_STATE_CHANGE, function (event) {
            //  网络状态发生改变（v2.5.0 起支持）。
            // event.name - TIM.EVENT.NET_STATE_CHANGE
            // event.data.state 当前网络状态，枚举值及说明如下：
            //     \- TIM.TYPES.NET_STATE_CONNECTED - 已接入网络
            //     \- TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
            //    \- TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
        });
        this.login();
    }
}
