































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { formRules } from '@/common/util/validator';
import { User } from '@/http';
import headerPanel from './component/headerPanel.vue';
import footerBar from './component/footerBar.vue';
import ImageUpload from './component/ImageUpload.vue';
import { JSEncrypt } from 'jsencrypt';

@Component({
    components: {
        headerPanel,
        footerBar,
        ImageUpload,
    },
})
export default class merchantsEntering extends Vue {
    public active: number = 0;
    public active1: number = 0;
    public isWait: boolean = true;
    public waitIng: boolean = false;
    public ruzhuSuccsee: boolean = false;
    public isEnterprise: boolean = false;
    public merchantInStatus: string = '';
    public settleInRejectReason: string = '';
    public settleInRejectRemark: string = '';
    public mainBusinessList: any[] = [];
    public bankNameDisabled: boolean = true;
    public $refs: {
        settledFrom: HTMLFormElement;
        settledFrom1: HTMLFormElement;
        siteFrom: HTMLFormElement;
        settledSuccessFrom: HTMLFormElement;
        autocomplete: HTMLFormElement;
    };
    public settledFrom = {
        enterpriseBrandLogo: {
            name: '',
            path: '',
            tag: '',
            id: '',
        },
        brand: '',
        enterpriseName: '',
        enterpriseCode: '',
        realName: '',
        enterpriseJob: '',
        mainBusiness: '',
        checked: ['0'],
    };
    public settledSuccessFrom = {
        businessLicense: [
            {
                path: '',
                name: '',
            },
        ],
        phoneNumber: sessionStorage.getItem('phoneNumber'),
        bankName: '',
        bankName1: '',
        bankAccountNumber: '',
        enterpriseProfile: '',
        bankCode: '',
        bankAccountTrueName: '',
        id: '',
    };
    public siteFrom = {
        businessLicense: [
            {
                path: '',
                name: '',
            },
        ],
        phoneNumber: sessionStorage.getItem('phoneNumber'),
        merchantPassword: '',
        reMerchantPassword: '',
        bankName: '',
        bankName1: '',
        bankAccountNumber: '',
        enterpriseProfile: '',
        bankCode: '',
        bankAccountTrueName: '',
        checked: ['0'],
    };
    public passWordIsTrue: boolean = false;
    public formRules = formRules;
    public bankNameList: any[] = [];
    public data: any = {};
    changePassword(value, num) {
        if (num == 0) {
            if (this.siteFrom.reMerchantPassword != '') {
                if (value != this.siteFrom.reMerchantPassword) {
                    this.passWordIsTrue = false;
                    // this.$message.warning('两次密码不一致');
                } else {
                    this.passWordIsTrue = true;
                }
            }
        } else {
            if (this.siteFrom.merchantPassword != '') {
                if (value != this.siteFrom.merchantPassword) {
                    this.passWordIsTrue = false;
                    // this.$message.warning('两次密码不一致');
                } else {
                    this.passWordIsTrue = true;
                }
            }
        }
    }
    async mounted() {
        this.init();
    }
    async init() {
        if (sessionStorage.getItem('id')) {
            if (sessionStorage.getItem('userType') == '2') {
                this.isEnterprise = true;
            } else {
                this.isEnterprise = false;
            }
            let resu = await User.GetWxBankCodeList();
            this.bankNameList = resu.data;
            let res = await User.GetMerchantInfo();
            if (res) {
                let businessLicense = [
                    {
                        path: '',
                        name: '',
                    },
                ];
                this.settleInRejectReason = res.settleInRejectReason;
                this.settleInRejectRemark = res.settleInRejectRemark;
                this.ruzhuSuccsee = res.merchantInStatus ? true : false;
                this.merchantInStatus = res.merchantInStatus;
                this.settledSuccessFrom = {
                    businessLicense: res.businessLicense.length > 0 ? res.businessLicense : businessLicense,
                    phoneNumber: sessionStorage.getItem('phoneNumber'),
                    bankName: res.bankName,
                    bankName1: res.bankName,
                    bankAccountNumber: res.bankAccountNumber,
                    enterpriseProfile: res.enterpriseProfile,
                    bankCode: res.bankCode,
                    bankAccountTrueName: res.bankAccountTrueName,
                    id: res.id,
                };
            }
            this.refreshUser();
        } else {
            this.$router.push({
                path: '/register',
            });
        }
    }
    refreshUser() {
        User.GetRefreshUserInfo(sessionStorage.getItem('id')).then((res) => {
            this.successLogin(res);
        });
    }
    successLogin(res) {
        sessionStorage.setItem('id', res.id);
        sessionStorage.setItem('headPath', res.headPath);
        sessionStorage.setItem('userName', res.userName);
        sessionStorage.setItem('phoneNumber', res.phoneNumber);
        sessionStorage.setItem('isOpenTencentCloud', res.isOpenTencentCloud);
        sessionStorage.setItem('mainBusiness', res.mainBusiness);
        sessionStorage.setItem('userType', res.userType);
        sessionStorage.setItem('enterpriseCode', res.enterpriseCode);
        sessionStorage.setItem('enterpriseJob', res.enterpriseJob);
        sessionStorage.setItem('enterpriseName', res.enterpriseName);
        sessionStorage.setItem('isEnabled', res.isEnabled);
        sessionStorage.setItem('isFirstLogin', res.isFirstLogin);
        sessionStorage.setItem('userSig', res.userSig);
        this.data = { ...res };
        this.setDataDictionary();
    }
    async setDataDictionary() {
        let res = await User.GetDataDictionary({
            parentId: '95e698f8-8d1e-4c82-7eea-0cc35528e281',
            skip: 0,
            count: -1,
        });
        this.mainBusinessList = res.data;
    }
    setImage(name, path) {
        this.settledFrom.enterpriseBrandLogo.name = name;
        this.settledFrom.enterpriseBrandLogo.path = path;
    }
    setSiteImage(name, path) {
        this.siteFrom.businessLicense = [];
        this.siteFrom.businessLicense.push({
            name: name,
            path: path,
        });
    }
    setsettledSuccessImage(name, path) {
        this.settledSuccessFrom.businessLicense = [];
        this.settledSuccessFrom.businessLicense.push({
            name: name,
            path: path,
        });
    }
    public async handleCustomerDropDown(qs, cb) {
        this.settledFrom.enterpriseCode = '';
        if (qs == '') {
            return;
        }
        let res = await User.GetEnterpriseCodeFromQcc({
            codeType: '8',
            enterpriseName: qs,
            pageIndex: 0,
            count: 10,
        });
        if (res.data) {
            cb(
                res.data.map((v) => ({
                    value: v.enterpriseName,
                    enterpriseCode: v.enterpriseCode,
                }))
            );
        } else {
            cb([]);
        }
    }

    public handleSelect(item) {
        if (item.enterpriseCode == '') {
            this.$message.warning('未查询到该企业信息');
        } else {
            this.settledFrom.enterpriseCode = item.enterpriseCode;
        }
    }
    getEnterpriseCode() {
        this.$refs.autocomplete.focus();
    }
    changeBankName(bankName, num) {
        this.siteFrom.bankName1 = '';
        this.settledSuccessFrom.bankName1 = '';
        if (num == 0) {
            this.bankNameList.forEach((e) => {
                if (e.name == bankName) {
                    this.siteFrom.bankCode = e.value;
                }
            });
            if (bankName == '其他') {
                this.bankNameDisabled = false;
            } else {
                this.bankNameDisabled = true;
                this.siteFrom.bankName1 = bankName;
            }
        } else {
            this.bankNameList.forEach((e) => {
                if (e.name == bankName) {
                    this.settledSuccessFrom.bankCode = e.value;
                }
            });
            if (bankName == '其他') {
                this.bankNameDisabled = false;
            } else {
                this.bankNameDisabled = true;
                this.settledSuccessFrom.bankName1 = bankName;
            }
        }
    }
    // 下一步
    handleClick(v) {
        const jsencrypt = new JSEncrypt({});
        jsencrypt.setPublicKey(
            'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDMkmzEoj7LFqLmfKqix11b2A5SMXsAE9ag/9Uq+YeiJfFq5TtP7VpGdJUx06SgQqghgWL5hXLRVTMUn8LVW2SmHV0WjvFAqhtTK9CyTYFQ9wFXy7tpxHcl6jZnw8rokzY2y4yN5WbzEJ4+1c9j0Yp+oeTk0NauyOC03lEOEY9BGwIDAQAB'
        );
        if (v == 0) {
            this.$refs.settledFrom1.validate((valid) => {
                if (valid) {
                    let obj = {
                        codeType: '8',
                        id: sessionStorage.getItem('id'),
                        enterpriseBrandLogo: this.settledFrom.enterpriseBrandLogo,
                        brand: this.settledFrom.brand,
                        enterpriseName: this.settledFrom.enterpriseName,
                        enterpriseCode: this.settledFrom.enterpriseCode,
                        realName: this.settledFrom.realName,
                        enterpriseJob: this.settledFrom.enterpriseJob,
                        mainBusiness: this.settledFrom.mainBusiness,
                    };
                    User.EditUserEnterpriseInfo(obj).then((res) => {
                        if (this.active++ > 2) this.active = 0;
                    });
                }
            });
        } else if (v == 1) {
            this.$refs.siteFrom.validate((valid) => {
                if (valid) {
                    let merchantPassword = jsencrypt.encrypt(this.siteFrom.merchantPassword);
                    let reMerchantPassword = jsencrypt.encrypt(this.siteFrom.reMerchantPassword);
                    let obj = {
                        adminId: sessionStorage.getItem('id'),
                        codeType: '8',
                        businessLicense: this.siteFrom.businessLicense,
                        phoneNumber: sessionStorage.getItem('phoneNumber'),
                        merchantPassword: merchantPassword,
                        reMerchantPassword: reMerchantPassword,
                        bankName: this.siteFrom.bankName1,
                        bankAccountNumber: this.siteFrom.bankAccountNumber,
                        enterpriseProfile: this.siteFrom.enterpriseProfile,
                        bankCode: this.siteFrom.bankCode,
                        bankAccountTrueName: this.siteFrom.bankAccountTrueName,
                    };
                    if (this.passWordIsTrue) {
                        User.AddMerchantInfo(obj).then((res) => {
                            if (this.active++ > 2) this.active = 0;
                            this.init();
                        });
                    } else {
                        this.$message.warning('两次密码不一致');
                    }
                }
            });
        } else if (v == 2) {
            this.$refs.siteFrom.validate((valid) => {
                if (valid) {
                    let merchantPassword = jsencrypt.encrypt(this.siteFrom.merchantPassword);
                    let reMerchantPassword = jsencrypt.encrypt(this.siteFrom.reMerchantPassword);
                    let obj = {
                        adminId: sessionStorage.getItem('id'),
                        codeType: '8',
                        businessLicense: this.siteFrom.businessLicense,
                        phoneNumber: sessionStorage.getItem('phoneNumber'),
                        merchantPassword: merchantPassword,
                        reMerchantPassword: reMerchantPassword,
                        bankName: this.siteFrom.bankName1,
                        bankAccountNumber: this.siteFrom.bankAccountNumber,
                        enterpriseProfile: this.siteFrom.enterpriseProfile,
                        bankCode: this.siteFrom.bankCode,
                        bankAccountTrueName: this.siteFrom.bankAccountTrueName,
                    };
                    if (this.passWordIsTrue) {
                        User.AddMerchantInfo(obj).then((res) => {
                            if (this.active1++ > 1) this.active1 = 0;
                        });
                    } else {
                        this.$message.warning('两次密码不一致');
                    }
                }
            });
        } else if (v == 3) {
            this.$refs.settledSuccessFrom.validate((valid) => {
                if (valid) {
                    let obj = {
                        codeType: '8',
                        id: this.settledSuccessFrom.id,
                        businessLicense: this.settledSuccessFrom.businessLicense,
                        bankName: this.settledSuccessFrom.bankName1,
                        bankCode: this.settledSuccessFrom.bankCode,
                        bankAccountTrueName: this.settledSuccessFrom.bankAccountTrueName,
                        bankAccountNumber: this.settledSuccessFrom.bankAccountNumber,
                        enterpriseProfile: this.settledSuccessFrom.enterpriseProfile,
                    };
                    User.EditMerchantInfo(obj).then((res) => {
                        this.isWait = true;
                        this.init();
                    });
                }
            });
        }
    }
    prevStep() {
        this.active--;
    }
    showEnterprice() {
        // window.location.href = 'http://jutiangas.com/#/';
        this.$router.push({
            path: '/',
        });
    }
    shouye() {
        // window.location.href = 'http://jutiangas.com/#/';
        this.$router.push({
            path: '/',
        });
    }
    changeShenqing() {
        this.isWait = false;
    }
    jumpRuzhu() {
        window.open('http://jutiangas.com/#/service/settleAgreement');
    }
    jumpFuwu() {
        window.open('http://jutiangas.com/#/service/clause');
    }
    jumpYinsi() {
        window.open('http://jutiangas.com/#/service/policy');
    }
}
