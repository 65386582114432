/*
 * @Description: 
 * @Autor: jyf
 * @Date: 2021-04-27 14:40:52
 * @LastEditors: jyf
 * @LastEditTime: 2021-04-28 14:21:05
 */
import { Post, Get, Patch, Put, Delete, Download, ax } from './http';
import { type } from './interface';
import qs from 'qs';
export namespace Shop {
    /**
     * 获取完整店铺详情
     */
    export var GetFullShopDetail = (id: any): Promise<any> => {
      return Get(`/api/Shop/GetFullShopDetail/${id}`, '');
  };
  /**
     * 获取店铺列表
     */
    export var GetShopList = (query: any): Promise<any> => {
        return Get(`/api/shop?${qs.stringify(query)}`, {});
    };
    /**
     * 获取店铺资质列表
     */
    export var GetShopQualificationList = (id: any): Promise<any> => {
        return Get(`/api/Shop/GetShopQualificationList?${qs.stringify(id)}`, {});
    };

    /**
     * 获取店铺动态列表
     */
    export var GetShopNewsList = (id: any): Promise<any> => {
        return Get(`/api/Shop/GetShopNewsList?${qs.stringify(id)}`, {});
    };
}
